import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router';
import equal from 'fast-deep-equal'
import qs from 'query-string';

export default function useSearchParams<T>(): [T, (arg: T) => void] {

  const history = useHistory()
  const location = useLocation();
  const searchParams = qs.parse(location.search) as any;

  const updateSearchParams = useCallback((unmergedSearchParams) => {
    const mergedSearchParams = Object.assign({}, searchParams, unmergedSearchParams);
    if (equal(mergedSearchParams, searchParams)) {
      return;
    }
    history.replace({
      pathname: location.pathname,
      search: qs.stringify(mergedSearchParams, {
        skipNull: true,
        skipEmptyString: true,
      })
    });
  }, [history, location, searchParams])

  return [searchParams, updateSearchParams];
}
