import Navbar from 'src/components/Navbar/Navbar';
import PropTypes from 'prop-types';
import React from 'react';

const AppLayout: React.FC = (props) => {

  const {
    children,
  } = props;

  return (
    <>
      <Navbar />
      {children}
    </>
  );
}

export default AppLayout;

AppLayout.propTypes = {
  auth: PropTypes.object,
  dispatch: PropTypes.func,
  user: PropTypes.object,
  userAgent: PropTypes.string,
};
