import React from 'react'

interface Props {
  invoice: any;
}

const Invoice: React.FC<Props> = (props) => {

  const {
    invoice,
  } = props

  return (
    <>
      <div className="d-row d-flex justify-content-between">
        <div>
          Subtotal
        </div>
        <div>
          $
          {invoice.subtotal}
        </div>
      </div>
      {
        invoice.couponValue > 0 && (
          <div className="d-row d-flex justify-content-between">
            <div>
              Coupon
            </div>
            <div>
              - $
              {invoice.couponValue}
            </div>
          </div>
        )
      }
      <div className="d-row d-flex justify-content-between">
        <div>
          Shipping
        </div>
        <div>
          $
          {invoice.shippingCost}
        </div>
      </div>
      <strong className="d-row d-flex justify-content-between">
        <div>
          Order total
        </div>
        <div>
          $
          {invoice.orderTotal}
        </div>
      </strong>

    </>
  )
}

export default Invoice;
