import React, { useContext } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useParams
} from 'react-router';
import { UserContext } from 'src/pages/AuthListener/User';
import Cart from 'src/pages/Private/Cart/Cart';
import Confirmation from 'src/pages/Private/Confirmation/Confirmation';
import Dashboard from 'src/pages/Private/Dashboard/Dashboard';
import Order from 'src/pages/Private/Order/Order';
import StackAndShirt from 'src/pages/Private/StackAndShirtAttributes/StackAndShirtAttributes';
import {
  PRIVATE_CART_PATH,
  PRIVATE_CONFIRMATION_PATH,
  PRIVATE_DASHBOARD_PATH,
  PRIVATE_ORDER_PATH,
  PRIVATE_STACK_ATTRIBUTES_PATH,
  PRIVATE_STACK_INTRO_PATH,
  PRIVATE_STACK_PATH
} from 'src/sdk/PATHS';
import { UserType } from 'src/sdk/schemaTypes';
import Intro from './Intro/Intro';
import Stack from './Stack/Stack';

export const usePrivate = (): { user: UserType } => {
  const { data: user } = useContext(UserContext)
  if (!user) {
    throw Error('You are not in a Private route')
  }
  return { user }
}

const Private: React.FC = () => {

  const userSWR = useContext(UserContext);
  const { data: user } = userSWR;
  const location = useLocation()
  const params = useParams<{ userId: string }>()

  if (!user) {
    return null; // Just chill till AuthAnonymously gets us a user
  }

  if (user.id !== params.userId) {
    return (
      <Redirect
        to={{
          pathname: location.pathname.replace(params.userId, user.id),
          search: location.search,
        }}
      />
    );
  }
  return (
    <Switch>
      <Route
        component={Intro}
        path={PRIVATE_STACK_INTRO_PATH}
      />
      <Route
        exact
        component={Stack}
        path={PRIVATE_STACK_PATH}
      />
      <Route
        component={StackAndShirt}
        path={PRIVATE_STACK_ATTRIBUTES_PATH}
      />
      <Route
        exact
        component={Order}
        path={PRIVATE_ORDER_PATH}
      />
      {/* 👆 this one has to be above 👇 */}
      <Route
        component={Dashboard}
        path={PRIVATE_DASHBOARD_PATH}
      />
      <Route
        component={Cart}
        path={PRIVATE_CART_PATH}
      />
      <Route
        exact
        component={Confirmation}
        path={PRIVATE_CONFIRMATION_PATH}
      />
      {/*<Route*/}
      {/*  component={NoMatch}*/}
      {/*/>*/}
    </Switch>
  );
}

export default Private;
