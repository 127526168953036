import {
  useCallback,
  useContext
} from 'react';
import usePromise from 'src/hooks/usePromise';
import { useResource } from 'src/hooks/useResources';
import { UserContext } from 'src/pages/AuthListener/User';
import { useMethodsSDK } from 'src/sdk/methods/MethodsSDKProvider';
import { useOrdersSDK } from 'src/sdk/orders/OrdersSDKContext';
import {
  CartOrderType,
  ShirtType
} from 'src/sdk/schemaTypes';

export default function useAddOrRemoveShirtOnCart(shirtId: string): [boolean, () => Promise<CartOrderType | undefined>]{
  const { data: shirt } = useResource<ShirtType>({
    id: shirtId,
    type: 'shirts'
  })
  const { data: user } = useContext(UserContext);
  const { data: cart } = useResource<CartOrderType>(user?.relationships?.cart);
  const methodsSDK = useMethodsSDK();
  const ordersSDK = useOrdersSDK();
  const [isLoading, callAsync] = usePromise()
  const addOrRemoveShirtOnCart = useCallback(async () => {
    if (!shirt || !cart) {
      return;
    }
    const cartOrder = callAsync(async () => {
      const updatedCart = ordersSDK.addOrRemoveShirtOnCart(shirt, cart)
      return methodsSDK.upsertResource(updatedCart);
    })
    return cartOrder
  }, [shirt, cart, callAsync, ordersSDK, methodsSDK])
  return [isLoading, addOrRemoveShirtOnCart]
}
