import makeConfigs from '@stackshirts/configs';
import { createBrowserHistory as createHistory } from 'history';
import { setAutoFreeze } from 'immer';
import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';
import { Router } from 'react-router';
import { algoliaProducts } from 'src/algolia';
import ErrorBoundary from 'src/pages/App/ErrorBoundary';
import User from 'src/pages/AuthListener/User';
import { AnalyticsContext } from 'src/sdk/analytics/AnalyticsContext';
import { AnalyticsType } from 'src/sdk/analytics/types';
import { DebugContext } from 'src/sdk/debug/DebugContext';
import FirebaseClientSDK from 'src/sdk/FirebaseClientSDK';
import { FirebaseSDKContext } from 'src/sdk/firebase/FirebaseSDKContext';
import MethodsSDK from 'src/sdk/methods/MethodsSDK';
import { MethodsSDKContext } from 'src/sdk/methods/MethodsSDKProvider';
import OrdersSDK from 'src/sdk/orders/OrdersSDK';
import { OrdersSDKContext } from 'src/sdk/orders/OrdersSDKContext';
import ProductsSDK from 'src/sdk/products/ProductsSDK';
import { ProductsSDKContext } from 'src/sdk/products/ProductsSDKContext';
import { SentryContext } from 'src/sdk/sentry/SentryContext';
import ShirtsSDK from 'src/sdk/shirts/ShirtsSDK';
import { ShirtsSDKContext } from 'src/sdk/shirts/ShirtsSDKContext';
import StacksSDK from 'src/sdk/stacks/StacksSDK';
import { StacksSDKContext } from 'src/sdk/stacks/StacksSDKContext';
import UsersSDK from 'src/sdk/users/UsersSDK';
import { UserSDKContext } from 'src/sdk/users/UsersSDKContext';
import diff from 'src/utils-for-testing/diff';
import makeDebug from 'src/utils/makeDebug';
import { SWRConfig } from 'swr'
import App from './pages/App/App';
import AuthListener from './pages/AuthListener/AuthListener';
import makeSentry from 'src/makeSentry';
import * as serviceWorker from './serviceWorker';
import LogRocket from 'logrocket';
import './styles/styles.scss'
import axiosInstance from 'src/utils/axiosInstance';

setAutoFreeze(false); // Because react-grid-layout does a whopping amount of mutations

const { firebaseClientConfig } = makeConfigs(process.env.REACT_APP_ENV)

declare const window: {
  analytics: any;
  diff: any;
};

window.analytics.ready(function () {
  // TODO: Get experiments
});

const analytics: AnalyticsType = {
  identify: ({ user }) => {
    window.analytics.identify(user.id, {
      ...user.attributes,
      ...user.attributes.authUser,
    })
  },
  track: ({ name, properties }) => {
    window.analytics.track(name, properties)
  },
  page: ({ name, category, properties }) => {
    if (category) {
      window.analytics.page(category, name, properties);
    }
    else {
      window.analytics.page(name, properties);
    }
  }
}

const sentry = makeSentry()
const debug = makeDebug({
  sentry,
  logrocket: LogRocket
})

const history = createHistory();

const queryParams = new URLSearchParams(history.location.search)

if (queryParams.has('mapKey')) {
  queryParams.delete('mapKey')
  history.replace({
    search: queryParams.toString(),
  })
}
let firebaseApp = 'app'
if (queryParams.has('userId')) {
  firebaseApp = 'admin'
  /**
   * And you better hope you're logged in??
   */
}

const firebaseSDK = new FirebaseClientSDK(firebaseApp, firebaseClientConfig)
const methods = new MethodsSDK(firebaseSDK, debug)

ReactDOM.render(
  // <React.StrictMode>
  <Router history={history}>
    <AnalyticsContext.Provider value={analytics}>
      <ErrorBoundary
        location={history.location}
        debug={debug}
      >
        <SentryContext.Provider value={makeSentry()}>
          <FirebaseSDKContext.Provider value={firebaseSDK}>
            <DebugContext.Provider value={debug}>
              <UserSDKContext.Provider value={new UsersSDK(firebaseSDK)}>
                <StacksSDKContext.Provider value={new StacksSDK(firebaseSDK)}>
                  <ShirtsSDKContext.Provider value={new ShirtsSDK(firebaseSDK)}>
                    <MethodsSDKContext.Provider value={methods}>
                      <ProductsSDKContext.Provider value={new ProductsSDK(debug, algoliaProducts)}>
                        <OrdersSDKContext.Provider value={new OrdersSDK(firebaseSDK, axiosInstance)}>
                          <IntlProvider locale="en">
                            <SWRConfig
                              value={{

                                revalidateOnFocus: false,
                                revalidateOnReconnect: false,

                                refreshInterval: 0,

                                refreshWhenHidden: false,
                                refreshWhenOffline: false,
                              }}
                            >
                              <AuthListener>
                                <User>
                                  <App />
                                </User>
                              </AuthListener>
                            </SWRConfig>
                          </IntlProvider>
                        </OrdersSDKContext.Provider>
                      </ProductsSDKContext.Provider>
                    </MethodsSDKContext.Provider>
                  </ShirtsSDKContext.Provider>
                </StacksSDKContext.Provider>
              </UserSDKContext.Provider>
            </DebugContext.Provider>
          </FirebaseSDKContext.Provider>
        </SentryContext.Provider>
      </ErrorBoundary>
    </AnalyticsContext.Provider>
  </Router>
  // </React.StrictMode>
  , document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();


if (typeof window !== 'undefined') {
  window.diff = diff
}
