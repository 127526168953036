import { AxiosInstance } from 'axios';
import produce from 'immer';
import { API_ORDERS_MAKE_CHECKOUT_URL_PATH } from 'src/sdk/PATHS';
import {
  CartOrderType,
  ShirtType
} from 'src/sdk/schemaTypes';
import { FirebaseUniversalSDKType } from 'src/sdk/types';
import handleAxios from 'src/utils/handleAxios';

export default class OrdersSDK {

  firebaseSDK: FirebaseUniversalSDKType;
  axiosInstance: AxiosInstance

  constructor(firebaseSDK: FirebaseUniversalSDKType, axiosInstance: AxiosInstance) {
    this.firebaseSDK = firebaseSDK;
    this.axiosInstance = axiosInstance
  }

  makeInvoice(props: any) {

    const {
      shirts,
      settings,
    } = props

    const shippingCost = 0
    const balanceUsed = 0

    const subtotal = settings.shirt.price * shirts.length

    const orderTotal = subtotal + shippingCost

    return {
      subtotal,
      shippingCost,
      balanceUsed,
      orderTotal,
    }

  }

  addOrRemoveShirtOnCart(shirt: ShirtType, cart: CartOrderType): CartOrderType {
    return produce<CartOrderType>(cart, (draft) => {
      if (!draft.relationships.shirts) {
        draft.relationships.shirts = {}
      }
      if (!draft.relationships.shirts[shirt.id]) {
        draft.relationships.shirts[shirt.id] = {
          id: shirt.id,
          type: 'shirts',
          addedOn: this.firebaseSDK.makeTimestamp()
        }
      }
      else {
        delete draft.relationships.shirts[shirt.id]
      }
      delete draft.meta.session
    })
  }

  async getSession(): Promise<CartOrderType> {

    const cartOrder: CartOrderType = await this.axiosInstance.get(API_ORDERS_MAKE_CHECKOUT_URL_PATH)
      .then(res => res.data)
      .then(res => res.data)
      .catch(handleAxios)

    return cartOrder

  }

}
