import { AxiosError } from 'axios';

export default function handleAxios(error: AxiosError) {
  if (typeof error.response?.data === 'object') {
    const err = new Error(JSON.stringify(error.response.data, null, 2));
    throw err;
  }
  if (error.response) {
    throw new Error(`${error.request.method}: ${error.message} (no JSON)`);
  }
  throw new Error(`${error.request.method}: most likely a server timeout or an internet connection error`);
};
