import classnames from 'classnames';
import firebase from 'firebase';
import React, {
  useContext,
  useRef,
  useState
} from 'react';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import {
  Redirect,
  useHistory,
  useLocation,
  useParams
} from 'react-router';
import Alert, { AlertMessage } from 'src/components/Alert/Alert';
import PageView from 'src/components/PageView/PageView';
import usePromise from 'src/hooks/usePromise';
// @ts-ignore
import {
  AUTH_CODES,
  FIREBASE_AUTH_ERROR_CODES,
} from 'src/pages/SignIn/CODES';
import SignInButtons from 'src/pages/SignIn/SignInButtons';
import { useAnalytics } from 'src/sdk/analytics/AnalyticsContext';
import AppError from 'src/sdk/AppError';
import { AuthContext } from 'src/sdk/auth/AuthProvider';
import { useDebug } from 'src/sdk/debug/DebugContext';
import { useFirebaseSDK } from 'src/sdk/firebase/FirebaseSDKContext';
import { useMethodsSDK } from 'src/sdk/methods/MethodsSDKProvider';
import {
  makeHomePathname,
  makeSignedInPathname
} from 'src/sdk/PATHS';
import { UserType } from 'src/sdk/schemaTypes';
import { RESOURCE_TYPES } from 'src/sdk/types';
import { useUsersSDK } from 'src/sdk/users/UsersSDKContext';
import promiseTry from 'src/utils/promiseTry';
import styled from 'styled-components'
import messages from './SignIn.messages';
import SignInHeroImg from './viana_de_castelo.png';

export { SignInHeroImg };

const StyledDiv = styled.div`
  .SignIn__hero {
    flex-shrink: 0;
    width: 40vw;
    margin-right: 2rem;

    &:after {
      content: '';
      position: fixed;
      left: 0;
      top: 0;
      width: 40vw;
      height: 100vh;
      background-size: cover;
      background: linear-gradient(123deg, #2E99B0 0%, #2E99B0 40%, #FCD77F calc(40% + 1px), #FCD77F 60%, #FF2E4C calc(60% + 1px), #FF2E4C 75%, #1E1548 calc(75% + 1px), #1E1548 100%);
    }
  }
`


// If you try and link with same provider twice:
// {
//   "code": "auth/provider-already-linked",
//   "message": "User can only be linked to one identity for the given provider."
// }

export type SignInAuthType = 'signIn' | 'signUp'
export type SignInAuthWith = 'social' | 'email'
export type EmailAuth = { email: string, password: string }

const localStorageKey = 'stackshirtsLastAuth'

const SignIn: React.FC = () => {

  const history = useHistory();
  const params = useParams();
  const location = useLocation<{ redirectTo: string }>()
  const { auth, setAuth } = useContext(AuthContext);
  const authRef = useRef(auth);
  const analytics = useAnalytics();
  const firebaseSDK = useFirebaseSDK()
  const methodsSDK = useMethodsSDK();
  const usersSDK = useUsersSDK();
  const debug = useDebug()

  // ==========
  // Component only hooks, etc
  // ==========
  const [signingIn, currySigningIn] = usePromise(); // TODO: Put overlay on screen, perhaps?
  const [errorCode, setCode] = useState<string | null>(null);
  const [authType, setAuthType] = useState<SignInAuthType>(localStorage.getItem(localStorageKey) ? 'signIn' : 'signUp')
  const [authWith, setAuthWith] = useState<SignInAuthWith>('social')
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, touchedFields, isValid, isSubmitted }
  } = useForm<EmailAuth>({ mode: 'all' });

  const successCallback = (userId: string) => {
    localStorage.setItem(localStorageKey, userId)
    if (location.state && location.state.redirectTo) {
      return history.push(location.state.redirectTo);
    }
    const pathname = makeHomePathname();
    return history.push(pathname);
  };

  const errorCallback = (e: AppError | firebase.auth.Error) => {
    if (e.code && e.code === FIREBASE_AUTH_ERROR_CODES.EMAIL_ALREADY_IN_USE) {
      setAuthType('signIn')
    }
    debug.error(e as AppError)
    setCode(e.code || AUTH_CODES.UNKNOWN_ERROR)
  }

  const handleUserCredential = async (userCredential: firebase.auth.UserCredential) => {
    if (!userCredential) {
      throw new Error('Umm, we really expect a userCredential from linkWithPopup')
    }
    const firebaseUser = userCredential.user!; // Can we guarantee that userCredential.user exists?
    debug.log(`Authed with credential`, userCredential);

    setAuth({
      initialized: true,
      uid: firebaseUser.uid,
      authenticated: true,
    })

    let user = await methodsSDK.getResource<UserType>({
      id: firebaseUser.uid,
      type: RESOURCE_TYPES.USERS
    });

    if (!user) {
      user = await usersSDK.createUser(firebaseUser, userCredential)
      await methodsSDK.upsertResource(user);
    }
    else {
      user = await usersSDK.updateAuthOnUser(user, firebaseUser, userCredential);
      await methodsSDK.upsertResource(user);
    }



    successCallback(firebaseUser.uid);
  }

  const onSubmit = (formValues: EmailAuth) => {
    return currySigningIn(async () => {
      await promiseTry(async () => {
        let userCredential
        if (authType === 'signIn') {
          userCredential = await firebaseSDK.firebaseAuth.signInWithEmailAndPassword(formValues.email, formValues.password)
        }
        else {
          userCredential = await firebaseSDK.firebaseAuth.createUserWithEmailAndPassword(formValues.email, formValues.password)
            .catch((e: firebase.auth.Error) => {
              if (e.code === FIREBASE_AUTH_ERROR_CODES.EMAIL_ALREADY_IN_USE) {
                return firebaseSDK.firebaseAuth.signInWithEmailAndPassword(formValues.email, formValues.password)
                  .catch((e) => {
                    if (e.code === FIREBASE_AUTH_ERROR_CODES.WRONG_PASSWORD) {
                      /**
                       * Ok then MAYBE you used a provider OR your password is wrong
                       */
                      setAuthType('signIn')
                      const error = new AppError(e.message)
                      error.code = AUTH_CODES.EMAIL_USED_PROBABLY_FROM_A_PROVIDER
                      throw error
                    }
                    throw e
                  })
              }
            })
        }
        if (userCredential) {
          await handleUserCredential(userCredential)
        }
      })
        .catch(errorCallback)
        .finally(() => {
          reset(formValues)
        })
    })
  }

  const renderAlert = () => {
    if (!errorCode) {
      return null;
    }

    debug.log('Error signing in', errorCode)

    switch (errorCode) {
      case FIREBASE_AUTH_ERROR_CODES.EMAIL_ALREADY_IN_USE:
      case FIREBASE_AUTH_ERROR_CODES.USER_NOT_FOUND:
      case FIREBASE_AUTH_ERROR_CODES.NETWORK_REQUEST_FAILED: {
        return <FormattedMessage {...messages[errorCode]} />;
      }
      case FIREBASE_AUTH_ERROR_CODES.WRONG_PASSWORD: {
        return 'Wrong password. Or did you use an outside account?';
      }
      case AUTH_CODES.EMAIL_USED_PROBABLY_FROM_A_PROVIDER: {
        return 'There is an account with that email, but maybe you used an outside account when you first signed up?'
      }
      case AUTH_CODES.UNKNOWN_ERROR:
      default: {
        return <FormattedMessage {...messages[AUTH_CODES.UNKNOWN_ERROR]} />;
      }
    }
  };

  if (authRef.current.authenticated) {
    return (
      <Redirect
        to={{
          pathname: makeSignedInPathname(params)
        }}
      />
    );
  }

  // 👇 This may be an anonymous user or not!
  return (
    <>
      <Helmet defer={false}>
        <title>Sign in</title>
        <meta
          name="description"
          content="Sign in"
        />
      </Helmet>
      <PageView
        onPageView={() => analytics.page({
          name: 'Sign In'
        })}
      />
      <StyledDiv className="d-flex flex-row">

        <div className="SignIn__hero d-none d-md-block" />

        <div className="container cy flex-grow d-flex flex-column">
          <h1 className="display-2">
            Welcome!
          </h1>

          <div className="form-group">
            <fieldset>
              <legend>Or Welcome back?</legend>
              <div className="custom-control custom-radio">
                <input
                  className="custom-control-input"
                  onChange={(e) => {
                    setAuthType('signUp')
                  }}
                  type="radio"
                  name="authType"
                  id="signUp"
                  value="signUp"
                  checked={authType === 'signUp'}
                />
                <label
                  className="custom-control-label"
                  htmlFor="signUp"
                >
                  Sign up
                </label>
              </div>
              <div className="custom-control custom-radio mb-1">
                <input
                  className="custom-control-input"
                  type="radio"
                  onChange={(e) => {
                    setAuthType('signIn')
                  }}
                  name="authType"
                  id="signIn"
                  value="signIn"
                  checked={authType === 'signIn'}
                />
                <label
                  className="custom-control-label"
                  htmlFor="signIn"
                >
                  Log in
                </label>
              </div>
            </fieldset>
          </div>


          {authWith === 'social' ? (
            <>
              <SignInButtons
                currySigningIn={currySigningIn}
                signingIn={signingIn}
                authType={authType}
                errorCallback={errorCallback}
                handleUserCredential={handleUserCredential}
              />
              <button
                className="btn btn-default center flex-grow-1"
                onClick={() => {
                  setAuthWith('email')
                }}
              >
                Or use email
              </button>
            </>
          ) : (
            <form
              className="w-100 w-sm-75 w-md-50"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Email address</label>
                <input
                  {...register('email', { required: true })}
                  type="email"
                  className={classnames('form-control', {
                    'is-invalid': touchedFields.email && errors.email,
                    'is-valid': touchedFields.email && !errors.email,
                  })}
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Enter email"
                />
                {authType === 'signUp' && (
                  <small
                    id="emailHelp"
                    className="form-text text-muted"
                  >
                    We'll never share your email with anyone else.
                  </small>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputPassword1">Password</label>
                <input
                  {...register('password', { required: true, minLength: 8 })}
                  type="password"
                  className={classnames('form-control', {
                    'is-invalid': touchedFields.password && errors.password,
                    'is-valid': touchedFields.password && !errors.password,
                  })}
                  id="exampleInputPassword1"
                  placeholder="Password"
                />
                {authType === 'signUp' && (
                  <small
                    id="passwordHelp"
                    className="form-text text-muted"
                  >
                    At least 8 characters. You know the drill
                  </small>
                )}
              </div>
              <div className="d-flex flex-row">
                <button
                  type="submit"
                  disabled={signingIn || (isSubmitted && !isValid)}
                  className="btn btn-primary mr-2"
                >
                  Submit
                </button>
                <button
                  className="btn btn-default center flex-grow-1"
                  onClick={() => {
                    setAuthWith('social')
                  }}
                >
                  Use another service
                </button>
              </div>
            </form>
          )}


          {errorCode && (
            <Alert className="my-1rem alert-danger">
              <AlertMessage>
                {renderAlert()}
              </AlertMessage>
            </Alert>
          )}

        </div>


      </StyledDiv>
    </>
  );
}

export default SignIn;
