import { Helmet } from 'react-helmet';
import { Link, useParams } from 'react-router-dom';
import { makeHomePathname } from 'src/sdk/PATHS';
import { navHeight } from 'src/components/Navbar/Navbar';
import { useAnalytics } from 'src/sdk/analytics/AnalyticsContext';
import PageView from 'src/components/PageView/PageView';
import React, { useMemo } from 'react';
import breakpoints from 'src/styles/breakpoints';
import styled from 'styled-components'

const StyledDiv = styled.div`
  min-height: calc(100vh - ${navHeight.xs * 2}px);

  @media (min-width: ${breakpoints.md}px) {
    min-height: calc(100vh - ${navHeight.md * 2}px);
  }
`

let errorCount = 0;

type Props = {
  reason?: string,
  statusCode?: string | number,
}

const Page404: React.FC<Props> = (props) => {

  const {
    children,
    reason,
    statusCode = 404,
  } = props;

  const params = useParams();
  const analytics = useAnalytics();

  useMemo(() => {
    if (!reason) {
      errorCount = errorCount + 1;
    }
  }, [reason]);

  return (
    <>
      <Helmet defer={false}>
        <title>{statusCode.toString()}</title>
        <meta
          name="description"
          content={reason}
        />
      </Helmet>
      <PageView
        onPageView={() => {
          analytics.page({
            name: '404',
            properties: {
              reason,
              statusCode
            }
          })
        }}
      />
      <StyledDiv className="container text-center center">
        <div>
          <h1 className="lead">
            {statusCode.toString()}
          </h1>
          <div className="w-md-75 m-auto">
            <p className="p">
              {reason || 'Unfortunately something went wrong and it is our fault.'}
            </p>
          </div>
          {children || (
            <>
              <Link
                to={makeHomePathname(params)}
                className="btn btn-default"
              >
                Home
              </Link>
            </>
          )}
        </div>
      </StyledDiv>
    </>
  );
}

export default Page404;
