import { Stripe, loadStripe } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';

if (!process.env.REACT_APP_STRIPE_KEY) {
  throw new Error('You forgot to provide a REACT_APP_STRIPE_KEY env variable')
}

const publishableKey = process.env.REACT_APP_STRIPE_KEY

export default function useStripe(): Stripe | null | undefined {

  const [stripe, setStripe] = useState<Stripe | null>()
  useEffect(() => {
    loadStripe(publishableKey).then((s) => setStripe(s));
  }, [])

  return stripe;

}
