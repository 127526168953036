import firebase from 'firebase';
import React from 'react';
import {
  FacebookIcon,
  GithubIcon,
  GoogleIcon,
  TwitterIcon
} from 'src/icons';
import { FIREBASE_AUTH_ERROR_CODES } from 'src/pages/SignIn/CODES';
import { SignInAuthType } from 'src/pages/SignIn/SignIn';
import { useDebug } from 'src/sdk/debug/DebugContext';
import { useFirebaseSDK } from 'src/sdk/firebase/FirebaseSDKContext';
import { CurriedFn } from 'src/sdk/types';
import styled from 'styled-components'

const StyledDiv = styled.div`

  .col-6 {
    margin-bottom: .5rem;
  }

  button {
    font-weight: 300;
    border: 0;
    justify-content: center;
  }

  button > .icon {
    padding-right: 8px;
  }

  .SignIn__github {
    background: #444444;
    color: white;

    &:hover {
      background: lighten(#444444, 10%);
    }
  }

  .SignIn__google {
    background: #DD4B39;
    color: white;

    &:hover {
      background: darken(#DD4B39, 10%);
    }
  }

  .SignIn__twitter {
    background: #55ACEE;
    color: white;

    &:hover {
      background: darken(#55ACEE, 10%);
    }
  }

  .SignIn__facebook {
    background: #4E6296;
    color: white;

    &:hover {
      background: darken(#4E6296, 10%);
    }
  }
`

interface Props {
  errorCallback: (e: Error | firebase.auth.Error) => void
  handleUserCredential: (c: firebase.auth.UserCredential) => void
  authType: SignInAuthType
  signingIn: boolean
  currySigningIn: CurriedFn
}


const SignInButtons: React.FC<Props> = (props) => {

  const {
    errorCallback,
    handleUserCredential,
    authType,
    signingIn,
    currySigningIn
  } = props;

  const firebaseSDK = useFirebaseSDK();
  const debug = useDebug()

  const signIn = async (provider: any) => {
    return currySigningIn(async () => {

      if (!firebaseSDK.firebaseAuth.currentUser) {
        return
      }

      return Promise.resolve(authType === 'signUp'
        ? firebaseSDK.firebaseAuth.currentUser
          .linkWithPopup(provider)
          .catch(async (e: firebase.FirebaseError & { credential?: firebase.auth.AuthCredential }) => {
            if (e.code === FIREBASE_AUTH_ERROR_CODES.CREDENTIAL_ALREADY_IN_USE && e.credential) {
              // If this provider is already linked to another user, sign in with that credential instead
              // TODO: offerToCopyStacks(user.stacks)
              const providerData = await firebaseSDK.firebaseAuth.signInWithCredential(e.credential);
              debug.log(`Signed in with ${e.credential.providerId} using credential`, { providerData });
              return providerData;
            }
            throw e;
          })
        : firebaseSDK.firebaseAuth.currentUser
          .linkWithPopup(provider)
          .catch(async (e: firebase.FirebaseError & { credential?: firebase.auth.AuthCredential }) => {
            if (e.code === FIREBASE_AUTH_ERROR_CODES.CREDENTIAL_ALREADY_IN_USE && e.credential) {
              // If this provider is already linked to another user, sign in with that credential instead
              // TODO: offerToCopyStacks(user.stacks)
              const providerData = await firebaseSDK.firebaseAuth.signInWithCredential(e.credential);
              debug.log(`Signed in with ${e.credential.providerId} using credential`, { providerData });
              return providerData;
            }
            throw e;
          })
      )
        .then(handleUserCredential)
        .catch(errorCallback)

    })

  };

  return (
    <StyledDiv className="row flex-wrap mx-n1">
      <div className="col-6 px-1">
        <button
          type="button"
          className="btn btn-dark w-100 SignIn__github"
          disabled={signingIn}
          onClick={() => signIn(firebaseSDK.firebaseGithubAuthProvider)}
        >

          <div className="icon">
            <GithubIcon
              height="1rem"
            />
          </div>
          {' '}

          GitHub
        </button>
      </div>
      <div className="col-6 px-1">
        <button
          type="button"
          className="btn btn-dark w-100 SignIn__facebook"
          disabled={signingIn}
          onClick={() => signIn(firebaseSDK.firebaseFacebookAuthProvider)}
        >
          <div className="icon">
            <FacebookIcon
              height="1rem"
            />
          </div>
          {' '}
          Facebook
        </button>
      </div>
      <div className="col-6 px-1">
        <button
          type="button"
          className="btn btn-dark w-100 SignIn__google"
          disabled={signingIn}
          onClick={() => signIn(firebaseSDK.firebaseGoogleAuthProvider)}
        >
          <div className="icon">
            <GoogleIcon
              height="1rem"
            />
          </div>
          {' '}
          Google
        </button>
      </div>
      <div className="col-6 px-1">
        <button
          type="button"
          className="btn btn-dark w-100 SignIn__twitter"
          disabled={signingIn}
          onClick={() => signIn(firebaseSDK.firebaseTwitterAuthProvider)}
        >
          <div className="icon">
            <TwitterIcon
              height="1rem"
            />
          </div>
          {' '}
          Twitter
        </button>
      </div>
    </StyledDiv>
  );
}

export default SignInButtons
