import {
  EditableStackAttributes,
  StackType
} from 'src/sdk/schemaTypes';
import stackDimensions from 'src/stackDimensions';
import _sortBy from 'src/utils/_sortBy';

export enum STACK_CODES {
  // validateProductsOnStack
  NO_LOGOS = 'NO_LOGOS',
  NOT_ENOUGH_LOGOS = 'NOT_ENOUGH_LOGOS',
  NOT_ENOUGH_ROWS = 'NOT_ENOUGH_ROWS',
  TOO_MANY_ROWS = 'TOO_MANY_ROWS',
  ROWS_NOT_FULL = 'ROWS_NOT_FULL',

  // =validateStackAttributes
  NO_STACK_NAME = 'NO_STACK_NAME',
  VALID = 'VALID'
}

export interface StackValidationType {
  name?: STACK_CODES.NO_STACK_NAME;
}

export function validateStackAttributes(stackAttributes: EditableStackAttributes): StackValidationType {
  const errors = {} as StackValidationType;
  if (!stackAttributes.name) {
    errors.name = STACK_CODES.NO_STACK_NAME;
  }
  return errors;
}

export function getProductsOnStackStatus(stack: StackType) {

  const productsOnStack = stack.relationships.products;
  if (!productsOnStack) {
    return STACK_CODES.NO_LOGOS;
  }
  const gridItems = Object.keys(productsOnStack)
    .map((productId) => productsOnStack[productId].gridItem)

  if (gridItems.length === 0) {
    return STACK_CODES.NO_LOGOS;
  }

  if (gridItems.length < 3) {
    return STACK_CODES.NOT_ENOUGH_LOGOS;
  }

  const numRows = gridItems.reduce((count, gridItem) => {
    if (gridItem.y > count) {
      count = gridItem.y;
    }
    return count;
  }, 0);

  if (numRows < stackDimensions.minRows) {
    return STACK_CODES.NOT_ENOUGH_ROWS;
  }
  if (numRows > stackDimensions.maxRows) {
    return STACK_CODES.TOO_MANY_ROWS;
  }

  // Is each row filled up
  let y = 0;
  while (y <= numRows) {
    let rowOfGridItems = gridItems
      // eslint-disable-next-line no-loop-func
      .filter(gridItem => gridItem.y === y);
    rowOfGridItems = _sortBy(rowOfGridItems, 'x');

    const columnsUsed = rowOfGridItems.reduce((sum, gridItem) => {
      return sum + gridItem.w;
    }, 0);
    if (columnsUsed < 12) {
      return STACK_CODES.ROWS_NOT_FULL;
    }
    y++;
  }

  return STACK_CODES.VALID
}

