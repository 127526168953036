import React, { useEffect, useRef } from 'react';

interface Props {
  onLoad: () => void;
}

const OnLoad: React.FC<Props> = (props) => {

  const {
    onLoad,
  } = props;

  const ref = useRef<Props['onLoad']>(onLoad)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(ref.current, [])

  return null
}

export default OnLoad
