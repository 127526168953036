import React from 'react';
import { StackProductSVGThemeProps } from 'src/components/StackProductSVGTheme/types';
import { defaultThemeProps } from 'src/components/StackProductSVGTheme/utils';

const SearchResultTheme: React.FC<StackProductSVGThemeProps> = (props) => {

  const themeProps = Object.assign(defaultThemeProps, props)

  const {
    logo,
  } = themeProps

  return (
    <svg
      viewBox={`0 0 ${logo.width} ${logo.height}`}
      height="100%"
    >
      <image
        height="68%"
        width="68%"
        x="16%"
        y="16%"
        href={logo.src}
      />
    </svg>

  );
}

export default SearchResultTheme
