import * as React from "react";

function SvgLogo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 147 147" fill="none" {...props}>
      <path
        d="M141.242 107.469c-7.845 4.079-48.483 20.753-57.134 25.254-8.651 4.502-13.456 4.461-20.29 1.197-6.834-3.263-50.077-20.693-57.87-24.408-3.899-1.855-5.945-3.422-5.945-4.901V89.786s56.299-12.23 65.391-15.488c9.086-3.252 12.244-3.369 19.978-.546 7.734 2.829 53.988 11.157 61.627 13.95l-.005 14.62c.005 1.461-1.759 3.07-5.752 5.147z"
        fill="#F90"
      />
      <path
        d="M141.236 92.726c-7.845 4.08-48.483 20.753-57.134 25.254-8.651 4.502-13.456 4.461-20.29 1.198-6.834-3.258-50.077-20.694-57.87-24.41-7.792-3.714-7.95-6.273-.3-9.26 7.652-2.994 50.66-19.831 59.752-23.083 9.086-3.251 12.244-3.369 19.978-.546 7.734 2.83 48.13 18.875 55.77 21.668 7.645 2.8 7.939 5.1.094 9.18z"
        fill="#fff"
      />
      <path
        d="M141.242 83.477c-7.845 4.079-48.483 20.752-57.134 25.26-8.651 4.501-13.456 4.454-20.29 1.197-6.834-3.257-50.077-20.694-57.87-24.409C2.05 83.665.004 82.098.004 80.62V65.788s56.299-12.23 65.391-15.482c9.086-3.252 12.244-3.375 19.978-.546 7.734 2.829 53.988 11.15 61.627 13.95l-.005 14.62c.005 1.461-1.759 3.07-5.752 5.147z"
        fill="#00ADC7"
      />
      <path
        d="M141.236 68.74c-7.845 4.079-48.483 20.753-57.134 25.254-8.651 4.502-13.456 4.455-20.29 1.197-6.834-3.257-50.077-20.694-57.87-24.409-7.792-3.715-7.95-6.273-.3-9.26 7.652-2.988 50.66-19.832 59.752-23.083 9.086-3.252 12.244-3.369 19.978-.546 7.734 2.829 48.13 18.869 55.77 21.668 7.645 2.794 7.939 5.094.094 9.18z"
        fill="#fff"
      />
      <path
        d="M141.242 58.599c-7.845 4.078-48.483 20.752-57.134 25.26-8.651 4.5-13.456 4.454-20.29 1.197-6.84-3.263-50.083-20.7-57.875-24.415-3.894-1.855-5.94-3.422-5.94-4.9V40.91s59.957 10.088 69.043 6.837c9.086-3.257 12.856-5.388 20.59-2.559 7.733 2.829 49.718-9.15 57.363-6.356l-.005 14.62c.005 1.46-1.759 3.069-5.752 5.147z"
        fill="#A93641"
      />
      <path
        d="M81.779 35.428c17.343 7.589 41.249 6.52 58.04-1.203L82.761 12.122c-16.079 5.459-16.185 16.586-.982 23.306z"
        fill="#FFEFEF"
      />
      <path
        d="M88.689 31.842c11.95 4.63 30.987 4.977 42.873-.822L89.459 14.722c-12.309 4.666-15.414 11.368-.77 17.12z"
        fill="#FF4F5E"
      />
      <path
        d="M141.143 34.677c-.353-.13-.782-.288-1.27-.47-16.785 7.712-40.674 8.774-58.005 1.192-15.173-6.703-15.09-17.8.894-23.277-5.97-1.86-9.369-1.426-17.367 1.438-9.092 3.252-52.1 20.084-59.751 23.077-7.651 2.993-7.487 5.552.3 9.261 7.786 3.715 51.035 21.146 57.87 24.409 6.833 3.257 11.638 3.304 20.289-1.197 8.65-4.502 49.289-21.175 57.134-25.254 7.845-4.08 7.551-6.386-.094-9.18z"
        fill="#FF4F5E"
      />
    </svg>
  );
}

export default SvgLogo;
