import GithubIcon from 'src/components/PropertyOfIcon/GithubIcon'
import React from 'react'
import TwitterIcon from 'src/components/PropertyOfIcon/TwitterIcon'

interface Props {
  providerId: string;
  size?: string;
}

const PropertyOfIcon: React.FC<Props> = (props) => {

  const {
    providerId,
    size = '1rem',
  } = props

  switch (providerId) {
    case 'twitter.com':
      return (
        <span className="center">
          <TwitterIcon
            alt="The Github icon"
            height={size}
          />
        </span>
      )
    case 'github.com':
      return (
        <span className="center">
          <GithubIcon
            alt="The Twitter icon"
            height={size}
          />
        </span>
      )
    default:
      return null
  }
}

export default PropertyOfIcon;
