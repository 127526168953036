import React from 'react';

const GuideSvg: React.FC = (props) => {

  const {
    children,
  } = props;

  return (
    <svg>
      <defs xmlns="http://www.w3.org/2000/svg">
        <linearGradient
          gradientTransform="rotate(5)"
          id="gradient"
          x1="-20%"
          y1="0%"
          x2="100%"
          y2="0"
        >
          <stop
            offset="-20%"
            style={{
              stopColor: 'transparent',
            }}
          />
          <stop
            offset="20%"
            style={{
              stopColor: 'transparent',
            }}
          />

          <stop
            offset="50%"
            style={{
              stopColor: 'rgba(0, 187, 255, .4)'
            }}
          />
          <stop
            offset="80%"
            style={{
              stopColor: 'transparent',
            }}
          />
          <stop
            offset="100%"
            style={{
              stopColor: 'transparent',
            }}
          />
        </linearGradient>

        <pattern
          id="pattern"
          x="-20"
          y="-20" // This is how we fixed an issue with the fill on the stack name
          width="300%"
          height="100%"
          patternUnits="userSpaceOnUse"
        >
          <rect
            x="0"
            y="0"
            width="150%"
            height="100%"
            fill="url(#gradient)"
          >
            <animate
              attributeType="XML"
              attributeName="x"
              from="0"
              to="150%"
              dur="3s"
              repeatCount="indefinite"
            />
          </rect>
          <rect
            x="-150%"
            y="0"
            width="150%"
            height="100%"
            fill="url(#gradient)"
          >
            <animate
              attributeType="XML"
              attributeName="x"
              from="-150%"
              to="0"
              dur="3s"
              repeatCount="indefinite"
            />
          </rect>
        </pattern>
        <filter
          id="dropshadow"
          height="130%"
        >
          <feGaussianBlur
            in="SourceAlpha"
            stdDeviation="3"
          />
          <feOffset
            dx="2"
            dy="2"
            result="offsetblur"
          />
          <feComponentTransfer>
            <feFuncA
              type="linear"
              slope="0.2"
            />
          </feComponentTransfer>
          <feMerge>
            <feMergeNode />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      {children}
    </svg>

  );
}

export default GuideSvg;
