import { EditableShirtAttributesType } from 'src/sdk/schemaTypes';

export enum SHIRT_CODES {
  NO_COLOR = 'NO_COLOR',
  NO_SIZE = 'NO_SIZE',
  NO_PROVIDER_ID = 'NO_PROVIDER_ID',
  NO_USERNAME = 'NO_USERNAME',
  NO_AT_SYMBOL = 'NO_AT_SYMBOL',
}

export interface ShirtAttributesValidationType {
  color: SHIRT_CODES.NO_COLOR;
  size: SHIRT_CODES.NO_SIZE;
  providerId: SHIRT_CODES.NO_PROVIDER_ID;
  username: SHIRT_CODES.NO_USERNAME | SHIRT_CODES.NO_AT_SYMBOL;
}

export function validateShirtAttributes(shirtAttributes: EditableShirtAttributesType): ShirtAttributesValidationType {

  const errors = {} as ShirtAttributesValidationType;

  if (!shirtAttributes.color) {
    errors.color = SHIRT_CODES.NO_COLOR
  }
  if (!shirtAttributes.size) {
    errors.size = SHIRT_CODES.NO_SIZE
  }
  if (!shirtAttributes.providerId) {
    errors.providerId = SHIRT_CODES.NO_PROVIDER_ID
  }
  if (!shirtAttributes.username) {
    errors.username = SHIRT_CODES.NO_USERNAME
  }
  else if (shirtAttributes.username.startsWith('@')) {
    errors.username = SHIRT_CODES.NO_AT_SYMBOL
  }

  return errors
}
