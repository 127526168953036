import { Link, LinkProps } from 'react-router-dom';
import React from 'react';
import classnames from 'classnames'

interface Props {
  className?: string
}
const Alert: React.FC<Props> = (props) => {

  const {
    children,
    className,
  } = props;

  const renderItems = () => {
    const hasAction = React.Children.count(children) > 1
    return React.Children.map(children, (child) => {
      if (React.isValidElement(child)) {
        switch (child.type) {
          case AlertMessage: {
            return (
              <div className={classnames('w-100', hasAction && 'w-sm-50 text-sm-left')}>
                {child}
              </div>
            );
          }
          case AlertLink:
          case AlertButton: {
            return (
              <div className="w-100 mt-3 w-sm-auto mt-sm-0">
                {React.cloneElement(child, { className: child.props.className + ' btn-block' })}
              </div>
            )
          }
          default:
            return null;
        }
      }
    });
  }

  return (
    <div className={classnames('p-3 alert rounded mb-1rem', className)}>
      <div className="d-flex flex-row justify-content-between align-items-center flex-wrap">
        {renderItems()}
      </div>
    </div>

  );
}

export default Alert

export const AlertButton: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = ({ children, ...props }) => {
  return (
    <button {...props}>
      {children}
    </button>
  );
}
AlertButton.displayName = 'AlertButton';

export const AlertLink: React.FC<LinkProps> = ({ children, ...props }) => {
  return (
    <Link {...props}>
      {children}
    </Link>
  );
}
AlertButton.displayName = 'AlertButton';

export const AlertMessage: React.FC = ({ children }) => {
  return (
    <div>
      {children}
    </div>
  );
}
AlertMessage.displayName = 'AlertMessage';
