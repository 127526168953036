export const xs = 0;
export const sm = 576;
export const md = 769; // Because an iPad in portrait mode is 768 wide
export const lg = 992;
export const xl = 1200;

export default {
  xs,
  sm,
  md,
  lg,
  xl,
} as const;

export type BreakpointType = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
