import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { FirebaseUniversalSDKType } from 'src/sdk/types';

export default class FirebaseClientSDK implements FirebaseUniversalSDKType {

  firebaseDb: firebase.firestore.Firestore;
  firebaseAuth: firebase.auth.Auth;

  // This is only in client SDK
  firebaseGithubAuthProvider = new firebase.auth.GithubAuthProvider();
  firebaseGoogleAuthProvider = new firebase.auth.GoogleAuthProvider();
  firebaseTwitterAuthProvider = new firebase.auth.TwitterAuthProvider();
  firebaseFacebookAuthProvider = new firebase.auth.FacebookAuthProvider();

  constructor(name: string, firebaseClientConfig: any) {
    let firebaseApp;
    if (!firebase.apps.length) {
      firebaseApp = firebase.initializeApp(firebaseClientConfig, name);
    }
    else {
      firebaseApp = firebase.app(name);
    }

    this.firebaseDb = firebaseApp.firestore();
    this.firebaseAuth = firebaseApp.auth();
  }

  makeResourceId = (type: string) => {
    const ref = this.firebaseDb.collection(type).doc();
    return ref.id;
  }

  makeTimestamp = () => {
    const date = new Date().getTime()
    const seconds = Math.floor(date / 1000)
    return Object.assign(firebase.firestore.FieldValue.serverTimestamp(), {
      seconds,
      nanoseconds: date - seconds * 1000
    })
  }

};
