import { UserType } from 'src/sdk/schemaTypes';

export type AnalyticsType = {
  page: (page: AnalyticsPageViewType) => void;
  track: (event: AnalyticsEventType) => void;
  identify: (identifier: AnalyticsIdentifyType) => void;
}

export type AnalyticsIdentifyType = {
  user: UserType
}

export type AnalyticsPageViewType = {
  name: string;
  category?: string;
  properties?: Object
};

export type AnalyticsEventType =
  AddToCartEvent
  | RemoveFromCartEvent
  | LogRocketSetupEvent


export enum EVENT_NAMES {
  ADD_TO_CART = 'AddToCart',
  REMOVE_FROM_CART = 'RemoveFromCart',
  LOG_ROCKET_SETUP = 'LogRocketSetup',
}

type BaseAnalyticsEventType = {
  name: string,
  properties?: Object
}

export type RemoveFromCartEvent = BaseAnalyticsEventType & {
  name: EVENT_NAMES.REMOVE_FROM_CART;
};

export type AddToCartEvent = BaseAnalyticsEventType & {
  name: EVENT_NAMES.ADD_TO_CART;
};

export type LogRocketSetupEvent = BaseAnalyticsEventType & {
  name: EVENT_NAMES.LOG_ROCKET_SETUP;
};
