import {
  PrintableTypesUnion,
  ShirtColorsUnion,
  ShirtSizesUnion
} from 'src/sdk/schemaTypes';

const printfulSettings = {
  shirt: {
    printfulProductId: 108,
    variantsBySexColorAndSize: {
      unisexShirt: {
        black: {
          xs: 9527,
          s: 4016,
          m: 4017,
          l: 4018,
          xl: 4019,
          xxl: 4020,
          xxxl: 5295
        },
        white: {
          xs: 9526,
          s: 4011,
          m: 4012,
          l: 4013,
          xl: 4014,
          xxl: 4015,
          xxxl: 5294
        },
        asphalt: {
          // xs: null, not available last I checked
          s: 4031,
          m: 4032,
          l: 4033,
          xl: 4034,
          xxl: 4035,
          xxxl: 5297
        }
      },
      womensShirt: {
        black: {
          s: 8814,
          m: 8815,
          l: 8816,
          xl: 8817,
          xxl: 8818,
        },
        white: {
          s: 8809,
          m: 8810,
          l: 8811,
          xl: 8812,
          xxl: 8813,
        },
        asphalt: {
          s: 8824,
          m: 8825,
          l: 8826,
          xl: 8827,
          xxl: 8828,
        }
      }
    } as Record<PrintableTypesUnion, Record<ShirtColorsUnion, Partial<Record<ShirtSizesUnion, number | null>>>>,
    frontPosition: {
      unisex: {
        area_width: 1800,
        area_height: 2400,
        width: 720,
        height: 1998, // 2400 - 402 (offsetTop)
        top: 402,
        left: 1080,
      },
      women: {}
    },
    backPosition: {
      unisex: {
        area_width: 1800,
        area_height: 2400,
        width: 1800,
        height: 2000, // 2400 - 400 (offsetTop)
        top: 400,
        left: 0
      },
      women: {}
    }
  },
}

export default printfulSettings

