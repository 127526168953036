import classnames from 'classnames';
import produce from 'immer';
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { Helmet } from 'react-helmet';
import { useFormContext } from 'react-hook-form';
import {
  useLocation,
  useParams
} from 'react-router';
import { Link } from 'react-router-dom';
import FormAndListeners from 'src/components/FormAndListeners/FormAndListeners';
import OnLoad from 'src/components/OnLoad/OnLoad';
import PageView from 'src/components/PageView/PageView';
import { ACTIVE_INPUTS } from 'src/components/ShirtCloseUp/helpers';
import useAddOrRemoveShirtOnCart from 'src/hooks/useAddOrRemoveShirtOnCart';
import { useBreakpointPrefix } from 'src/hooks/useBreakpoint';
import { useResource } from 'src/hooks/useResources';
import Page404 from 'src/pages/Page404/Page404';
import SelectColor, { SelectColorIdAttribute } from 'src/pages/Private/StackAndShirtAttributes/ShirtAttributes/Inputs/SelectColor';
import SelectSize from 'src/pages/Private/StackAndShirtAttributes/ShirtAttributes/Inputs/SelectSize';
import UsernameInput from 'src/pages/Private/StackAndShirtAttributes/ShirtAttributes/Inputs/UsernameInput';
import ShirtCongratulations from 'src/pages/Private/StackAndShirtAttributes/ShirtAttributes/ShirtCongratulations';
import ShirtMobileDrawer from 'src/pages/Private/StackAndShirtAttributes/ShirtAttributes/ShirtMobileDrawer';
import { shirtAId } from 'src/pages/Private/StackAndShirtAttributes/StackAndShirtAttributes';
import { blurOn } from 'src/pages/Private/StackAndShirtAttributes/StackAttributes/StackAttributes';
import { useAnalytics } from 'src/sdk/analytics/AnalyticsContext';
import { useMethodsSDK } from 'src/sdk/methods/MethodsSDKProvider';
import {
  makePrivateShirtsPathname,
  makePrivateStackAttributesPathname
} from 'src/sdk/PATHS';
import {
  CartOrderType,
  EditableShirtAttributesType,
  ShirtType,
  StackType,
  UserType
} from 'src/sdk/schemaTypes';
import _debounce from 'src/utils/_debounce';
import { nprogressTry } from 'src/utils/nprogress';


type LocationState = { editing: ACTIVE_INPUTS } | undefined

type Props = {
  shirt: ShirtType,
  stack: StackType,
  user: UserType
  setActiveInput: Dispatch<SetStateAction<ACTIVE_INPUTS | null>>
}

const ShirtAttributes: React.FC<Props> = (props) => {

  const {
    shirt,
    stack,
    user,
    setActiveInput,
  } = props

  const [usernameHelpText, setUsernameHelpText] = useState(false)

  /**
   * Form
   */
  const formValuesRef = useRef<EditableShirtAttributesType>()
  const formBag = useFormContext<EditableShirtAttributesType>()
  const { trigger, setFocus, watch } = formBag
  formValuesRef.current = watch()

  useEffect(() => {
    // To trigger an immediate validation
    // NOTE: you have to use trigger, not formBag.trigger
    trigger()
  }, [trigger])

  const {
    isSubmitting,
    isValid,
    touchedFields,
    errors,
  } = formBag.formState;
  const colorValue = formBag.watch('color')
  /**
   * End form
   */

  const methodsSDK = useMethodsSDK();
  const debouncedSaveShirt = useMemo(() => {
    return _debounce((shirt: ShirtType) => {
      return nprogressTry(async () => {
        const updatedShirt = produce<ShirtType>(shirt, (draft) => {
          Object.assign(draft.attributes, formValuesRef.current);
        })
        await methodsSDK.upsertResource(updatedShirt);
      })
    }, 400)
  }, [methodsSDK])

  const params = useParams<{ userId: string }>();
  const analytics = useAnalytics();
  const { data: cart } = useResource<CartOrderType>(user.relationships.cart)

  const location = useLocation<LocationState>();
  const [showCongratulations, setShowCongratulations] = useState(false);
  const containerDiv = useRef<HTMLDivElement>(null)

  const seenCongratulations = user?.attributes?.congratulatedForCompletingAShirt
  const flagAsSeenCongratulations = React.useCallback(async () => {
    if (user && !seenCongratulations) {
      const updatedUser = produce<UserType>(user, (draft) => {
        draft.attributes.congratulatedForCompletingAShirt = true;
      })
      return methodsSDK.upsertResource(updatedUser)
    }
  }, [user, seenCongratulations, methodsSDK]);

  const [isAddingOrRemoving, addOrRemoveShirtOnCart] = useAddOrRemoveShirtOnCart(shirt.id)
  const isMedium = useBreakpointPrefix('md');

  if (!user || !shirt || !stack) {
    return null;
  }

  if (shirt.attributes.createdOn) {
    if (shirt.relationships.user.id !== user.id) {
      return (
        <Page404>
          This is not your shirt. Either that or you need to login with a different user
        </Page404>
      );
    }
  }

  // const locked = shirt.relationships.orders;

  const renderCTA = () => {

    if (!cart || !shirt || shirt.id === shirtAId) {
      return
    }

    const shirtIsInCart = Boolean(cart.relationships.shirts[shirt.id])
    let CTA: React.ReactNode
    if (!seenCongratulations) {
      CTA = (
        <button
          type="button"
          onClick={() => {
            setShowCongratulations(true)
            return flagAsSeenCongratulations()
          }}
          disabled={isSubmitting || !isValid}
          className="btn btn-primary btn-block"
        >
          Done
        </button>
      )
    }
    else {
      CTA = (
        <button
          type="button"
          onClick={() => addOrRemoveShirtOnCart()}
          disabled={isSubmitting || !isValid || isAddingOrRemoving}
          className={classnames('btn btn-block', !shirtIsInCart ? 'btn-primary' : 'btn-default')}
        >
          {shirtIsInCart ? 'Remove from cart' : 'Add to cart'}
        </button>
      )
    }

    return (
      <>
        <div className="row mx-n1">
          <div className="col-6 col-md-12 col-lg-6 mb-md-2 mb-lg-0 px-1">
            {CTA}
          </div>
          <div className="col-6 col-md-12 col-lg-6 px-1">
            <Link
              to={makePrivateStackAttributesPathname({
                ...params,
                stackId: stack.id
              })}
              className="btn btn-outline btn-block"
            >
              Rename stack
            </Link>
          </div>
          {/*{!isSubmitted && !isValid && (*/}
          {/*  <small className="ml-3">*/}
          {/*    <FormattedMessage {...messages[errorCode || 'valid']} />*/}
          {/*  </small>*/}
          {/*)}*/}
        </div>
        <Link
          to={makePrivateShirtsPathname(params)}
          className="btn btn-outline btn-block mr-2 d-block d-md-none center mt-2"
        >
          Your dashboard
        </Link>
      </>
    )
  }

  return (
    <FormAndListeners
      onFocus={(e) => {
        const activeInputId = e.target.dataset.activeinputid
        console.log('activeInputId', activeInputId)
        if (Object.values(ACTIVE_INPUTS).includes(activeInputId)) {
          setActiveInput(activeInputId)
        }
      }}
      onBlur={(e) => {
        setActiveInput(null)
      }}
      onChange={() => {
        debouncedSaveShirt(shirt)
      }}
      className={classnames('Shirt__form h-100 w-100', {
        blackShirt: colorValue === 'black'
      })}
      onSubmit={formBag.handleSubmit(() => void 0)}
    >
      <Helmet defer={false}>
        <title>Your shirt</title>
        <meta
          name="description"
          content="How would you like your shirt?"
        />
      </Helmet>
      <PageView
        pageKey={shirt.id}
        onPageView={() => analytics.page({
          name: 'Shirt - Yours',
          properties: {
            shirtId: shirt.id,
            stackId: stack.id
          }
        })}
      />

      {isMedium ? (
        <div className="cy offset-md-6 col-md-6 col-lg-5 d-none d-md-block">
          <OnLoad
            onLoad={() => {
              if (location.state?.editing === ACTIVE_INPUTS.USERNAME) {
                setFocus(ACTIVE_INPUTS.USERNAME)
                setActiveInput(ACTIVE_INPUTS.USERNAME)
              }
              const div = containerDiv.current;
              if (div) {
                div.addEventListener('touchmove', blurOn)
                return () => {
                  div.removeEventListener('touchmove', blurOn)
                }
              }
            }}
          />
          <div
            style={{
              maxWidth: 500,
            }}
            className="container-fluid"
          >
            {showCongratulations ? <ShirtCongratulations shirtId={shirt.id} /> : (
              <>
                <h1 className="display-3 mb-2rem">
                  Customize your shirt
                </h1>

                <div className="form-group">
                  <label className="form-label">
                    Username
                  </label>
                  <UsernameInput />
                  <small
                    onClick={() => {
                      setActiveInput(ACTIVE_INPUTS.USERNAME)
                      if (!usernameHelpText) {
                        setUsernameHelpText(true);
                      }
                    }}
                    className="href"
                  >
                    What's this?
                  </small>
                  {usernameHelpText && (
                    <small>
                      {' '}
                      Who is going to be sporting this masterpiece?
                    </small>
                  )}
                  {errors.username && touchedFields.username && (
                    <small
                      className={classnames('invalid-feedback', usernameHelpText ? 'd-block' : 'd-inline')}
                    >
                      {' '}
                      It's required
                    </small>
                  )}
                </div>
                <div className="form-group w-100 w-md-75 w-lg-50">
                  <label
                    htmlFor={SelectColorIdAttribute}
                    className="form-label"
                  >
                    Shirt size
                  </label>
                  <SelectSize />
                </div>
                <div className="form-group w-100 w-md-75 w-lg-50">
                  <label
                    htmlFor={SelectColorIdAttribute}
                    className="form-label"
                  >
                    Shirt color
                  </label>
                  <SelectColor />
                </div>

                {renderCTA()}
              </>
            )}
          </div>
        </div>
      ) : (
        <ShirtMobileDrawer
          ref={containerDiv}
          bottomBarCTA={renderCTA()}
          initialTab={location.state?.editing}
          setActiveInput={setActiveInput}
        />
      )}

    </FormAndListeners>
  );
}
export default ShirtAttributes;
