import classnames from 'classnames'
import React from 'react';
import IntroStacks from 'src/pages/Private/Intro/IntroMedia/IntroStacks';
import { ReachedIntroStepSchema } from 'src/sdk/schemas';
import { ReachedIntroStepUnion } from 'src/sdk/schemaTypes';
import { shirtImages } from 'src/shirtImages/shirtImages';
import breakpoints from 'src/styles/breakpoints';
import styled from 'styled-components'

const StyledDiv = styled.div`
  .IntroMediaPerspective__shirtBackground {
    margin-top: -34%;
    left: -54%;
    right: -54%;
  }

  .IntroMediaPerspective__shirtImg {
    width: 100%;
  }

  .IntroMediaPerspective__perspective {
    transition: transform .4s, width .4s;
    left: 0;
    width: 100%;

    &.active {
      @media (min-width: ${breakpoints.sm}px) {
        transform-origin: center;
        transform: perspective(40em) rotateY(-31deg) translateX(-31px);

        position: relative;
        width: 272px;
      }
      @media (min-width: ${breakpoints.md}px) {
        position: relative;
        transform: perspective(40em) rotateY(-31deg) translateX(-47px);
        width: 371px;
      }
      @media (min-width: ${breakpoints.lg}px) {
        position: relative;
        transform: perspective(40em) rotateY(-31deg) translateX(-72px);
        width: 500px;
      }
      @media (min-width: ${breakpoints.xl}px) {
        position: relative;
        transform: perspective(40em) rotateY(-31deg) translateX(-91px);
        width: 590px;
      }
    }
  }
`

interface Props {
  stepId: ReachedIntroStepUnion;
}

const IntroMediaPerspective: React.FC<Props> = (props) => {

  const {
    stepId,
  } = props;

  return (
    <StyledDiv>
      {stepId === ReachedIntroStepSchema.enum.shirtBack && (
        <div className="fadeIn fill IntroMediaPerspective__shirtBackground">
          <img
            alt="The back of a shirt with an example stack on it"
            className="IntroMediaPerspective__shirtImg"
            src={shirtImages.whiteBack1}
          />
        </div>
      )}
      <div className={classnames('IntroMediaPerspective__perspective', stepId !== ReachedIntroStepSchema.enum.shirtBack && 'active')}>
        <IntroStacks stepId={stepId} />
      </div>
    </StyledDiv>
  );
}

export default IntroMediaPerspective
