import firebase from 'firebase';
import PropTypes from 'prop-types';
import React, {
  useEffect,
  useRef,
  useState
} from 'react';
import { useLocation } from 'react-router-dom';
import { useAnalytics } from 'src/sdk/analytics/AnalyticsContext';
import AuthProvider from 'src/sdk/auth/AuthProvider';
import { useDebug } from 'src/sdk/debug/DebugContext';
import { useFirebaseSDK } from 'src/sdk/firebase/FirebaseSDKContext';
import { useMethodsSDK } from 'src/sdk/methods/MethodsSDKProvider';
import { UserType } from 'src/sdk/schemaTypes';
import { useSentry } from 'src/sdk/sentry/SentryContext';
import {
  AuthType,
  RESOURCE_TYPES
} from 'src/sdk/types';
import { useUsersSDK } from 'src/sdk/users/UsersSDKContext';

export const initialAuth: AuthType = {
  initialized: false,
  authenticated: false,
  uid: null
};

const AuthListener: React.FC = (props) => {

  const {
    children,
  } = props;

  const sentry = useSentry();
  const analytics = useAnalytics();
  const firebaseSDK = useFirebaseSDK();
  const debug = useDebug()
  const methodsSDK = useMethodsSDK();
  const usersSDK = useUsersSDK();
  const [auth, setAuth] = useState(initialAuth);

  const location = useLocation();
  const [queryParams] = useState(new URLSearchParams(location.search))


  const authRef = useRef<AuthType>(auth);
  authRef.current = auth;
  // ^^^^ 👆 We need authRef for the listener

  const [, setAlertCode] = useState(''); // We need to use the alertCode, right?

  useEffect(() => {

    debug.log('Adding listener on auth');

    const listener = firebaseSDK.firebaseAuth.onAuthStateChanged(
      async (firebaseUser: firebase.User | null) => {
        try {
          debug.log('onAuthStateChanged');
          if (!firebaseUser) {
            if (authRef.current.initialized) {
              setAuth(initialAuth); // Then we logged out!
            }
            return firebaseSDK.firebaseAuth.signInAnonymously()
          }

          // Worth noting that this will fire when linking to a provider (github, twitter, ...)
          // But, it will not add the userCredential. That happens only in the signIn/link firebase methods!
          if (authRef.current.uid === firebaseUser.uid) {
            return debug.log('Why did auth change on user?');
          }

          debug.log(`Just authed user ${firebaseUser.uid}`);

          // 👇 this is only in case the user is authenticated but somehow we don't have him/her in the db
          if (!authRef.current.initialized) {

            const userId = firebaseUser.uid === 'admin' && queryParams.has('userId') ? queryParams.get('userId') as string : firebaseUser.uid
            let user = await methodsSDK.getResource<UserType>({
              id: userId,
              type: RESOURCE_TYPES.USERS
            })
            if (!user) {
              user = usersSDK.createUser(firebaseUser)
              await methodsSDK.upsertResource(user);
            }
            setAuth({
              initialized: true,
              authenticated: !firebaseUser.isAnonymous,
              uid: userId,
            });
          }
        } catch (error) {
          debug.error(error); // Handle it in action if you're using an action (make it reusable).
          setAlertCode('unknown-auth-error');
        }
      },
      (e: firebase.auth.Error) => {
        const error = new Error(`Issue with auth: ${e.message}`)
        debug.error(error)
      }
    );

    return () => {
      if (listener) {
        listener();
      }
      debug.log('Listener on auth removed');
    };

  }, [queryParams, debug, firebaseSDK, methodsSDK, usersSDK, analytics, sentry]);

  // The entire objective of this component is to keep latest auth in context
  return (
    <AuthProvider
      auth={auth}
      setAuth={setAuth}
    >
      {children}
    </AuthProvider>
  );

}

AuthListener.propTypes = {
  children: PropTypes.node,
};
AuthListener.defaultProps = {};

export default AuthListener;
