import { FlipElementBoundsType } from 'src/components/Flip/types';

export const getBoundsHelper = (flipNode: HTMLElement, flipperNode: HTMLElement, useBCR?: boolean): FlipElementBoundsType => {

  if (useBCR) {
    const {
      x,
      y,
      width,
      height,
    } = flipNode.getBoundingClientRect();
    return {
      left: x,
      top: y,
      width: width,
      height: height,
    }
  }

  let el = flipNode;
  let left = 0;
  let top = 0;
  let height = el.offsetHeight;
  let width = el.offsetWidth;

  while (el && el !== flipperNode) {
    left += el.offsetLeft;
    top += el.offsetTop;
    el = el.offsetParent as HTMLElement;
  }
  // if (!el) {
  //   return;
  // }

  return {
    left,
    top,
    height,
    width,
  }
}
