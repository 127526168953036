import classnames from 'classnames';
import React, {
  createContext,
  useMemo,
  useRef
} from 'react'
import {
  FlipElementBoundsType,
  FlipKeyType,
  FlipperState
} from 'src/components/Flip/types';
import { getBoundsHelper } from 'src/components/Flip/utils';

export const FlipperContext = createContext<FlipperState>({} as FlipperState)

interface Props {
  flipKey: FlipKeyType;
  debug?: boolean;
  className?: string
}

const Flipper: React.FC<Props> = (props) => {

  const {
    children,
    debug,
    flipKey,
    className,
  } = props;

  const flipperDiv = useRef<HTMLDivElement>(null);
  const getBoundsByFlipIdRef = useRef<Record<string, () => FlipElementBoundsType>>({})


  const flipState: FlipperState = useMemo(() => {
    // Call this in a useEffect in Flip to create all getBounds methods
    const register: FlipperState['register'] = (flipId, el, useBCR) => {
      if (getBoundsByFlipIdRef.current[flipId]) {
        const e = new Error('You cannot render two Flip components with the same flipId at the same time under the same Flipper component');
        console.error(e)
        return;
      }
      getBoundsByFlipIdRef.current[flipId] = () => {
        // Return bounds
        delete getBoundsByFlipIdRef.current[flipId]
        return getBoundsHelper(el, flipperDiv.current!, useBCR)
      }
    }

    const boundsByFlipId: Record<string, FlipElementBoundsType> = {}
    if (flipKey) {
      // This will run before old DOM (on old flipKey) unmounts?
      for (const flipId in getBoundsByFlipIdRef.current) {
        const getBounds = getBoundsByFlipIdRef.current[flipId]
        const bounds = getBounds();
        boundsByFlipId[flipId] = bounds
      }
    }

    return {
      debug,
      flipKey,
      boundsByFlipId,
      flipperDiv,
      register,
    }
  }, [debug, flipKey])

  return (
    <FlipperContext.Provider value={flipState}>
      <div
        style={{
          position: 'relative',
        }}
        className={classnames('Flipper', className)}
        ref={flipperDiv}
      >
        {children}
      </div>
    </FlipperContext.Provider>
  )
}

Flipper.propTypes = {}

export default Flipper;
