import React, {
  createContext,
  useContext
} from 'react';
import MethodsSDK from './MethodsSDK';

export const MethodsSDKContext = createContext<MethodsSDK | null>(null)

export const useMethodsSDK = () => {
  const methodsSDK = useContext(MethodsSDKContext);

  if (!methodsSDK) {
    throw Error('You need to have a MethodsSDKContext.Provider')
  }
  return methodsSDK;
}

const MethodsSDKProvider: React.FC<{ methodsSDK: MethodsSDK }> = (props) => {

  const {
    children,
    methodsSDK,
  } = props;

  return (
    <MethodsSDKContext.Provider value={methodsSDK}>
      {children}
    </MethodsSDKContext.Provider>
  )
}

export default MethodsSDKProvider
