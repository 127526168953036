import * as React from "react";

function SvgDashboardIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 36 36" fill="none" {...props}>
      <path
        d="M29.5 11.5C25.833 15.167 19.4 22.6 19 23h14.5c1.2-4-2.167-9.667-4-11.5z"
        fill="#C4C4C4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.264 17.26l-8.725 7.27a5.673 5.673 0 00-.793.919c-2.426 3.504 2.609 7.563 5.491 4.427.272-.297.514-.624.72-.977l5.182-10.127c.709-1.213-.85-2.47-1.875-1.512zM35 22.57v.014c0 .728-.603 1.313-1.329 1.313h-3.893a1.35 1.35 0 01-1.347-1.348v-.039a1.354 1.354 0 011.35-1.361h2.315a13.694 13.694 0 00-2.987-7.223l-1.56 1.567a1.4 1.4 0 01-1.982 0l-.02-.02a1.415 1.415 0 010-1.99l1.56-1.567a13.574 13.574 0 00-7.192-3V11.2c0 .773-.623 1.4-1.393 1.4h-.04a1.4 1.4 0 01-1.397-1.404v-2.28a13.574 13.574 0 00-7.193 3l1.561 1.567a1.415 1.415 0 010 1.99l-.043.043a1.368 1.368 0 01-1.935 0l-1.584-1.59a13.694 13.694 0 00-2.987 7.223h2.319a1.35 1.35 0 011.346 1.357v.039a1.354 1.354 0 01-1.35 1.352h-3.89A1.325 1.325 0 012 22.585v-.014C2 13.434 9.402 6 18.5 6 27.598 6 35 13.434 35 22.571z"
        fill="#000"
      />
    </svg>
  );
}

export default SvgDashboardIcon;
