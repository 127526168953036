import { createContext, useContext } from 'react';
import ShirtsSDK from 'src/sdk/shirts/ShirtsSDK';

export const ShirtsSDKContext = createContext<ShirtsSDK | null>(null)

export const useShirtsSDK = () => {
  const shirtsSDK = useContext(ShirtsSDKContext);

  if (!shirtsSDK) {
    throw Error('You need to have a ShirtsSDKContext.Provider')
  }
  return shirtsSDK;
}
