import React from 'react';
import Flip from 'src/components/Flip/Flip';
import { FLIPPABLE_STEP_IDS } from 'src/pages/Private/Intro/Intro';
import { ReachedIntroStepSchema } from 'src/sdk/schemas';
import { ReachedIntroStepUnion } from 'src/sdk/schemaTypes';
import breakpoints from 'src/styles/breakpoints';
import styled from 'styled-components'

const makeGutters = (gutter: number) => {
  const half = gutter / 2
  return `
    padding: ${gutter}px;
    .row {
      margin-left: -${half}px;
      margin-right: -${half}px;
      margin-bottom: ${gutter}px;
      
      div {
        padding-left: ${half}px;
        padding-right: ${half}px;    
      }
    }
  `
}

const StyledDiv = styled.div`
  transition: margin-top .4s;
  border-width: 1px;
  border-radius: 2px;

  .rounded {
    border-width: 1px;
    border-radius: 2px;
  }

  ${makeGutters(4)};

  @media (min-width: ${breakpoints.sm}px) {
    border-radius: 4px;
    border-width: 2px;
    .rounded {
      border-radius: 4px;
      border-width: 2px;
    }
  }
  @media (min-width: ${breakpoints.lg}px) {
    ${makeGutters(8)}
  }

  .row:last-child {
    margin-bottom: 0;
  }

  .gridItem {
    content: '';
    height: 28px;

    @media (min-width: ${breakpoints.sm}px) {
      height: 42px;
    }
    @media (min-width: ${breakpoints.md}px) {
      height: 60px;
    }
    @media (min-width: ${breakpoints.lg}px) {
      height: 80px;
    }
    @media (min-width: ${breakpoints.xl}px) {
      height: 100px;
    }
  }
`

interface Props {
  stepId: ReachedIntroStepUnion;
}

const FLIP_COLORS = [
  '#552F62',
  '#FFBC47',
  '#F65E5D',
  '#40CEE3',
  '#147587',
  '#FFCC7C',
  '#51D0E7',
  '#1D2D5F',
]

const IntroStacks: React.FC<Props> = (props) => {

  const {
    stepId,
  } = props;

  const makeGridItem = React.useCallback((background) => {

    if (!FLIPPABLE_STEP_IDS.includes(stepId)) {
      return (
        <div className="gridItem border shadow rounded" />
      )
    }

    if (FLIP_COLORS.includes(background)) {
      return (
        <Flip
          flipId={background}
          className="gridItem border shadow rounded"
          style={{
            zIndex: 12,
            background,
          }}
        />
      )
    }

    return (
      <div
        className="gridItem border shadow rounded"
        style={{
          background,
        }}
      />
    )
  }, [stepId])

  return (
    <StyledDiv
      className="border border-gray rounded"
    >
      {stepId === ReachedIntroStepSchema.enum.maxRows ? (
        <>
          <div className="row">
            <div className="col-7">{makeGridItem(FLIP_COLORS[2])}</div>
            <div className="col-5">{makeGridItem('#603813')}</div>
          </div>
          <div className="row">
            <div className="col-3">{makeGridItem(FLIP_COLORS[1])}</div>
            <div className="col-7">{makeGridItem(FLIP_COLORS[3])}</div>
            <div className="col-2">{makeGridItem('#696969')}</div>
          </div>
          <div className="row">
            <div className="col-4">{makeGridItem(FLIP_COLORS[0])}</div>
            <div className="col-4">{makeGridItem('#232526')}</div>
            <div className="col-4">{makeGridItem('#E43A15')}</div>
          </div>
          <div className="row">
            <div className="col-6">{makeGridItem('#FC354C')}</div>
            <div className="col-4">{makeGridItem(FLIP_COLORS[4])}</div>
            <div className="col-2">{makeGridItem(FLIP_COLORS[5])}</div>
          </div>
          <div className="row">
            <div className="col-3">{makeGridItem('#5D4157')}</div>
            <div className="col-4">{makeGridItem(FLIP_COLORS[6])}</div>
            <div className="col-5">{makeGridItem(FLIP_COLORS[7])}</div>
          </div>
          <div className="row">
            <div className="col-12">{makeGridItem('#0ABFBC')}</div>
          </div>
        </>
      ) : (
        <>
          <div
            className="row"
            style={{
              transition: 'transform .5s',
              transform: stepId === ReachedIntroStepSchema.enum.frontend ? 'scale(1.16) translateY(-22%)' : undefined,
            }}
          >
            <div className="col-4">{makeGridItem(FLIP_COLORS[0])}</div>
            <div className="col-4">{makeGridItem(FLIP_COLORS[6])}</div>
            <div className="col-4">{makeGridItem(FLIP_COLORS[5])}</div>
          </div>
          <div className="row">
            <div className="col-5">{makeGridItem(FLIP_COLORS[7])}</div>
            <div className="col-7">{makeGridItem(FLIP_COLORS[2])}</div>
          </div>
          <div
            className="row"
            style={{
              transition: 'transform .5s',
              transform: stepId === ReachedIntroStepSchema.enum.backend ? 'scale(1.16) translateY(22%)' : undefined,
            }}
          >
            <div className="col-3">{makeGridItem(FLIP_COLORS[1])}</div>
            <div className="col-7">{makeGridItem(FLIP_COLORS[3])}</div>
            <div className="col-2">{makeGridItem(FLIP_COLORS[4])}</div>
          </div>
        </>
      )}
    </StyledDiv>
  );

}

export default IntroStacks;
