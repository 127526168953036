import classnames from 'classnames'
import React from 'react';
import {
  Link,
  useParams
} from 'react-router-dom';
import { ACTIVE_INPUTS } from 'src/components/ShirtCloseUp/helpers';
import StackOnlySVG from 'src/components/StackSVGs/StackOnlySVG';
import {
  Table,
  Tr
} from 'src/components/Table/Table';
import useCreateShirt from 'src/hooks/useCreateShirt';
import {
  makePrivateStackAttributesPathname,
  makePrivateStackPathname
} from 'src/sdk/PATHS';
import { FacingSchema } from 'src/sdk/schemas';
import {
  StackType,
  UserType
} from 'src/sdk/schemaTypes';

type Props = {
  stack: StackType;
  user: UserType;
}

const StackCard: React.FC<Props> = (props) => {

  const {
    stack,
    user,
  } = props;

  const params = useParams<{ userId: string }>();

  const stackPathname = makePrivateStackPathname({
    ...params,
    userId: user.id,
    stackId: stack.id,
  })

  const [creatingShirt, createShirtOnStack] = useCreateShirt();

  return (
    <div className="StackCard">
      <div className="row flex-wrap">
        <div className="col-md-5 col-lg-4 d-flex flex-column justify-content-between">
          {/* And 👇 this mb-1rem will push StackOnlySVG on mobile */}
          <Table className="mb-1rem">
            <Tr>
              <>
                Name:
              </>
              <Link
                to={{
                  pathname: makePrivateStackAttributesPathname({
                    ...params,
                    stackId: stack.id
                  }),
                  state: { editing: ACTIVE_INPUTS.NAME }
                }}
                className={classnames(!stack.attributes.name && 'text-red font-italic')}
              >
                {stack.attributes.name || 'Untitled'}
              </Link>
            </Tr>
            <Tr>
              <>
                Version:
              </>
              <Link
                to={{
                  pathname: makePrivateStackAttributesPathname({
                    ...params,
                    stackId: stack.id
                  }),
                  state: { editing: ACTIVE_INPUTS.VERSION }
                }}
                className={classnames(!stack.attributes.version && 'text-red font-italic')}
              >
                {stack.attributes.version || 'None provided'}
              </Link>
            </Tr>
          </Table>

          <div className="w-100 w-md-auto mb-1rem mb-md-0">
            <Link
              to={stackPathname}
              className="btn btn-default btn-block"
            >
              Edit Layout
            </Link>
            <button
              onClick={() => createShirtOnStack(stack)}
              disabled={creatingShirt}
              className="btn btn-outline btn-block"
            >
              Start new shirt
            </button>
          </div>
        </div>
        <div className="col-md-7 col-lg-8">
          <StackOnlySVG
            stack={stack}
            facing={FacingSchema.enum.back}
          />
        </div>
      </div>
    </div>

  );
};

export default StackCard;
