import React from 'react';
import DefaultTheme from 'src/components/StackProductSVGTheme/DefaultTheme/DefaultTheme';
import InsetShadowTheme from 'src/components/StackProductSVGTheme/InsetShadowTheme/InsetShadowTheme';
import SearchResultTheme from 'src/components/StackProductSVGTheme/SearchResultTheme/SearchResultTheme';
import { StackProductSVGThemeProps } from 'src/components/StackProductSVGTheme/types';
import WhiteBorderTheme from 'src/components/StackProductSVGTheme/WhiteBorderTheme/WhiteBorderTheme';
import { ThemeUnion } from 'src/sdk/schemaTypes';


type Props = StackProductSVGThemeProps & {
  theme: ThemeUnion
}

const StackProductSVGTheme: React.FC<Props> = (props) => {

  const {
    theme,
    ...otherProps
  } = props

  switch (theme) {
    case 'whiteBorder':
      return (
        <WhiteBorderTheme {...otherProps} />
      )
    case 'insetShadowTheme':
      return (
        <InsetShadowTheme {...otherProps} />
      )
    case 'searchResult':
      return (
        <SearchResultTheme {...otherProps} />
      )
    default:
      return (
        <DefaultTheme {...otherProps} />
      );
  }

}

export default StackProductSVGTheme
