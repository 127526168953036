import React, {
  createContext,
  Dispatch
} from 'react';
import { initialAuth } from 'src/pages/AuthListener/AuthListener';
import { AuthType } from 'src/sdk/types';

export interface AuthContextType {
  auth: AuthType;
  setAuth: Dispatch<AuthType>
}

export const AuthContext = createContext<AuthContextType>({
  auth: initialAuth,
  setAuth: (v: AuthType) => void v
});

const AuthProvider: React.FC<AuthContextType> = (props) => {

  const {
    children,
  } = props

  return (
    <AuthContext.Provider value={props}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
