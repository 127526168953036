import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router';
import PageView from 'src/components/PageView/PageView';
import ShirtMockup from 'src/components/ShirtMockup/ShirtMockup';
import {
  Table,
  Tr
} from 'src/components/Table/Table';
import { useResource } from 'src/hooks/useResources';
import { UserContext } from 'src/pages/AuthListener/User';
import messages from 'src/pages/Private/Dashboard/DashboardOrders.messages'

import { useAnalytics } from 'src/sdk/analytics/AnalyticsContext';
import productSettings from 'src/sdk/PRODUCT_SETTINGS';
import { FacingSchema } from 'src/sdk/schemas';
import { SubmittedOrderType } from 'src/sdk/schemaTypes';
import { appShirtImagesMap } from 'src/shirtImages/shirtImages';
import breakpoints from 'src/styles/breakpoints';
import _dayjs from 'src/utils/_dayjs';
import styled from 'styled-components'

const StyledDiv = styled.div`

  .Order__mockup {
    margin-bottom: -200px;

    @media (min-width: ${breakpoints.md}px) {
      margin-bottom: 0;
      position: absolute;
      left: -10%;
    }
    @media (min-width: ${breakpoints.lg}px) {
      top: -4%;
    }
  }

`

const Order: React.FC = () => {

  const { data: user } = useContext(UserContext);
  const params = useParams<{ orderId: string }>();
  const orderSWR = useResource<SubmittedOrderType>({
    id: params.orderId,
    type: 'orders',
  })
  const { data: order } = orderSWR;
  const analytics = useAnalytics();

  if (!user || !order || !order) {
    return null;
  }

  return (
    <>
      <Helmet defer={false}>
        <title>Your order</title>
        <meta
          name="description"
          content="About your order"
        />
      </Helmet>
      <PageView
        pageKey={order.id}
        onPageView={() => analytics.page({
          name: 'Your Order',
          properties: {
            orderId: order.id,
          }
        })}
      />
      <StyledDiv className="container cy">

        <h1 className="display-2">
          {_dayjs(order.attributes.createdOn).format('MMM D, YYYY')} order
        </h1>
        <h2 className="lead">
          #{order.id}
        </h2>

        <p className="display-p">
          <strong>
            Status:
          </strong>
          {' '}
          <FormattedMessage
            {...messages[order.meta.state]}
          />
        </p>


        <section className="row mb-4rem">

          <div className="col-6">
            <div className="underlined">
              <h5 className="uppercase-label">
                Recipient
              </h5>
            </div>

            <div>
              <div>
                {order.attributes.shippingAddress.name}
              </div>
              <div>
                {order.attributes.shippingAddress.street1}
              </div>
              <div>
                {order.attributes.shippingAddress.street2}
              </div>
              <div>
                <span>
                  {order.attributes.shippingAddress.city}
                  ,
                  {order.attributes.shippingAddress.state}
                  {' '}
                  {order.attributes.shippingAddress.zip}
                </span>
              </div>
            </div>
          </div>

          <div className="col-6">
            <div className="underlined">
              <h5 className="uppercase-label">
                Cost
              </h5>
            </div>

            <div className="d-row d-flex justify-content-between">
              <div>
                Subtotal
              </div>
              <div>
                ${Object.keys(order.meta.shirts).length * productSettings.shirt.price}.00
              </div>
            </div>

            <div className="d-row d-flex justify-content-between">
              <div>
                Shipping
              </div>
              <div>
                $0
              </div>
            </div>

            <strong className="d-row d-flex justify-content-between">
              <div>
                Total
              </div>
              <div>
                ${Object.keys(order.meta.shirts).length * productSettings.shirt.price}.00
              </div>
            </strong>
          </div>
        </section>


        <ul className="list-group">
          {Object.values(order.meta.shirts).map((shirt) => {
            const stack = order.meta.stacks[shirt.relationships.stack.id];
            return (
              <li
                key={shirt.id}
                className="list-group-item overflow-hidden"
              >
                <div
                  style={{
                    minHeight: 220,
                  }}
                  className="row"
                >
                  <div className="col-6 d-flex flex-row flex-wrap">
                    <div className="d-flex flex-column justify-content-between w-100">
                      <Table className="mb-1rem">
                        <Tr>
                          <>Property of:</>
                          <>{shirt.attributes.username}</>
                        </Tr>
                        <Tr>
                          <>Size:</>
                          <>{shirt.attributes.size}</>
                        </Tr>
                        <Tr>
                          <>Color:</>
                          <>{shirt.attributes.color}</>
                        </Tr>
                        <Tr>
                          <>Stack:</>
                          <>{stack.attributes.name}</>
                        </Tr>
                      </Table>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="w-100 Order__mockup">
                      <ShirtMockup
                        stack={stack}
                        shirt={shirt}
                        facing={FacingSchema.enum.back}
                        shirtImagesMap={appShirtImagesMap}
                      />
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </StyledDiv>
    </>
  );
}

export default Order;
