import Alert, {
  AlertLink,
  AlertMessage
} from 'src/components/Alert/Alert';
import PageView from 'src/components/PageView/PageView';
import {
  Table,
  Tr
} from 'src/components/Table/Table';
import { useResources } from 'src/hooks/useResources';
import useWindow from 'src/hooks/useWindow';
import { UserContext } from 'src/pages/AuthListener/User';
import messages from 'src/pages/Private/Dashboard/DashboardOrders.messages';
import {
  makePrivateCartPathname,
  makePrivateOrderPathname
} from 'src/sdk/PATHS';
import productSettings from 'src/sdk/PRODUCT_SETTINGS';
import { useAnalytics } from 'src/sdk/analytics/AnalyticsContext';
import { SubmittedOrderType } from 'src/sdk/schemaTypes';
import breakpoints from 'src/styles/breakpoints';
import _dayjs from 'src/utils/_dayjs';
import React, {
  useContext,
  useMemo
} from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import {
  Link,
  useParams
} from 'react-router-dom';
import Message from 'src/components/Message/Message';

const DashboardOrders: React.FC = () => {

  const params = useParams()
  const { data: user } = useContext(UserContext);
  const { data: orders } = useResources<SubmittedOrderType>(user?.relationships?.orders)
  const windowSize = useWindow();
  const matchesSm = useMemo(() => windowSize.width >= breakpoints.sm, [windowSize]);
  const analytics = useAnalytics();

  if (!user || !orders) {
    return null;
  }

  return (
    <>
      <Helmet defer={false}>
        <title>Your orders</title>
        <meta
          name="description"
          content="Your orders"
        />
      </Helmet>
      <PageView
        onPageView={() => analytics.page({
          name: 'Orders',
          category: 'Dashboard'
        })}
      />
      {Object.keys(orders).length === 0 && (
        <Alert className="fadeInFromRight">
          <AlertMessage>
            Hmm. You haven’t ordered anything from us yet.
          </AlertMessage>
          <AlertLink
            className="btn btn-primary"
            to={makePrivateCartPathname(params)}
          >
            View your cart
          </AlertLink>
        </Alert>
      )}

      <div className="list-group fadeInFromRight third">
        {
          Object.values(orders).map((order) => {

            const shirtCount = Object.keys(order.meta.shirts).length

            const renderHalf = () => {
              return (
                <>
                  <Tr>
                    <>Cost:</>
                    <>
                      ${shirtCount * productSettings.shirt.price}.00
                    </>
                  </Tr>
                  <Tr>
                    <>Shipped to:</>
                    <>{order.attributes.shippingAddress.name}</>
                  </Tr>
                  <Tr>
                    <>Status:</>
                    <>
                      <Message
                        message={messages[order.meta.state]}
                      />
                    </>
                  </Tr>
                </>
              )
            }

            return (
              <div
                key={order.id}
                className="list-group-item list-group-item-action"
              >
                <Link
                  to={{
                    pathname: makePrivateOrderPathname({
                      ...params,
                      orderId: order.id
                    })
                  }}
                  className="stretched-link"
                />
                <div className="row">
                  <div className="col-sm-6">

                    <Table>
                      <Tr>
                        <>Order #:</><>{order.id}</>
                      </Tr>
                      <Tr>
                        <>Date:</>
                        <>
                          {_dayjs(order.attributes.createdOn).format('MMM D, YYYY')}
                        </>
                      </Tr>
                      <Tr>
                        <>Items:</>
                        <>
                          <FormattedMessage
                            {...messages.numberOfShirtsInOrder}
                            values={{
                              shirtCount
                            }}
                          />
                        </>
                      </Tr>
                      {!matchesSm && renderHalf()}
                    </Table>
                  </div>
                  <div className="d-none d-sm-block col-6">
                    <Table>
                      {renderHalf()}
                    </Table>
                  </div>
                </div>
              </div>
            );
          })
        }
      </div>
    </>
  );
}

export default DashboardOrders;
