import { RefObject, useEffect, useState } from 'react';

export default function useRectangle(ref: RefObject<HTMLElement | undefined>) {
  const [rectangle, setRectangle] = useState<DOMRectReadOnly>({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    width: 0,
    height: 0,
    x: 0,
    y: 0,
    toJSON: () => void 0,
  })

  useResizeObserver(ref, setRectangle)
  // 👆 if you don't pass setRectangle as the function you better do a useCallback or ref
  return rectangle
}

interface ResizeObserverEntry {
  target: HTMLElement;
  contentRect: DOMRectReadOnly;
}

type ObserverCallback = (entry: DOMRectReadOnly) => void;

// 👇 This is a pain to test. Only use useRectangle
function useResizeObserver(ref: RefObject<HTMLElement | undefined>, callback: ObserverCallback) {

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const node = ref.current;
    let mounted = true;
    const resizeObserver = new (window as any).ResizeObserver((entries: ResizeObserverEntry[]) => {
      // We wrap it in requestAnimationFrame to avoid this error - ResizeObserver loop limit exceeded
      window.requestAnimationFrame(() => {
        if (!Array.isArray(entries) || !entries.length) {
          return;
        }

        // Since we only observe the one element, we don't need to loop over the
        // array
        if (!entries.length) {
          return;
        }

        if (mounted) {
          callback(node.getBoundingClientRect());
        }
      });
    });

    resizeObserver.observe(node);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      mounted = false;
      resizeObserver.unobserve(node);
    }

  }, [callback, ref]);
}
