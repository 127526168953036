import { StackType } from 'src/sdk/schemaTypes';
import { UserContext } from 'src/pages/AuthListener/User';
import { makePrivateShirtAttributesPath } from 'src/sdk/PATHS';
import { useHistory, useParams } from 'react-router';
import { useMethodsSDK } from 'src/sdk/methods/MethodsSDKProvider';
import { useShirtsSDK } from 'src/sdk/shirts/ShirtsSDKContext';
import { useStacksSDK } from 'src/sdk/stacks/StacksSDKContext';
import { useUsersSDK } from 'src/sdk/users/UsersSDKContext';
import React, { useContext } from 'react';
import usePromise from 'src/hooks/usePromise';

export default function useCreateShirt(): [boolean, (stack: StackType) => Promise<any>] {

  const { data: user } = useContext(UserContext);
  const usersSDK = useUsersSDK();
  const stacksSDK = useStacksSDK();
  const shirtsSDK = useShirtsSDK();
  const methodsSDK = useMethodsSDK();
  const history = useHistory();
  const params = useParams<{ stackId: string, userId: string }>();

  const [creatingShirt, callAsync] = usePromise();

  const createShirtOnStack = React.useCallback((stack: StackType) => {
    return callAsync(async () => {
      if (!user || !stack) {
        return null;
      }

      const newShirt = shirtsSDK.makeShirt(user, stack.id);
      const updatedUser = usersSDK.addShirtOnUser(newShirt, user);
      const updatedStack = stacksSDK.addShirtToStack(newShirt, stack);

      /**
       * TODO: You need to do this then cache it
       */
      const updatedResources = [
        updatedUser,
        updatedStack,
        newShirt,
      ];
      await methodsSDK.upsertResources(updatedResources)

      history.push({
        pathname: makePrivateShirtAttributesPath({
          ...params,
          stackId: newShirt.relationships.stack.id,
          shirtId: newShirt.id,
        }),
      });
    });
  }, [callAsync, methodsSDK, shirtsSDK, stacksSDK, usersSDK, user, history, params]);


  return [creatingShirt, createShirtOnStack]
}
