import produce from 'immer';
import React, {
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import {
  FormProvider,
  useForm
} from 'react-hook-form';
import {
  Route,
  Switch,
  useParams,
  useRouteMatch
} from 'react-router';
import { ActiveInputType } from 'src/components/ShirtCloseUp/helpers';
import ShirtCloseUp from 'src/components/ShirtCloseUp/ShirtCloseUp';
import { useResource } from 'src/hooks/useResources';
import { usePrivate } from 'src/pages/Private/Private';
import ShirtAttributes from 'src/pages/Private/StackAndShirtAttributes/ShirtAttributes/ShirtAttributes';
import StackAttributes from 'src/pages/Private/StackAndShirtAttributes/StackAttributes/StackAttributes';
import {
  PRIVATE_SHIRT_ATTRIBUTES_PATH,
  PRIVATE_STACK_ATTRIBUTES_PATH
} from 'src/sdk/PATHS';
import { FacingSchema } from 'src/sdk/schemas';
import {
  EditableShirtAttributesType,
  EditableStackAttributes,
  ShirtType,
  StackType,
  UserType
} from 'src/sdk/schemaTypes';
import { useShirtsSDK } from 'src/sdk/shirts/ShirtsSDKContext';
import breakpoints from 'src/styles/breakpoints';

import styled from 'styled-components'

export const shirtAId = 'shirtA'

const StyledDiv = styled.div`

  .StackAttributes__shirtCloseUp {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 10;
  }

  @media (min-width: ${breakpoints.md}px) {
    .StackAttributes__shirtCloseUp {
      width: 50%;
    }
  }

`

type Props = {
  stack: StackType
  shirt: ShirtType
  user: UserType
}

const StackAndShirtAttributes: React.FC<Props> = (props) => {

  const {
    user,
    stack,
    shirt,
  } = props

  const [activeInput, setActiveInput] = useState<ActiveInputType>(null)


  /**
   * Form
   */
  const stackAttributesFormBag = useForm<EditableStackAttributes>({
    mode: 'all',
    defaultValues: stack.attributes
  });

  const { watch: watchStackAttributes } = stackAttributesFormBag
  const stackAttributesValues = watchStackAttributes()

  const shirtAttributesFormBag = useForm<EditableShirtAttributesType>({
    mode: 'all',
    defaultValues: shirt.attributes
  });

  const { watch: watchShirtAttributes, reset: resetShirtAttributes } = shirtAttributesFormBag
  const shirtAttributesValues = watchShirtAttributes()
  /**
   * End form
   */

  const ref = useRef({
    shirt,
    stack,
  })
  ref.current = {
    shirt,
    stack,
  }


  useEffect(() => {
    if (shirt.id !== shirtAId) {
      resetShirtAttributes(ref.current.shirt.attributes)
    }
  }, [shirt.id, resetShirtAttributes])

  const dirtyStack = useMemo(() => {
    return produce(stack, (draft) => {
      Object.assign(draft.attributes, stackAttributesValues)
    })
  }, [stack, stackAttributesValues])

  const dirtyShirt = useMemo(() => {
    return produce(shirt, (draft) => {
      Object.assign(draft.attributes, shirtAttributesValues)
      if (shirtAttributesValues.username.length === 0) {
        draft.attributes.username = '*****'
      }
    })
  }, [shirt, shirtAttributesValues])

  if (!stack || !user) {
    return null
  }

  return (
    <StyledDiv>

      {/* ShirtCloseUp is in the form so we can have latest form values for stack attributes */}
      <div className="d-flex w-100">
        <div className="StackAttributes__shirtCloseUp">
          <ShirtCloseUp
            shirt={dirtyShirt}
            stack={dirtyStack}
            activeInput={activeInput}
            initialFacing={FacingSchema.enum.back}
          />
        </div>


        <Switch>
          <Route
            exact
            render={() => {
              return (
                <FormProvider {...shirtAttributesFormBag}>
                  <ShirtAttributes
                    shirt={shirt}
                    stack={stack}
                    user={user}
                    setActiveInput={setActiveInput}
                  />
                </FormProvider>
              )
            }}
            path={PRIVATE_SHIRT_ATTRIBUTES_PATH}
          />
          <Route
            exact
            render={() => {
              return (
                <FormProvider {...stackAttributesFormBag}>
                  <StackAttributes
                    stack={stack}
                    user={user}
                    setActiveInput={setActiveInput}
                  />
                </FormProvider>
              )
            }}
            path={PRIVATE_STACK_ATTRIBUTES_PATH}
          />
        </Switch>
      </div>
    </StyledDiv>
  );
}


const StackAndShirt: React.FC = () => {
  const { user } = usePrivate()
  const { stackId } = useParams<{ stackId: string }>()
  const match = useRouteMatch<{ shirtId?: string }>(PRIVATE_SHIRT_ATTRIBUTES_PATH)
  const shirtId = match?.params.shirtId

  const shirtSWR = useResource<ShirtType>({
    id: shirtId,
    type: 'shirts',
  })

  const { data: stack } = useResource<StackType>({
    id: stackId,
    type: 'stacks',
  });


  const shirtsSDK = useShirtsSDK()
  const shirtA = useMemo(() => {
    const shirt = shirtsSDK.makeShirt(user, 'stackA')
    shirt.id = shirtAId
    return shirt
  }, [shirtsSDK, user])

  const shirt = shirtSWR.data || shirtA

  if (!stack || !shirt) {
    return null
  }


  return (
    <StackAndShirtAttributes
      user={user}
      stack={stack}
      shirt={shirt || null}
    />
  )
}

export default StackAndShirt
