import { createContext, useContext } from 'react';
import StacksSDK from 'src/sdk/stacks/StacksSDK';

export const StacksSDKContext = createContext<StacksSDK | null>(null)

export const useStacksSDK = () => {
  const stacksSDK = useContext(StacksSDKContext);

  if (!stacksSDK) {
    throw Error('You need to have a StacksSDKContext.Provider')
  }
  return stacksSDK;
}
