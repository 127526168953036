import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';
import { ShirtType } from 'src/sdk/schemaTypes';
import { UserContext } from 'src/pages/AuthListener/User';
import { makePrivateCartPathname, makePrivateStacksPathname } from 'src/sdk/PATHS';
import { useAnalytics } from 'src/sdk/analytics/AnalyticsContext';
import { useParams } from 'react-router';
import { useResources } from 'src/hooks/useResources';
import Alert, { AlertLink, AlertMessage } from 'src/components/Alert/Alert';
import PageView from 'src/components/PageView/PageView';
import React, { useContext } from 'react';
import ShirtCard from 'src/components/ShirtCard/ShirtCard';
import classnames from 'classnames'
import messages from './DashboardShirts.messages';

const DashboardShirts: React.FC = () => {

  const params = useParams();
  const { data: user } = useContext(UserContext);
  const { data: shirts } = useResources<ShirtType>(user?.relationships?.shirts)
  const analytics = useAnalytics();

  if (!user || !shirts) {
    return null;
  }

  const hasShirts = Object.keys(shirts).length
  const hasShirtsInCart = Object.keys(user.relationships.cart || {}).length

  let alert;
  if (!hasShirts) {
    alert = <FormattedMessage {...messages.noShirts} />
  }
  else if (!hasShirtsInCart) {
    alert = <FormattedMessage {...messages.noShirtsInCart} />
  }
  else if (hasShirtsInCart === 1) {
    alert = <FormattedMessage {...messages.hasOneShirtInCart} />
  }
  else {
    alert = <FormattedMessage {...messages.hasSeveralShirtsInCart} />
  }


  return (
    <>
      <Helmet defer={false}>
        <title>Your shirts</title>
        <meta
          name="description"
          content="Your shirts"
        />
      </Helmet>
      <PageView
        onPageView={() => analytics.page({
          name: 'Shirts',
          category: 'Dashboard'
        })}
      />
      <Alert className="fadeInFromRight">
        <AlertMessage>
          {alert}
        </AlertMessage>
        {hasShirts ? (
          <AlertLink
            className={classnames('btn btn-block btn-primary', !hasShirtsInCart && 'disabled')}
            to={makePrivateCartPathname(params)}
          >
            View your cart
          </AlertLink>
        ) : (
          <AlertLink
            className="btn btn-primary btn-block"
            to={makePrivateStacksPathname(params)}
          >
            See your stacks
          </AlertLink>
        )}
      </Alert>
      <ul className="list-group fadeInFromRight second">
        {Object.keys(shirts).map((shirtId) => {
          return (
            <li
              key={shirtId}
              className="list-group-item overflow-hidden"
            >
              <ShirtCard shirtId={shirtId} />
            </li>
          );
        })}
      </ul>
    </>
  );
}

export default DashboardShirts;



