import { defineMessages } from 'react-intl'

export default defineMessages({
  nextButtonLabel: {
    id: 'Intro.nextButtonLabel',
    defaultMessage: 'Next',
  },
  getStarted: {
    id: 'Intro.getStarted',
    defaultMessage: 'Let\'s go!',
    description: 'Done with intro, time to Search for products',
  },
  stackHeading: {
    id: 'Intro.stackHeading',
    defaultMessage: 'What\'s a stack?',
    description: 'Display heading for an introduction to the whole stack',
  },
  stackBody: {
    id: 'Intro.stackBody',
    defaultMessage: `Your "stack" is the collection of tools and services you use
      to do your job, or to get sh*t done.`,
    description: 'About the whole stack',
  },
  frontendHeading: {
    id: 'Intro.frontendHeading',
    defaultMessage: 'The frontend',
    description: 'Display heading for intro to the frontend part of the stack',
  },
  frontendBody: {
    id: 'Intro.frontendBody',
    defaultMessage: `The top of the stack, 
      or "frontend", is made up of the tools you
      use to interact with your customers or audience.
      It might be the UI of your app, or your go to
      web-conferencing solution.`,
    description: 'About the frontend part of the stack',
  },
  backendHeading: {
    id: 'Intro.backendHeading',
    defaultMessage: 'The backend',
    description: 'Display heading for intro to the backend part of the stack',
  },
  backendBody: {
    id: 'Intro.backendBody',
    defaultMessage: `The bottom or “backend” is made up of the tools your
      audience isn’t likely to know you use. It is the infrastructure of your product.
      Examples: a database, an API, or spreadsheet tool.`,
    description: 'About the backend part of the stack',
  },
  minRowsHeading: {
    id: 'Intro.minRowsHeading',
    defaultMessage: '3 row minimum',
  },
  minRowsBody: {
    id: 'Intro.minRowsBody',
    defaultMessage: 'Make sure you fill at least 3 rows! And, fill each row completely.',
  },
  maxRowsHeading: {
    id: 'Intro.maxRowsHeading',
    defaultMessage: '7 row maximum',
  },
  maxRowsBody: {
    id: 'Intro.maxRowsBody',
    defaultMessage: 'You can fill up to 7 rows. But that\'s pretty extreme.',
  },
  onShirtHeading: {
    id: 'Intro.onShirtHeading',
    defaultMessage: 'On a shirt!',
  },
  onShirtBody: {
    id: 'Intro.onShirtBody',
    defaultMessage: `It doesn't look exactly like this, but close. Anyway, the intro is over. 
      You can take it from here!`,
  },
})
