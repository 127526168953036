import classnames from 'classnames'
import React from 'react'
import StackProductSVGTheme from 'src/components/StackProductSVGTheme/StackProductSVGTheme';
import {
  ProductType,
  StackType
} from 'src/sdk/schemaTypes';
import breakpoints from 'src/styles/breakpoints';
import styled from 'styled-components'

const StyledDiv = styled.div`

  button {
    padding: 0;
    margin: 0;
    overflow: hidden;
    position: relative;
    display: inline-block;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
    transition: all 0.3s ease-in-out;

    /* Create the hidden pseudo-element */
    /* include the shadow for the end state */

    &.inactive {

      &::after {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        border-radius: 5px;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
        transition: opacity 0.3s ease-in-out;
      }

      &:hover {
        z-index: 2;
        transform: scale(1.1, 1.1);
      }

      &:hover::after {
        opacity: 1;
      }

    }
  }

  .GridItemSearchResult__media {
    height: 34px;
  }

  @media (min-width: ${breakpoints.sm}px) {
    .GridItemSearchResult__media {
      height: 64px;
      padding: 0;
    }
  }

  .GridItemSearchResult__name {
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
  }

`


export const baseWidth = 120
export const baseHeight = 80
export const margin = 10

type Props = {
  product: ProductType;
  addProductOnStack: (product: ProductType) => void;
  stack: StackType;
}

const GridItemSearchResult: React.FC<Props> = (props) => {

  const {
    product,
    addProductOnStack,
    stack,
  } = props

  const active = Boolean(stack.relationships.products?.[product.id]);
  const logo = product.attributes.logos[0];
  const color = product.attributes.colors[0]

  return (
    <StyledDiv className="position-relative d-inline-block">
      <button
        onClick={() => addProductOnStack(product)}
        disabled={active}
        style={{
          opacity: active ? .5 : 1,
        }}
        className={classnames('shadow-sm bg-white', active ? 'active' : 'inactive')}
      >
        <div className="GridItemSearchResult__media d-inline-block position-relative">
          <StackProductSVGTheme
            theme="searchResult"
            logo={logo}
            color={color}
          />
        </div>
        <div className="GridItemSearchResult__name d-inline-block media-body p-2">
          {product.attributes.name}
        </div>
      </button>
    </StyledDiv>
  )
}

export default GridItemSearchResult;
