import { Index } from 'algoliasearch/lite';
import axios from 'axios';
import { ProductType } from 'src/sdk/schemaTypes';
import { DebugType, } from 'src/sdk/types';
import { JsonApiQueryType } from 'src/types';


interface SearchType {
  term: string;
  perPage: number;
  suggestion: any;
}

interface AlgoliaProduct extends ProductType {
  objectID: string;
}

export default class ProductsSDK {

  debug: DebugType;
  algoliaProducts: Index;

  constructor(debug: DebugType, algoliaProducts: Index) {
    this.algoliaProducts = algoliaProducts;
    this.debug = debug;
  }

  async getProduct(id: string) {
    return axios.get(`https://www.republicofstackshirts.com/api/products/${id}`)
      .then(r => r.data)
  }

  async queryProducts(queryParams: SearchType): Promise<JsonApiQueryType> {
    // this.debug.log('queryProducts for params:', queryParams)
    const fetchResults = async (): Promise<any> => {
      if (!queryParams.term && !queryParams.suggestion) {
        return this.algoliaProducts.getObjects([
          'django',
          'react',
          'javascript',
          'angular',
          'python',
          'stackoverflow',
          'sass',
          'swift',
          'circleci',
          'firebase',
          'redux',
          'socketIo',
          'square',
          'shopify',
          'safari',
          'salesforce',
          'saucelabs',
        ])
          .then((res: any) => res.results)
      }
      else {
        const { perPage = 20, term } = queryParams
        const algoliaOptions: any = {
          query: term,
          hitsPerPage: perPage,
        }
        if (queryParams.suggestion) {
          algoliaOptions.filters = JSON.parse(queryParams.suggestion).filters
        }
        return this.algoliaProducts.search(algoliaOptions)
          .then((res: any) => {
            return res.hits
          })
      }
    }
    const results = await fetchResults()

    return results.map(({ objectID, ...resourceObject }: AlgoliaProduct) => resourceObject)
  }
}
