import React from 'react';
import {
  FacingUnion,
  ProductOnStackType,
  StackType
} from 'src/sdk/schemaTypes';

import SvgGridItem from './SvgGridItem';

interface Props {
  stack: StackType,
  facing: FacingUnion,
}

const GridItems: React.FC<Props> = (props) => {

  const {
    stack,
    facing,
  } = props;

  const productsOnStack = stack.relationships.products || {}
  const productsOnStackArray: ProductOnStackType[] = Object.keys(productsOnStack).map((key: string) => productsOnStack[key]);
  return (
    <>
      {productsOnStackArray.map((productOnStack) => {
        return (
          <SvgGridItem
            key={productOnStack.id}
            stack={stack}
            facing={facing}
            productOnStack={productOnStack}
          />
        );
      })}
    </>
  );
};

export default GridItems;
