import breakpoints from 'src/styles/breakpoints';
import useWindowSize from 'src/hooks/useWindowSize';

type Breakpoint = 'xl' | 'lg' | 'md' | 'sm' | 'xs'

export default function useBreakpoint(): Breakpoint | null {
  const { width } = useWindowSize();

  if (!width) {
    return null;
  }
  if (width >= breakpoints.xl) {
    return 'xl';
  }
  if (width >= breakpoints.lg) {
    return 'lg'
  }
  if (width >= breakpoints.md) {
    return 'md'
  }
  if (width >= breakpoints.sm) {
    return 'sm';
  }
  return 'xs';

}

export function useBreakpointPrefix(breakpoint: Breakpoint): boolean {
  const { width } = useWindowSize();

  return width >= breakpoints[breakpoint];
}
