import { defineMessages } from 'react-intl'

export default defineMessages({
  noShirts: {
    id: 'DashboardShirts.noShirts',
    defaultMessage: 'Not a single shirt? Go pick a stack to put on a new one',
  },
  noShirtsInCart: {
    id: 'DashboardShirts.noShirtsInCart',
    defaultMessage: 'Nice shirts. Put some in your cart to checkout!',
  },
  hasOneShirtInCart: {
    id: 'DashboardShirts.hasOneShirtInCart',
    defaultMessage: 'No one will mess with you in this shirt',
  },
  hasSeveralShirtsInCart: {
    id: 'DashboardShirts.hasSeveralShirtsInCart',
    defaultMessage: 'Nice additions to your wardrobe',
  },
})
