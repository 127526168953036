import React from 'react';
import BackStackSVG from 'src/components/StackSVGs/BackStackSVG';
import FrontStackSVG from 'src/components/StackSVGs/FrontStackSVG';
import { ShirtImagesMapType } from 'src/sdk/types';
import {
  FacingUnion,
  ShirtType,
  StackType
} from 'src/sdk/schemaTypes';
import styled from 'styled-components'

const StyledDiv = styled.div`
  pointer-events: none;
  position: relative;

  .ShirtFront__shirtImg {
    width: 100%;
    height: 100%;
  }

  .ShirtMockup__frontSvg {
    position: absolute;
    right: 32%;
    top: 27%;
    width: 12%;
  }

  .ShirtBack__shirtImg {
    width: 100%;
  }

  .ShirtMockup__backSvg {
    position: absolute;
    left: 29.5%;
    top: 14%;
    width: 40%;
  }

`

type Props = {
  stack: StackType;
  shirt?: ShirtType;
  facing?: FacingUnion;
  shirtImagesMap: ShirtImagesMapType,
  style?: any
}

export const ShirtMockup: React.FC<Props> = (props) => {

  const {
    stack,
    shirt,
    facing,
    shirtImagesMap,
    style = {}
  } = props;

  const color = shirt?.attributes?.color || 'white';

  return (
    <StyledDiv
      style={style}
      className="ShirtMockup ratio-box"
    >
      {facing === 'back' ? (
        <>
          <img
            alt="A mockup of your shirt"
            className="ShirtBack__shirtImg"
            src={shirtImagesMap.back[color]}
          />
          <div className="ShirtMockup__backSvg">
            <BackStackSVG
              shirt={shirt}
              stack={stack}
            />
          </div>
        </>
      ) : (
        <>
          <img
            alt="Shirt mockup"
            className="ShirtFront__shirtImg"
            src={shirtImagesMap.front[color]}
          />
          <div
            id="ShirtMockup__frontSvg"
            className="ShirtMockup__frontSvg"
          >
            <FrontStackSVG
              stack={stack}
            />
          </div>
        </>
      )}
    </StyledDiv>
  );
}

export default ShirtMockup;
