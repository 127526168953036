import produce from 'immer';
import LogRocket from 'logrocket';
import React, {
  createContext,
  useContext,
  useEffect,
  useRef
} from 'react';
import {
  makeResourceSWR,
  useResource
} from 'src/hooks/useResources';
import useAuthAxiosInterceptors from 'src/pages/AuthListener/useAuthAxiosInterceptors';
import { useAnalytics } from 'src/sdk/analytics/AnalyticsContext';
import { AuthContext } from 'src/sdk/auth/AuthProvider';
import { useFirebaseSDK } from 'src/sdk/firebase/FirebaseSDKContext';
import { useMethodsSDK } from 'src/sdk/methods/MethodsSDKProvider';
import {
  CartOrderType,
  UserType
} from 'src/sdk/schemaTypes';
import { useSentry } from 'src/sdk/sentry/SentryContext';
import { RESOURCE_TYPES } from 'src/sdk/types';
import { SWRType } from 'src/types';
import { nprogress } from 'src/utils/nprogress';

export const UserContext = createContext<SWRType<UserType>>(makeResourceSWR<UserType>())

const User: React.FC = (props) => {

  const {
    children,
  } = props;

  const { auth } = useContext(AuthContext);
  const analytics = useAnalytics();
  const sentry = useSentry();
  const methods = useMethodsSDK();
  const firebaseSDK = useFirebaseSDK();

  const userSWR = useResource<UserType>({
    id: auth.uid,
    type: RESOURCE_TYPES.USERS,
  });
  const {
    data: user,
  } = userSWR;

  const userIdRef = useRef<string>()


  useEffect(() => {
    if (user && user.id !== userIdRef.current) {
      userIdRef.current = user.id;
      analytics.identify({
        user,
      })

      LogRocket.identify(user.id, {
        email: user.attributes.authUser.email as string,
      });

      sentry?.configureScope((scope: any) => {
        scope.setUser({
          id: user.id,
          email: user.attributes.authUser.email,
        });
      });
    }
  }, [user, analytics, sentry])

  useEffect(() => {
    if (user && !user.relationships.cart) {
      const id = firebaseSDK.makeResourceId('orders')
      const now = firebaseSDK.makeTimestamp()
      const updatedUser = produce<UserType>(user, (draft) => {
        draft.relationships.cart = {
          id,
          type: 'orders',
          addedOn: now
        }
      })
      const cartOrder: CartOrderType = {
        id,
        type: 'orders',
        attributes: {
          updatedOn: now,
          createdOn: now,
          shippingAddress: {},
        },
        relationships: {
          shirts: {},
          user: {
            id: user.id,
            type: RESOURCE_TYPES.USERS,
            addedOn: now
          }
        },
        meta: {}
      }
      void methods.upsertResources([
        updatedUser,
        cartOrder
      ])
    }
  }, [user, firebaseSDK, methods])

  const everythingLoaded = userSWR.meta.loaded

  React.useEffect(() => {
    if (!everythingLoaded) {
      nprogress.start()
    }
    else {
      nprogress.done();
    }
  }, [everythingLoaded])

  useAuthAxiosInterceptors()

  return (
    <UserContext.Provider value={userSWR}>
      {children}
    </UserContext.Provider>
  );
}

export default User
