import React from 'react';
import {
  FacingUnion,
  StackType
} from 'src/sdk/schemaTypes';
import makeBlanks from 'src/sdk/stacks/makeBlanks';
import stackDimensions from 'src/stackDimensions';

const { getRadius, getStroke, gutter, stackItemHeight, stackItemWidth } = stackDimensions;

interface Props {
  stack: StackType,
  facing: FacingUnion;
}

const SvgBlanks: React.FC<Props> = (props) => {

  const {
    stack,
    facing,
  } = props;

  const gridItems = makeBlanks(stack);

  return (
    <>
      {gridItems.map((gridItem) => {

        const x = gridItem.x * (stackItemWidth + gutter);
        const y = gridItem.y * (stackItemHeight + gutter);
        const width = gridItem.w * stackItemWidth + (gridItem.w - 1) * gutter;

        const stroke = getStroke(facing);
        const radius = getRadius(facing);

        const patternId = `pattern-${gridItem.i}`;

        return (
          <g key={gridItem.i}>
            <defs>
              <pattern
                id={patternId}
                width="8"
                height="10"
                patternUnits="userSpaceOnUse"
                patternTransform="rotate(45 50 50)"
              >
                <line
                  stroke="#ffa8a8"
                  strokeWidth="3px"
                  y2="10"
                />
              </pattern>

            </defs>

            <rect
              x={x}
              y={y}
              rx={radius}
              fill="white"
              width={width}
              height={stackItemHeight}
            />
            <rect
              x={x + stroke}
              y={y + stroke}
              rx={radius}
              fill={`url(#${patternId})`}
              width={width - stroke * 2}
              height={stackItemHeight - stroke * 2}
            />
          </g>
        );
      })}
    </>
  );
}

SvgBlanks.defaultProps = {};

export default SvgBlanks;
