import produce from 'immer';
import React, {
  useCallback,
  useContext
} from 'react';
import { Helmet } from 'react-helmet';

import { FormattedMessage } from 'react-intl';
import {
  Redirect,
  useHistory,
  useParams
} from 'react-router';
import { Link } from 'react-router-dom';
import { navHeight } from 'src/components/Navbar/Navbar';
import PageView from 'src/components/PageView/PageView';
import { useResource } from 'src/hooks/useResources';
import { UserContext } from 'src/pages/AuthListener/User';
import messages from 'src/pages/Private/Stack/Stack.messages';
import StackGrid from 'src/pages/Private/Stack/StackGrid/StackGrid';
import { useAnalytics } from 'src/sdk/analytics/AnalyticsContext';
import { useMethodsSDK } from 'src/sdk/methods/MethodsSDKProvider';
import {
  makePrivateDashboardPathname,
  makePrivateStackAttributesPathname,
  makePrivateStackIntroPathname,
} from 'src/sdk/PATHS';
import { ReachedIntroStepSchema } from 'src/sdk/schemas';
import {
  ProductsOnStackType,
  StackType
} from 'src/sdk/schemaTypes';

import {
  getProductsOnStackStatus,
  STACK_CODES
} from 'src/sdk/stacks/validation';
import { nprogressTry } from 'src/utils/nprogress';
import styled from 'styled-components'

const StyledDiv = styled.div``

const Stack: React.FC = (props) => {

  const history = useHistory();
  const params = useParams<{ stackId: string, userId: string }>()
  const { data: user } = useContext(UserContext);
  const methodsSDK = useMethodsSDK();
  const { data: stack, meta } = useResource<StackType>({
    id: params.stackId,
    type: 'stacks',
  })

  const analytics = useAnalytics();


  // const windowSize = useWindow();
  // const matchesLandscape = useMemo(() => windowSize.width >= 568, [windowSize]) // Width on iPhone SE
  // if (matchesLandscape) {
  //
  // }
  // if (!matchesLandscape) {
  //   return (
  //     <Page404 statusCode="" reason="Sorry, but there's just not enough room on the screen to build a stack.">
  //       Try <strong>landscape mode</strong> - turning your phone sideways. Or, find a larger screen somewhere!
  //     </Page404>
  //   )
  // }

  const handleChange = useCallback((productsOnStack: ProductsOnStackType) => {
    return nprogressTry(async () => {
      const updatedStack = produce(stack as StackType, (draft) => {
        draft.relationships.products = productsOnStack;
      })
      return methodsSDK.upsertResource(updatedStack)
    });
  }, [methodsSDK, stack])

  if (!stack) {
    return null;
  }
  if (!user) {
    return null;
  }

  const statusCode = getProductsOnStackStatus(stack)
  const next = () => {
    history.push({
      pathname: makePrivateStackAttributesPathname({
        ...params,
        stackId: params.stackId,
      }),
    });
  };

  const renderBottomBarCTA = () => {
    if (!stack.attributes.name) {
      return (
        <button
          className="btn btn-lg btn-primary mr-3"
          type="submit"
          onClick={next}
          disabled={statusCode !== STACK_CODES.VALID}
        >
          Next
        </button>
      )
    }
    return (
      <Link
        className="btn btn-lg btn-primary mr-3 flex-shrink-0"
        to={makePrivateDashboardPathname(params)}
      >
        <span className="d-none d-md-block">
          Back to dashboard
        </span>
        <span className="d-block d-md-none">
          Dashboard
        </span>
      </Link>
    )
  }

  const reachedIntroStepId = user.attributes?.reachedIntroStepId;
  if (reachedIntroStepId !== ReachedIntroStepSchema.enum.finished) {
    return (
      <Redirect
        to={makePrivateStackIntroPathname({
          ...params,
          stepId: reachedIntroStepId || ReachedIntroStepSchema.enum.stack
        })}
      />
    )
  }

  return (
    <>
      <div style={{ height: navHeight.md }} />
      <Helmet defer={false}>
        <title>Your stack</title>
        <meta
          name="description"
          content="Layout your stack"
        />
      </Helmet>

      <PageView
        pageKey={stack.id}
        onPageView={() => {
          analytics.page({
            name: 'Stack - Yours',
            properties: {
              stack,
            }
          })
        }}
      />
      <StyledDiv className="Stack">
        <div className="container ct">
          <div className="pb-2rem">
            <h1 className="display-2 fadeInFromRight">
              {'What\'s in your stack?'}
            </h1>
          </div>
        </div>

        <StackGrid
          stack={stack}
          bottomBarCTA={(
            <>
              {renderBottomBarCTA()}
              <span>
                <FormattedMessage
                  {...messages[statusCode]}
                />
              </span>
            </>
          )}
          onChange={handleChange}
        />

      </StyledDiv>
    </>
  );
}

export default Stack;
