const productSettings = {
  shirt: {

    price: 25,
    unitAmount: 2500, // for Stripe

    defaultColor: 'white',
    defaultSize: 'med',

    colors: {
      black: {
        hex: '000000',
        label: 'black',
        id: 'black',
      },
      asphalt: {
        hex: '545454',
        label: 'asphalt',
        id: 'asphalt',
      },
      white: {
        hex: 'FFFFFF',
        label: 'white',
        id: 'white',
      },
    },
    sizes: {
      xs: {
        id: 'xs',
        abbr: 'xs',
        label: 'xs'
      },
      s: {
        id: 's',
        abbr: 's',
        label: 'small'
      },
      m: {
        id: 'm',
        abbr: 'm',
        label: 'medium'
      },
      l: {
        id: 'l',
        abbr: 'l',
        label: 'large'
      },
      xl: {
        id: 'xl',
        abbr: 'xl',
        label: 'xl'
      },
      '2xl': {
        id: '2xl',
        abbr: '2xl',
        label: 'xxl'
      },
      '3xl': {
        id: '3xl',
        abbr: '3xl',
        label: 'xxxl'
      },
    }
  }
}
export default productSettings;
