import { defineMessages } from 'react-intl'
import {
  AUTH_CODES,
  FIREBASE_AUTH_ERROR_CODES
} from 'src/pages/SignIn/CODES';

export default defineMessages({
  [FIREBASE_AUTH_ERROR_CODES.NETWORK_REQUEST_FAILED]: {
    id: FIREBASE_AUTH_ERROR_CODES.NETWORK_REQUEST_FAILED,
    defaultMessage: 'Some how, we just lost internet access while trying to sign you in. Try again?',
    description: 'An auth error - lost network access',
  },
  [FIREBASE_AUTH_ERROR_CODES.USER_NOT_FOUND]: {
    id: FIREBASE_AUTH_ERROR_CODES.USER_NOT_FOUND,
    defaultMessage: 'Hmm, we could not find that user. Do you want to sign up with that email?',
    description: 'An auth error - user not found',
  },
  [FIREBASE_AUTH_ERROR_CODES.EMAIL_ALREADY_IN_USE]: {
    id: FIREBASE_AUTH_ERROR_CODES.EMAIL_ALREADY_IN_USE,
    defaultMessage: 'You may have used a different service to login last time?',
    description: 'An auth error - email used with a different provider',
  },
  [AUTH_CODES.UNKNOWN_ERROR]: {
    id: AUTH_CODES.UNKNOWN_ERROR,
    defaultMessage: 'Issue signing in. Please try again later? Or let us know!',
  },
})
