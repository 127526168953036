// noinspection JSPrimitiveTypeWrapperUsage

/**
 *
 * @param env
 * @param serviceAccountPrivateKey
 * @returns {{firebaseClientConfig: any, serviceAccount: any}}
 */
function makeConfigs(env, serviceAccountPrivateKey) {
  switch (env) {
    case 'production': {
      return {
        firebaseClientConfig: require('./firebase-client-config.production.json'),
        serviceAccount: {
          'type': 'service_account',
          'project_id': 'stackshirts',
          'private_key_id': 'e934505317f9c3e996141f6803cf04ffe8708269',
          'private_key': serviceAccountPrivateKey,
          'client_email': 'stackshirts@stackshirts.iam.gserviceaccount.com',
          'client_id': '111524534446681713127',
          'auth_uri': 'https://accounts.google.com/o/oauth2/auth',
          'token_uri': 'https://accounts.google.com/o/oauth2/token',
          'auth_provider_x509_cert_url': 'https://www.googleapis.com/oauth2/v1/certs',
          'client_x509_cert_url': 'https://www.googleapis.com/robot/v1/metadata/x509/stackshirts%40stackshirts.iam.gserviceaccount.com'
        }
      }
    }
    case 'development':
    default: {
      return {
        firebaseClientConfig: require('./firebase-client-config.development.json'),
        serviceAccount: require('./service-account.development.json')
      }
    }
  }
}

module.exports = makeConfigs
