import classnames from 'classnames'
import React, { useContext } from 'react';
import {
  Link,
  useParams
} from 'react-router-dom';
import { ACTIVE_INPUTS } from 'src/components/ShirtCloseUp/helpers';
import ShirtMockup from 'src/components/ShirtMockup/ShirtMockup';
import {
  Table,
  Tr
} from 'src/components/Table/Table';
import useAddOrRemoveShirtOnCart from 'src/hooks/useAddOrRemoveShirtOnCart';
import { useResource } from 'src/hooks/useResources';
import { CartIcon } from 'src/icons';
import { UserContext } from 'src/pages/AuthListener/User';
import {
  makePrivateShirtAttributesPath,
  makePrivateStackAttributesPathname,
  makePrivateStackPathname
} from 'src/sdk/PATHS';
import { FacingSchema } from 'src/sdk/schemas';
import {
  CartOrderType,
  ShirtType,
  StackType
} from 'src/sdk/schemaTypes';
import {
  getProductsOnStackStatus,
  STACK_CODES
} from 'src/sdk/stacks/validation';
import { appShirtImagesMap } from 'src/shirtImages/shirtImages';
import breakpoints from 'src/styles/breakpoints';
import styled from 'styled-components'

const StyledDiv = styled.div`
  .ShirtCard__mockup {
    margin-bottom: -100px;
    margin-top: 1rem;

    @media (min-width: ${breakpoints.sm}px) {
      margin-bottom: -200px;
      margin-top: 0;
      left: -4%;
    }
    @media (min-width: ${breakpoints.md}px) {
      margin-bottom: 0;
      position: absolute;
    }
    @media (min-width: ${breakpoints.lg}px) {
      top: -4%;
    }
  }

  .ShirtCard__reverse {
    z-index: 1;

    &:before {
      content: '';
      z-index: -1;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: gray;
      opacity: .5;
    }
  }
`

type Props = {
  shirtId: string;
  onCartPage?: boolean;
}

const ShirtCard: React.FC<Props> = (props) => {

  const {
    shirtId,
    onCartPage,
  } = props;

  const { data: shirt } = useResource<ShirtType>({
    id: shirtId,
    type: 'shirts'
  })
  const { data: user } = useContext(UserContext);
  const { data: stack } = useResource<StackType>(shirt?.relationships?.stack);
  const { data: cart } = useResource<CartOrderType>(user?.relationships?.cart);
  const params = useParams<{ userId: string }>();
  const [isAddingOrRemoving, addOrRemoveShirtOnCart] = useAddOrRemoveShirtOnCart(shirtId)


  // const locked = !!shirt.relationships.orders;
  // const propertyOfBlank = !shirt.attributes.providerId || !shirt.attributes.username;

  if (!stack || !shirt || !user) {
    return null;
  }

  const stackStatus = getProductsOnStackStatus(stack);
  const shirtIsInCart = !!cart?.relationships.shirts?.[shirt.id]

  const renderCardAction = () => {
    if (shirtIsInCart) {
      return (
        <button
          disabled={isAddingOrRemoving}
          onClick={addOrRemoveShirtOnCart}
          className="btn btn-default btn-block"
        >
          Remove from cart
        </button>
      )
    }
    if (!onCartPage) {
      return (
        <button
          disabled={isAddingOrRemoving}
          onClick={addOrRemoveShirtOnCart}
          className="btn btn-outline btn-block"
        >
          <CartIcon
            height="18px"
            className="pr-2"
          />
          Add to cart
        </button>
      )
    }
  }

  return (
    <StyledDiv className="ShirtCard">
      {onCartPage && !shirtIsInCart && (
        <div className="ShirtCard__reverse fill d-flex flex-column justify-content-center text-center">
          <div>
            <button
              onClick={addOrRemoveShirtOnCart}
              className="btn btn-default"
            >
              Put back in cart
            </button>
          </div>
        </div>
      )}
      <div
        style={{
          minHeight: 220,
        }}
        className="row flex-wrap"
      >
        <div className="d-flex flex-column justify-content-between col-md-6">
          <Table
            className="mb-1rem"
            breakpoint="md"
          >
            <Tr>
              <>
                Property of:
              </>
              <Link
                to={{
                  pathname: makePrivateShirtAttributesPath({
                    ...params,
                    stackId: shirt.relationships.stack.id,
                    shirtId: shirt.id
                  }),
                  state: { editing: ACTIVE_INPUTS.USERNAME }
                }}
                className={classnames(!shirt.attributes.username && 'text-red font-italic')}
              >
                {shirt.attributes.username || 'Unclaimed'}
              </Link>
            </Tr>
            <Tr>
              <>
                Size:
              </>
              <Link
                to={{
                  pathname: makePrivateShirtAttributesPath({
                    ...params,
                    stackId: shirt.relationships.stack.id,
                    shirtId: shirt.id
                  }),
                  state: { editing: ACTIVE_INPUTS.SHIRT_SIZE }
                }}
                className={classnames(!shirt.attributes.size && 'text-red font-italic', 'text-uppercase')}
              >
                {shirt.attributes.size || 'No size'}
              </Link>
            </Tr>
            <Tr>
              <>
                Color:
              </>
              <Link
                to={{
                  pathname: makePrivateShirtAttributesPath({
                    ...params,
                    stackId: shirt.relationships.stack.id,
                    shirtId: shirt.id
                  }),
                  state: { editing: ACTIVE_INPUTS.SHIRT_COLOR }
                }}
                className={classnames(!shirt.attributes.color && 'text-red font-italic', 'text-capitalize')}
              >
                {shirt.attributes.color || 'Colorless'}
              </Link>
            </Tr>
            <Tr>
              <>
                Stack:
              </>
              <>
                <Link
                  to={{
                    pathname: makePrivateStackAttributesPathname({
                      ...params,
                      stackId: stack.id
                    }),
                    state: { editing: ACTIVE_INPUTS.NAME }
                  }}
                  className={classnames(!stack.attributes.name && 'text-red font-italic')}
                >
                  <span>
                    {stack.attributes.name || 'Unnamed'}
                  </span>
                </Link>
                {stackStatus !== STACK_CODES.VALID && (
                  <>
                    {' '}
                    <Link
                      to={makePrivateStackPathname({
                        ...params,
                        stackId: stack.id
                      })}
                      className="text-red font-italic"
                    >
                      (Broken)
                    </Link>
                  </>
                )}
              </>
            </Tr>
            <Tr>
              <>
                Version:
              </>
              <Link
                to={{
                  pathname: makePrivateStackAttributesPathname({
                    ...params,
                    stackId: stack.id
                  }),
                  state: { editing: ACTIVE_INPUTS.NAME }
                }}
                className={classnames(!shirt.attributes.color && 'text-red font-italic')}
              >
                {stack.attributes.version || 'None'}
              </Link>
            </Tr>
            {/*<FormResult>*/}
            {/*  <>Status:</><>Not complete</>*/}
            {/*</FormResult>*/}
          </Table>

          <div className="w-100 w-md-auto">
            {renderCardAction()}
          </div>

        </div>


        <div className="col-md-6">
          <div className="w-100 ShirtCard__mockup">
            <ShirtMockup
              stack={stack}
              shirt={shirt}
              facing={FacingSchema.enum.back}
              shirtImagesMap={appShirtImagesMap}
            />
          </div>
        </div>
      </div>
    </StyledDiv>

  );
}

export default ShirtCard;
