import produce from 'immer';
import React, {
  useContext,
  useEffect,
  useMemo
} from 'react';
import { useParams } from 'react-router-dom';
import Flipper from 'src/components/Flip/Flipper';
import { navHeight } from 'src/components/Navbar/Navbar';
import useWindow from 'src/hooks/useWindow';
import { UserContext } from 'src/pages/AuthListener/User';
import IntroMediaPerspective from 'src/pages/Private/Intro/IntroMedia/IntroMediaPerspective';
import IntroStacks from 'src/pages/Private/Intro/IntroMedia/IntroStacks';
import IntroRoutes from 'src/pages/Private/Intro/IntroRoutes';
import { useMethodsSDK } from 'src/sdk/methods/MethodsSDKProvider';
import { ReachedIntroStepSchema } from 'src/sdk/schemas';
import {
  ReachedIntroStepUnion,
  UserType
} from 'src/sdk/schemaTypes';
import { shirtImages } from 'src/shirtImages/shirtImages';
import breakpoints from 'src/styles/breakpoints';
import { nprogressTry } from 'src/utils/nprogress';
import Styled from 'styled-components';

const StyledDiv = Styled.div`

  height: calc(100vh - ${navHeight.xs}px);
  overflow: hidden;
  
  .Intro__shirtBackground {
    margin-top: -34%;
    left: -54%;
    right: -54%;
    opacity: 0;
    transition: opacity .4s;
  }
  .Intro__shirtImg {
    width: 100%;
  }
  .uppercase-label {
    display: none;
  }
  @media (min-width: ${breakpoints.sm}px) {
    .uppercase-label {
      display: block;
    }
  }
  @media (min-width: ${breakpoints.md}px) {
    height: calc(100vh - ${navHeight.md}px);
    overflow: visible;
  }
`

export const INTRO_STEPS_IN_ORDER = [...ReachedIntroStepSchema.options, undefined]

export const FLIPPABLE_STEP_IDS = [
  'maxRows',
  'minRows',
  'shirtBack'
];

const Intro: React.FC = (props) => {

  const userSWR = useContext(UserContext);
  const { stepId } = useParams<{ stepId: ReachedIntroStepUnion }>()
  const methodsSDK = useMethodsSDK();
  const windowSize = useWindow()
  const matchesSm = useMemo(() => windowSize.width >= breakpoints.sm, [windowSize])

  useEffect(() => {
    if (userSWR.data) {
      const currentStepId = userSWR.data.attributes?.reachedIntroStepId;
      const existingStepIndex = INTRO_STEPS_IN_ORDER.indexOf(currentStepId);
      const newStepIndex = INTRO_STEPS_IN_ORDER.indexOf(stepId);
      if (newStepIndex > existingStepIndex) {
        const updatedUser = produce<UserType>(userSWR.data, (draft) => {
          draft.attributes.reachedIntroStepId = stepId;
        })
        return void nprogressTry(() => methodsSDK.upsertResource(updatedUser))
      }
    }
  }, [userSWR.data, stepId, methodsSDK])

  useEffect(() => {
    new Image().src = shirtImages.whiteBack1 // cache the shirt image early
  }, [])

  const flipKey = useMemo(() => {
    if (FLIPPABLE_STEP_IDS.includes(stepId)) {
      return stepId;
    }
    return null;
  }, [stepId])
  
  return (
    <StyledDiv className="container d-flex flex-column justify-content-center">
      <Flipper className="h-100 d-flex flex-row align-items-center" flipKey={flipKey}>
        <div className="row">
          {!matchesSm && (
            <div className="col-12 d-block d-sm-none mb-2rem position-relative">
              <div
                style={{
                  maxWidth: 294
                }}
                className="w-75 m-auto"
              >
                <div
                  style={{
                    opacity: stepId === ReachedIntroStepSchema.enum.shirtBack ? 1 : 0,
                  }}
                  className="fill Intro__shirtBackground"
                >
                  <img
                    alt="The back of a shirt with an example stack on it"
                    className="Intro__shirtImg"
                    src={shirtImages.whiteBack1}
                  />
                </div>
                <IntroStacks stepId={stepId} />
              </div>
            </div>
          )}

          <div
            className="col-sm-6 center"
            style={{
              zIndex: 2,
            }}
          >
            <div
              className="text-center text-sm-left"
            >
              <IntroRoutes />
            </div>
          </div>

          {matchesSm && (
            <div
              className="d-none d-sm-block col-6"
              style={{
                zIndex: 1,
              }}
            >
              <IntroMediaPerspective
                stepId={stepId}
              />
            </div>
          )}
        </div>
      </Flipper>
    </StyledDiv>
  );
}

export default Intro;
