export const LIGHT = 'LIGHT'
export const DARK = 'DARK'

interface RGB { r: number, g: number, b: number }

export function makeRGB(hexColor: string): RGB | null {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexColor);
  if (!result) {
    return null
  }
  return {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  };
}

export function getContrastLightOrDark(hexColor: string): string | null {
  const rgb = makeRGB(hexColor)
  if (!rgb) {
    return LIGHT
  }
  const {
    r,
    g,
    b,
  } = rgb
  const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000
  return (yiq >= 128) ? DARK : LIGHT
}
