import { STACK_CODES } from 'src/sdk/stacks/validation';
import { defineMessages } from 'react-intl'

export default defineMessages({
  stackLocked: {
    id: 'Stack.stackLocked',
    defaultMessage: 'This stack is locked since it\'s already on a shirt.',
    description: 'Stack locked because it\'s been ordered',
  },
  [STACK_CODES.NO_LOGOS]: {
    id: STACK_CODES.NO_LOGOS,
    defaultMessage: 'Select some tools, services, or products for your stack.',
    description: 'User does not have any products in his or her stack',
  },
  [STACK_CODES.NOT_ENOUGH_LOGOS]: {
    id: STACK_CODES.NOT_ENOUGH_LOGOS,
    defaultMessage: `You need at least 3 products in your stack.`,
    description: 'User has removed too many products from his or her stack',
  },
  [STACK_CODES.NOT_ENOUGH_ROWS]: {
    id: STACK_CODES.NOT_ENOUGH_ROWS,
    defaultMessage: 'You need to have at least 3 rows in your stack',
    description: 'Validation of the stack grid',
  },
  [STACK_CODES.TOO_MANY_ROWS]: {
    id: STACK_CODES.TOO_MANY_ROWS,
    defaultMessage: 'Please limit your stack to 7 rows!',
    description: 'Validation of the stack grid',
  },
  [STACK_CODES.ROWS_NOT_FULL]: {
    id: STACK_CODES.ROWS_NOT_FULL,
    defaultMessage: 'Each row needs to span the full width of your stack',
    description: 'Validation of the stack grid',
  },
  [STACK_CODES.VALID]: {
    id: STACK_CODES.VALID,
    defaultMessage: 'Looks good!',
    description: 'Feedback message when user has a valid stack',
  },
})
