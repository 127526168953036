import React from 'react';
import {
  FormattedMessage,
  MessageDescriptor
} from 'react-intl';
import { DebugContext } from 'src/sdk/debug/DebugContext';

type Props = {
  message: MessageDescriptor
}

export default class Message extends React.Component<Props> {

  static propTypes = {};

  static defaultProps = {};

  static contextType = DebugContext

  state = {
    error: null,
  };

  componentDidCatch(error: Error) {

    const debug = this.context
    debug.error(error)

    this.setState({
      error
    })
  }

  render() {

    const {
      message,
    } = this.props

    const {
      error
    } = this.state

    const debug = this.context

    if (!message) {
      debug.error(new Error('No message provided to Message'))
      return null
    }

    if (error) {
      return null
    }

    return (
      <FormattedMessage {...message} />
    );
  }
}
