import { AnalyticsType } from 'src/sdk/analytics/types';
import { createContext, useContext } from 'react';

export const AnalyticsContext = createContext<AnalyticsType | null>(null)

export const useAnalytics = () => {
  const analytics = useContext(AnalyticsContext);

  if (!analytics) {
    throw Error('You need to have a AnalyticsContext.Provider')
  }
  return analytics;
}
