import { animated, useSpring } from '@react-spring/web';
import React, {
  useEffect,
  useState
} from 'react';
import ReactDOM from 'react-dom';
import {
  CSSTransition,
  SwitchTransition
} from 'react-transition-group';
import { useDrag } from 'react-use-gesture';
import GuideSvg from 'src/components/ShirtCloseUp/GuideSvg';
import {
  ACTIVE_INPUTS,
  ActiveInputType,
  getActiveInputFacing,
  ScaleType
} from 'src/components/ShirtCloseUp/helpers';
import ZoomButtons from 'src/components/ShirtCloseUp/ZoomButtons';
import ShirtMockup from 'src/components/ShirtMockup/ShirtMockup';
import { backStackSVGId } from 'src/components/StackSVGs/BackStackSVG';
import { frontStackSVGId } from 'src/components/StackSVGs/FrontStackSVG';
import { STACK_SVG_IDS } from 'src/components/StackSVGs/helpers';
import useBreakpoint, { useBreakpointPrefix } from 'src/hooks/useBreakpoint';
import useUserAgent from 'src/hooks/useUserAgent';
import { bottomBarId } from 'src/pages/Private/StackAndShirtAttributes/ShirtAttributes/ShirtMobileDrawer';
import { FacingSchema } from 'src/sdk/schemas';
import {
  FacingUnion,
  ShirtType,
  StackType
} from 'src/sdk/schemaTypes';
import { appShirtImagesMap } from 'src/shirtImages/shirtImages';
import breakpoints from 'src/styles/breakpoints';
import Styled from 'styled-components';
import usePreventGesture from '../../hooks/usePreventGesture';


const StyledDiv = Styled.div`
  touch-action: none;
  
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  
  z-index: 0;
  cursor: grab;
  overflow: hidden;

  background: #fff;  /* fallback for old browsers */

  @media (min-width:${breakpoints.md}px) {
    overflow: hidden;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    border-left: 1px solid #ccc;
  }

  .ShirtCloseUp__fadeIn-enter {
    opacity: 0;
  }
  .ShirtCloseUp__fadeIn-enter-active {
    opacity: 1;
    transition: opacity 200ms;
  }
  .ShirtCloseUp__fadeIn-exit {
    opacity: 1;
  }
  .ShirtCloseUp__fadeIn-exit-active {
    opacity: 0;
    transition: opacity 200ms;
  }
  .ShirtCloseUp__zoomable {
    transform-origin: center;
    user-select: none;
  }
  .ShirtCloseUp__draggable {
    height: 100%;
    width: 100%;
  }
  .ShirtCloseUp__zoomButtonsMd {
    position: absolute;
    right: 0;
    top: 0;
  } 
  @media (min-width: ${breakpoints.md}px) {
    .ShirtCloseUp__zoomButtonsMd {
      top: auto;
      bottom: 0;
    }
  }
  
  svg {
    overflow: visible;
  }

`;

const StyledZoomButtonsInBottomBar = Styled.div`
  position: absolute;
  bottom: calc(100% + 20px);
  right: 20px;
`

type Props = {
  stack: StackType;
  shirt: ShirtType;
  initialFacing?: FacingUnion; // This COULD be passed down... or not? Use for initial state. And watch for changes.
  activeInput?: ActiveInputType;
}

interface SpringType {
  x: number;
  y: number;
  width: number;
  height: number;
  opacity: number;
}

const ShirtCloseUp: React.FC<Props> = (props) => {

  const {
    shirt,
    stack,
    initialFacing,
    activeInput,
  } = props;

  const [scale, setScale] = useState<ScaleType>(2 as ScaleType)
  const [facing, setFacing] = useState<FacingUnion>(initialFacing || getActiveInputFacing(activeInput) || FacingSchema.enum.back);
  const { isMobileDevice } = useUserAgent();
  const isMedium = useBreakpointPrefix('md')

  useEffect(() => {
    const newFacing = getActiveInputFacing(activeInput)
    if (newFacing && newFacing !== facing) {
      setFacing(newFacing);
    }
  }, [activeInput, facing])

  usePreventGesture();

  const [dragAndZoomSpring, setDragAndZoomSpring] = useSpring(() => ({
    x: 0,
    y: 0,
    scale,
    transformOrigin: '50% 50%',
    config: {
      mass: 1,
      tension: 360,
      friction: 35
    }
  }))

  useEffect(() => {
    return void setDragAndZoomSpring({
      scale,
    })
  }, [scale, setDragAndZoomSpring])

  const breakpoint = useBreakpoint();

  useEffect(() => {
    switch (breakpoint) {
      case 'xl': {
        const scale: ScaleType = 1.5
        setScale(scale)
        return void setDragAndZoomSpring({
          scale,
          transformOrigin: '50% 50%',
          y: window.innerWidth / window.innerHeight < 1.4 ? 0 : 300,
          x: 0,
        })
      }
      case 'lg': {
        const scale: ScaleType = 1.5
        setScale(scale)
        return void setDragAndZoomSpring({
          scale,
          transformOrigin: '50% 50%',
          y: 0,
          x: 0,
        })
      }
      case 'md': {
        const scale: ScaleType = 1.5
        setScale(scale)
        return void setDragAndZoomSpring({
          scale,
          transformOrigin: '50% 50%',
          y: 0,
          x: 0,
        })
      }
      case 'sm':
      case 'xs': {
        const scale: ScaleType = 1.5
        setScale(scale)
        return void setDragAndZoomSpring({
          scale,
          transformOrigin: '50% 0%',
          y: 100,
          x: 0,
        })
      }
    }
  }, [setDragAndZoomSpring, breakpoint])

  useEffect(() => {
    if (isMobileDevice) {
      switch (activeInput) {
        case ACTIVE_INPUTS.NAME: {
          const scale: ScaleType = 2.5
          setScale(scale)
          return void setDragAndZoomSpring({
            scale,
            x: 100,
            y: 100,
          })
        }
        case ACTIVE_INPUTS.VERSION: {
          const scale: ScaleType = 2.5
          setScale(scale)
          return void setDragAndZoomSpring({
            scale,
            x: -100,
            y: 100,
          })
        }
        case ACTIVE_INPUTS.USERNAME: {
          const scale: ScaleType = 2.5
          setScale(scale)
          return void setDragAndZoomSpring({
            scale,
            x: -100,
            y: -100,
          })
        }
      }
    }
    else if (isMedium) {
      switch (activeInput) {
        case ACTIVE_INPUTS.NAME: {
          const scale: ScaleType = 2.5
          setScale(scale)
          return void setDragAndZoomSpring({
            scale,
            x: 100,
            y: 300,
          })
        }
        case ACTIVE_INPUTS.VERSION: {
          const scale: ScaleType = 2.5
          setScale(scale)
          return void setDragAndZoomSpring({
            scale,
            x: -100,
            y: 300,
          })
        }
        case ACTIVE_INPUTS.USERNAME: {
          const scale: ScaleType = 2.5
          setScale(scale)
          return void setDragAndZoomSpring({
            scale,
            x: -100,
            y: 400,
          })
        }
      }
    }
    else {
      switch (activeInput) {
        case ACTIVE_INPUTS.NAME: {
          const scale: ScaleType = 2.5
          setScale(scale)
          return void setDragAndZoomSpring({
            scale,
            x: 100,
            y: 0,
          })
        }
        case ACTIVE_INPUTS.VERSION: {
          const scale: ScaleType = 2.5
          setScale(scale)
          return void setDragAndZoomSpring({
            scale,
            x: -100,
            y: 0,
          })
        }
        case ACTIVE_INPUTS.USERNAME: {
          const scale: ScaleType = 2.5
          setScale(scale)
          return void setDragAndZoomSpring({
            scale,
            x: -100,
            y: -100,
          })
        }
      }
    }


  }, [activeInput, isMedium, isMobileDevice, setDragAndZoomSpring])

  // noinspection JSUnusedGlobalSymbols
  const bind = useDrag((state) => {
    const {
      movement: [x, y]
    } = state;
    return setDragAndZoomSpring({
      x,
      y
    })
  }, {
    initial: () => [dragAndZoomSpring.x.get(), dragAndZoomSpring.y.get()]
  })


  const [overlaySpring, setOverlaySpring] = useSpring<SpringType>(() => ({
    x: 100,
    y: 100,
    width: 40,
    height: 10,
    opacity: 0,
    config: {
      mass: 1,
      tension: 360,
      friction: 30
    },
  }));


  // Update the blue overlay
  useEffect(() => {
    let id;
    switch (activeInput) {
      case ACTIVE_INPUTS.NAME:
        id = STACK_SVG_IDS.STACK_NAME;
        break;
      case ACTIVE_INPUTS.VERSION:
        id = STACK_SVG_IDS.STACK_VERSION;
        break;
      case ACTIVE_INPUTS.USERNAME:
        id = STACK_SVG_IDS.SHIRT_USERNAME;
        break;
      default:
        break;
    }
    if (!id) {
      return void setOverlaySpring({
        opacity: 0,
      });
    }

    const el = document.getElementById(id) as any as SVGGraphicsElement;
    if (!el) {
      return void setOverlaySpring({
        opacity: 0,
      });
    }

    const dimensions = el.getBBox();
    const width = Math.max(dimensions.width + 24, 0);
    const height = dimensions.height + 22;
    const x = dimensions.x - (width - dimensions.width) / 2;
    const y = dimensions.y - (height - dimensions.height) / 2;
    const padding = 0;

    return void setOverlaySpring({
      x: x - padding,
      y: y - padding,
      width: width + padding * 2,
      height: height + padding * 2,
      opacity: 1,
    });

  }, [activeInput, setOverlaySpring, stack, shirt]);

  const overlayTarget = document.getElementById(facing === 'front' ? frontStackSVGId : backStackSVGId);

  const bottomBar = document.getElementById(bottomBarId)

  return (
    <StyledDiv
      {...bind()}
      className="ShirtCloseUp center"
    >
      {overlayTarget && ReactDOM.createPortal((
        <GuideSvg>
          <animated.rect
            filter="url(#dropshadow)"
            rx="6"
            stroke="#0bf"
            strokeWidth="2px"
            fill="url(#pattern)"
            {...overlaySpring}
          />
        </GuideSvg>
      ), overlayTarget)}
      <animated.div
        className="ShirtCloseUp__zoomable ratio-box"
        style={{
          x: dragAndZoomSpring.x,
          y: dragAndZoomSpring.y,
          scale: dragAndZoomSpring.scale,
          transformOrigin: dragAndZoomSpring.transformOrigin,
        }}
      >
        <SwitchTransition mode="out-in">
          <CSSTransition
            key={facing}
            addEndListener={(node, done) => node.addEventListener('transitionend', (e) => {
              done()
            }, false)}
            classNames="ShirtCloseUp__fadeIn"
          >
            <ShirtMockup
              shirt={shirt}
              stack={stack}
              facing={facing}
              shirtImagesMap={appShirtImagesMap}
            />
          </CSSTransition>
        </SwitchTransition>
      </animated.div>
      {bottomBar && ReactDOM.createPortal((
        <StyledZoomButtonsInBottomBar>
          <ZoomButtons
            facing={facing}
            onChangeFacing={setFacing}
            scale={scale}
            onChangeScale={setScale}
          />
        </StyledZoomButtonsInBottomBar>
      ), bottomBar)}
      <div className="ShirtCloseUp__zoomButtonsMd p-4 d-none d-md-block">
        <ZoomButtons
          facing={facing}
          onChangeFacing={setFacing}
          scale={scale}
          onChangeScale={setScale}
        />
      </div>
    </StyledDiv>
  );
}

export default ShirtCloseUp;
