import React from 'react';
import { useFormContext } from 'react-hook-form';
import { ACTIVE_INPUTS } from 'src/components/ShirtCloseUp/helpers';
import { EditableStackAttributes } from 'src/sdk/schemaTypes';

const VersionInput: React.FC = (props) => {

  const { register } = useFormContext<EditableStackAttributes>()

  return (
    <input
      {...register('version', { required: true })}
      autoCorrect="off"
      autoCapitalize="none"
      type="text"
      style={{
        background: 'rgba(255,255,255, .6)'
      }}
      data-activeinputid={ACTIVE_INPUTS.VERSION}
      data-testid={ACTIVE_INPUTS.VERSION}
      className="form-control form-control-lg"
      placeholder="Version # or name"
      name="version"
    />
  );
}

export default VersionInput;
