import firebase from 'firebase';
import {
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useDebug } from 'src/sdk/debug/DebugContext';
import { useFirebaseSDK } from 'src/sdk/firebase/FirebaseSDKContext';
import {
  ResourceHashType,
  ResourceIdentifierHashType,
  ResourceIdentifierType,
  ResourceType
} from 'src/sdk/schemaTypes';
import {
  PickResourceIdentifier,
  RESOURCE_TYPES
} from 'src/sdk/types';
import {
  MetaType,
  ResourceIdentifierPartial,
  SWRType
} from 'src/types';

export function makeResourceSWR<T>(): SWRType<T> {
  return {
    data: undefined,
    meta: {
      loading: true,
      loaded: false,
      error: null,
    },
    update: (v: T) => void v
  }
}

function isResourceIdentifier(val: any): val is ResourceIdentifierType {
  return val && val.id && val.type && Object.values(RESOURCE_TYPES).includes(val.type);
}

export function serializeKey(r: ResourceIdentifierType): string
export function serializeKey(r: ResourceIdentifierPartial): string | null
export function serializeKey(r: ResourceIdentifierType | ResourceIdentifierPartial): string | null {
  if (isResourceIdentifier(r)) {
    return `${r.type}/${r.id}`;
  }
  else {
    return null;
  }
}

export const cache = new Map<string, ResourceType | null>(); // new Map(Object.entries(initialData))

export function useResource<T extends ResourceType>(resourceIdentifier: ResourceIdentifierPartial)
  : { data: T | null | undefined, meta: MetaType<T> } {

  // null means it doesn't exist or there's an error. undefined means we are loading it.
  const key = serializeKey(resourceIdentifier)
  const debug = useDebug();

  const [error, setError] = useState<Error | firebase.FirebaseError | null>(null);
  const firebaseSDK = useFirebaseSDK();

  const [data, setData] = useState<T | null | undefined>(() => {
    if (key) {
      const warmResource = cache.get(key) as T | null | undefined
      return warmResource;
    }
  });

  useEffect(() => {
    if (key) {
      const listener = firebaseSDK.firebaseDb
        .doc(key)
        .onSnapshot((doc: any) => {
          const resource = doc.data();
          // debug.log('Update on useResource', resource)
          cache.set(key, resource)
          setData(resource)
        }, (e: Error) => {
          setError(e);
        })
      return () => {
        // Hey! As of now I don't see a reason we should keep old resource around while we wait for a key
        // If there's a good reason later, document it!
        setData(undefined)
        listener();
      }
    }
  }, [debug, firebaseSDK, key])

  const meta = useMemo(() => {
    return {
      loading: Boolean(key && data === undefined),
      loaded: data !== undefined,
      error,
    }
  }, [key, data, error])

  useEffect(() => {
    if (error) {
      error.message = `${error.message} Issue getting resource ${key}`
      debug.error(error)
    }
  }, [error, key, debug])

  return {
    data,
    meta,
  }
}

export function useResourcesArray<T extends ResourceType>(resourceIdentifiers?: PickResourceIdentifier<T>[])
  : { data: T[] | undefined, meta: MetaType<T> } {

  const hash: ResourceIdentifierHashType<T> = {}
  if (resourceIdentifiers) {
    for (const r of resourceIdentifiers) {
      hash[r.id] = r;
    }
  }

  const swr = useResources<T>(resourceIdentifiers && hash)

  return useMemo(() => {
    return {
      ...swr,
      data: swr.data && Object.values(swr.data),
    }
  }, [swr])
}

function getResourcesInCache<T extends ResourceType>(resourceIdentifiers: ResourceIdentifierHashType<T>) {
  const resources = {} as ResourceHashType<T>;
  for (const id in resourceIdentifiers) {
    const r = resourceIdentifiers[id];
    const key = serializeKey(r);
    const warmResource = cache.get(key!) as T | null | undefined
    if (!warmResource) {
      return;
    }
    resources[id] = warmResource;
  }
  return resources;
}

export function useResources<T extends ResourceType>(resourceIdentifiers?: ResourceIdentifierHashType<T> | null)
  : { data: ResourceHashType<T> | undefined, meta: MetaType<T> } {

  // Hey, an empty set could still mean an error. undefined means we are loading it.

  const firebaseSDK = useFirebaseSDK();
  const debug = useDebug()
  const [error, setError] = useState<Error | firebase.FirebaseError | null>(null);
  const [data, setData] = useState<ResourceHashType<T> | undefined>(() => {
    if (resourceIdentifiers) {
      return getResourcesInCache<T>(resourceIdentifiers);
    }
  });

  const ref = useRef<{ listeners: any, callStack: any, resourceIdentifiers?: any }>({
    listeners: new Map(),
    callStack: new Error().stack
  });
  ref.current.resourceIdentifiers = resourceIdentifiers;

  useEffect(() => {
    if (resourceIdentifiers && !data) {
      setData(getResourcesInCache<T>(resourceIdentifiers))
    }
  }, [data, resourceIdentifiers])

  useEffect(() => {
    if (resourceIdentifiers === undefined) {
      return void 0;
      // ^^^ So we are loading dependencies
    }
    if (resourceIdentifiers === null) {
      return void setData({})
      // ^^^ This is an empty set
    }
    for (const id in resourceIdentifiers) {
      if (!ref.current.listeners.get(id)) {
        const resourceIdentifier = resourceIdentifiers[id];
        const key = serializeKey(resourceIdentifier);
        if (key) {
          debug.log(`Attaching listener to ${key}`)
          const listener = firebaseSDK.firebaseDb
            .doc(key)
            .onSnapshot((doc: any) => {
              const resource = doc.data();
              if (!resource) {
                setError(new Error(`Got null for ${key}. Could not fetch all resources in useResources.`))
                // But we still set [id]: null in hash
              }
              debug.log(`Updating resource in hash ${key}`)
              cache.set(key!, resource);
              setData((data) => ({
                ...data,
                [id]: resource,
              }))
            }, (e: Error) => {
              console.groupCollapsed(`Got an error in onSnapshot for key ${key} in useResources`);
              console.log('callStack behind useResources', ref.current.callStack);
              console.error(e)
              console.groupEnd();
              debug.error(e, { key })
              setError(e);
            })
          ref.current.listeners.set(id, listener);
        }
      }
    }
    return () => {
      const removeAll = ref.current.resourceIdentifiers === resourceIdentifiers
      for (const [id, listener] of ref.current.listeners) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        if (removeAll || !ref.current.resourceIdentifiers[id]) {
          debug.log(`Removing listener ${id}`)
          listener();
        }
      }
    }
  }, [debug, firebaseSDK, resourceIdentifiers])

  const meta = useMemo(() => {
    const loading =
      resourceIdentifiers === undefined || // We haven't started
      data === undefined ||  // We haven't finished
      Boolean(data && Object.keys(resourceIdentifiers || {}).join('&') !== Object.keys(data).join('&')) // We are fetching new data

    return {
      loading,
      loaded: Boolean(data && !loading),
      error,
    }
  }, [data, error, resourceIdentifiers])

  return { data, meta }
}
