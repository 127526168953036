import { defineMessages } from 'react-intl'
import { OrderStateSchema } from 'src/sdk/schemas';

export default defineMessages({
  numberOfShirtsInOrder: {
    id: 'numberOfShirtsInOrder',
    defaultMessage: `{shirtCount, plural,
      one {{shirtCount} shirt}
      other {{shirtCount} shirts}
    }`,
  },
  [OrderStateSchema.enum.cart]: {
    id: OrderStateSchema.enum.draftedAtPrinter,
    defaultMessage: 'In cart',
  },
  [OrderStateSchema.enum.submitted]: {
    id: OrderStateSchema.enum.submitted,
    defaultMessage: 'Processing',
  },
  [OrderStateSchema.enum.draftedAtPrinter]: {
    id: OrderStateSchema.enum.draftedAtPrinter,
    defaultMessage: 'Printing',
  },
  [OrderStateSchema.enum.printing]: {
    id: OrderStateSchema.enum.printing,
    defaultMessage: 'Printing',
  },
  [OrderStateSchema.enum.inTransit]: {
    id: OrderStateSchema.enum.inTransit,
    defaultMessage: 'In transit',
  },
  [OrderStateSchema.enum.delivered]: {
    id: OrderStateSchema.enum.delivered,
    defaultMessage: 'Delivered',
  },
  [OrderStateSchema.enum.canceled]: {
    id: OrderStateSchema.enum.canceled,
    defaultMessage: 'Cancelled',
  },
})
