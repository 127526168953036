import classnames from 'classnames'
import React from 'react';
import { useFormContext } from 'react-hook-form';
import PropertyOfIcon from 'src/components/PropertyOfIcon/PropertyOfIcon'
import { ACTIVE_INPUTS } from 'src/components/ShirtCloseUp/helpers';
import { EditableShirtAttributesType } from 'src/sdk/schemaTypes';

export const SHIRT_USERNAME_INPUT_ID = 'shirt-username'

const UsernameInput: React.FC = (props) => {

  const { register, formState, watch, setValue } = useFormContext<EditableShirtAttributesType>(); // retrieve all hook methods

  const providerId = watch('providerId')

  return (
    <div className="input-group input-group-lg">
      <span
        className="input-group-prepend"
      >
        <button
          type="button"
          onClick={() => {
            setValue('providerId', 'github.com', {
              shouldValidate: true,
              shouldDirty: true
            })
          }}
          onKeyPress={() => {
            setValue('providerId', 'github.com', {
              shouldValidate: true,
              shouldDirty: true
            })
          }}
          className={classnames('m-0 btn btn-primary', {
            active: providerId === 'github.com',
          })}
          data-activeinputid={ACTIVE_INPUTS.PROVIDER}
          aria-checked={providerId === 'github.com'}
          role="radio"
        >
          <PropertyOfIcon providerId="github.com" />
          <span className="sr-only">Github</span>
        </button>

        <button
          type="button"
          onClick={() => {
            setValue('providerId', 'twitter.com', {
              shouldValidate: true,
              shouldDirty: true
            })
          }}
          onKeyPress={() => {
            setValue('providerId', 'twitter.com', {
              shouldValidate: true,
              shouldDirty: true
            })
          }}
          className={classnames('m-0 btn btn-primary', {
            active: providerId === 'twitter.com',
          })}
          data-activeinputid={ACTIVE_INPUTS.PROVIDER}
          aria-checked={providerId === 'twitter.com'}
          role="radio"
        >
          <PropertyOfIcon providerId="twitter.com" />
          <span className="sr-only">Twitter</span>
        </button>
      </span>
      <input
        autoCorrect="off"
        autoCapitalize="none"
        type="text"
        className={classnames('form-control', formState.errors.username && formState.touchedFields.username && 'is-invalid')}
        placeholder="Username/handle"
        data-activeinputid={ACTIVE_INPUTS.USERNAME}
        {...register('username', { required: true })}
      />
    </div>
  );
}


export default UsernameInput;
