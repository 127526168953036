import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router';
import {
  Link,
  useHistory
} from 'react-router-dom';
import { navHeight } from 'src/components/Navbar/Navbar';
import PageView from 'src/components/PageView/PageView';
import {
  initialAuth
} from 'src/pages/AuthListener/AuthListener';
import { useAnalytics } from 'src/sdk/analytics/AnalyticsContext';
import { AuthContext } from 'src/sdk/auth/AuthProvider';
import { useFirebaseSDK } from 'src/sdk/firebase/FirebaseSDKContext';
import { makeHomePathname } from 'src/sdk/PATHS';
import breakpoints from 'src/styles/breakpoints';
import { nprogressTry } from 'src/utils/nprogress';
import styled from 'styled-components';

const StyledDiv = styled.div`
  min-height: calc(100vh - ${navHeight.xs * 2}px);

  @media (min-width: ${breakpoints.md}px) {
    min-height: calc(100vh - ${navHeight.md * 2}px);
  }
`

const SignedIn: React.FC = (props) => {

  const analytics = useAnalytics();
  const params = useParams()
  const { setAuth } = useContext(AuthContext);
  const firebaseSDK = useFirebaseSDK();
  const history = useHistory();

  return (
    <>
      <Helmet defer={false}>
        <title>Signed in</title>
        <meta
          name="description"
          content="Signed in already"
        />
      </Helmet>
      <PageView
        onPageView={() => analytics.page({
          name: 'Signed In'
        })}
      />
      <StyledDiv className="container text-center center">
        <div>
          <h1 className="lead">
            Signed in
          </h1>
          <div className="w-md-75 m-auto">
            <p className="p">
              Where do you want to go?
            </p>
          </div>
          <div>
            <Link
              to={makeHomePathname(params)}
              className="btn btn-primary mr-2"
            >
              Home
            </Link>
            <button
              className="btn btn-default"
              onClick={() => {
                return nprogressTry(async () => {
                  history.push(makeHomePathname());
                  setAuth(initialAuth); // Then we logged out!
                  await firebaseSDK.firebaseAuth.signOut()
                })
              }}
            >
              Log out
            </button>
          </div>
        </div>
      </StyledDiv>
    </>
  );
}

export default SignedIn
