import React from 'react';
import StackProductSVGTheme from 'src/components/StackProductSVGTheme/StackProductSVGTheme';
import {
  FacingUnion,
  ProductOnStackType,
  StackType
} from 'src/sdk/schemaTypes';
import stackDimensions from 'src/stackDimensions';

const {
  getRadius,
  getStroke,
  gutter,
  stackItemHeight,
  stackItemWidth
} = stackDimensions;

interface Props {
  stack: StackType
  productOnStack: ProductOnStackType;
  facing: FacingUnion;
}

const SvgGridItem: React.FC<Props> = (props) => {

  const {
    stack,
    productOnStack,
    facing,
  } = props;

  const {
    gridItem,
    color,
  } = productOnStack;

  const x = gridItem.x * (stackItemWidth + gutter);
  const y = gridItem.y * (stackItemHeight + gutter);
  const width = gridItem.w * stackItemWidth + (gridItem.w - 1) * gutter;


  const stroke = getStroke(facing);
  const radius = getRadius(facing);

  return (
    <g>
      <rect
        x={x}
        y={y}
        rx={radius}
        fill="white"
        width={width}
        height={stackItemHeight}
      />
      {facing === 'back' ? (
        <StackProductSVGTheme
          theme={stack.attributes.theme}
          logo={productOnStack.logo}
          color={productOnStack.color}
          facing="back"
          svgProps={{
            x: x + stroke,
            y: y + stroke,
            width: width - stroke * 2,
            height: stackItemHeight - stroke * 2,
          }}
        />
      ) : (
        <rect
          x={x + stroke}
          y={y + stroke}
          rx={radius}
          fill={color.hex}
          width={width - stroke * 2}
          height={stackItemHeight - stroke * 2}
        />
      )}
    </g>
  );
}


// SvgGridItem.propTypes = {
//   productOnStack: PropTypes.object,
//   facing: PropTypes.string,
// };

SvgGridItem.defaultProps = {};

export default SvgGridItem;
