import {
  useEffect,
  useRef,
  useState
} from 'react';
import { isDev } from 'src/utils/environment';

export default function usePreventObjectLiteralInDev(value: Object) {
  const [memoInitial] = useState(() => {
    if (typeof value === 'object') {
      return {
        string: JSON.stringify(value),
        value,
      }
    }
  });
  const { current: memoLast } = useRef(memoInitial)

  useEffect(() => {
    if (isDev && value && memoLast) {
      const string = JSON.stringify(value)
      if (string === memoLast.string && value !== memoLast.value) {
        throw new Error(`Looks like you did not memoize correctly: ${JSON.stringify(value, null, 2)}`)
      }
      Object.assign(memoLast, {
        string,
        value,
      })
    }
  }, [value, memoLast])
}
