import React from 'react';
import { useFormContext } from 'react-hook-form';
import { ShirtColorsSchema } from 'src/sdk/schemas';
import { EditableShirtAttributesType } from 'src/sdk/schemaTypes';

export const SelectColorIdAttribute = 'selectColor'

const SelectColor: React.FC = () => {

  const { register } = useFormContext<EditableShirtAttributesType>()

  return (
    <select
      {...register('color')}
      id={SelectColorIdAttribute}
      name="color"
      className="custom-select custom-select-lg"
    >
      {Object.values(ShirtColorsSchema.options).map((color) => {
        return (
          <option
            key={color}
            value={color}
          >
            {color}
          </option>
        );
      })}
    </select>
  );
}

export default SelectColor;
