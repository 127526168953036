import {
  useEffect,
  useState
} from 'react';
import useSimpleSWR from 'src/hooks/useSimpleSWR';
import { ResourceType } from 'src/sdk/schemaTypes';
import {
  ConfigInterface,
} from 'swr';
import {
  MetaType
} from 'src/types'
import qs from 'src/utils/qs';

export interface QueryType<T extends ResourceType, P> {
  data: T[] | undefined;
  meta: MetaType<T[]>;
  params: P | null;
  id: string;
}

export default function useQuery<T extends ResourceType, P>(
  key: P | false | null | undefined,
  fetcher: (arg: any) => Promise<any>,
  config?: ConfigInterface
): QueryType<T, P> {

  const params = key ? key : null
  const id = qs.stringify(params);
  const querySWR = useSimpleSWR<T[]>(key, fetcher, config);
  const [query, setQuery] = useState<QueryType<T, P>>({
    data: undefined,
    meta: {
      loading: true,
      loaded: false,
      error: undefined,
    },
    params,
    id,
  });

  useEffect(() => {
    if (query.data && !params) {
      setQuery({
        data: undefined,
        meta: {
          loading: true,
          loaded: false,
          error: undefined,
        },
        params,
        id,
      })
    }
  }, [params, id, query.data])

  useEffect(() => {
    setQuery((prevQuery: QueryType<T, P>) => ({
      // 👇 this is why we have useQuery - it only updates data if new query results are loaded!
      data: querySWR.meta.loaded ? querySWR.data : prevQuery.data,
      // 👇 but meta.loading is for active query, thus can show spinner AND old results til new ones come in.
      meta: querySWR.meta,
      params,
      id,
    }));
  }, [querySWR, params, id])
  return query;
}
