import React from 'react';
import { StackProductSVGThemeProps } from 'src/components/StackProductSVGTheme/types';
import { defaultThemeProps } from 'src/components/StackProductSVGTheme/utils';
import { FacingUnion } from 'src/sdk/schemaTypes';

function getRadius(facing: FacingUnion) {
  if (facing === 'front') {
    return 8;
  }
  return 4;
}

const DefaultTheme: React.FC<StackProductSVGThemeProps> = (props) => {

  const themeProps = Object.assign(defaultThemeProps, props)

  const {
    logo,
    color,
    facing,
    svgProps,
  } = themeProps

  const radius = getRadius(facing)

  return (
    <svg
      {...svgProps}
    >
      <rect
        width="100%"
        height="100%"
        rx={radius}
        x={0}
        y={0}
        fill={color.hex}
      />
      <image
        height="68%"
        width="68%"
        x="16%"
        y="16%"
        href={logo.src}
      />
    </svg>

  );
}

export default DefaultTheme
