import firebase from 'firebase';
import produce from 'immer';
import { ResourceType } from 'src/sdk/schemaTypes';
import {
  DebugType,
  FirebaseDocResourceType,
  FirebaseType,
  FirebaseUniversalSDKType,
  PickResourceIdentifier
} from 'src/sdk/types';
import pLimit from 'p-limit';

interface GetAllAndMapOptions {
  collection: string,
  limit?: number,
  wheres?: [firebase.firestore.FieldPath, firebase.firestore.WhereFilterOp, any][],
}

export default class MethodsSDK {

  private firebaseSDK: FirebaseType;
  private debug: any;

  constructor(firebaseSDK: FirebaseUniversalSDKType, debug: DebugType = console) {
    this.firebaseSDK = firebaseSDK;
    this.debug = debug;
  }


  async getAllAndMap<T extends ResourceType>(options: GetAllAndMapOptions, cb: (r: T) => any): Promise<any> {
    const {
      collection,
      limit = 1,
      wheres = []
    } = options

    const limiter = pLimit(limit)
    const promises: Promise<any>[] = []
    const queryRef = this.firebaseSDK.firebaseDb.collection(collection)
    if (wheres.length) {
      wheres.forEach((where) => {
        queryRef.where(...where)
      })
    }
    const querySnapshot = await queryRef.get()
    querySnapshot.forEach((doc: FirebaseDocResourceType<T>) => {
      promises.push(limiter(() => cb(doc.data())))
    })
    return await Promise.all(promises)
  }

  getResource<T extends ResourceType>(resourceIdentifier: PickResourceIdentifier<T>): Promise<T> {
    const {
      id,
      type
    } = resourceIdentifier;

    return this.firebaseSDK.firebaseDb
      .doc(`${type}/${id}`)
      .get()
      .then((doc: FirebaseDocResourceType<T>) => {
        if (doc.exists) {
          const resource = doc.data();
          this.debug.log(`Got resource ${type}/${id}`, resource);
          return resource;
        }
        this.debug.log(`Got resource ${type}/${id}`, null);
        return null;
      })
      .catch((e: Error) => {
        this.debug.log(`Issue getting resource ${type}/${id}`);
        throw e;
      });
  }

  async upsertResource<T extends ResourceType>(resource: T): Promise<T> {
    const {
      id,
      type,
    } = resource;

    const updatedResource = produce(resource, (draft: ResourceType) => {
      draft.attributes.updatedOn = this.firebaseSDK.makeTimestamp();
    })

    this.debug.log(`Upserting resource ${type}/${id}`, updatedResource);

    const docRef = this.firebaseSDK.firebaseDb
      .doc(`${type}/${id}`);

    await docRef
      .set(updatedResource)
      // .then(() => this.getResource(resource))
      .catch((e: Error) => {
        this.debug.log('Issue upserting resource', updatedResource);
        throw e;
      });

    return resource;
  }

  async upsertResources(resources: Array<ResourceType>) {

    const batch = this.firebaseSDK.firebaseDb.batch();

    await Promise.all(resources.map((resource) => {

      /**
       * TODO: Need to check if some need a createdOn
       */
      const resourceWithTimestamps = produce(resource, (draft) => {
        draft.attributes.updatedOn = this.firebaseSDK.makeTimestamp();
        if (!draft.attributes.createdOn) {
          draft.attributes.createdOn = this.firebaseSDK.makeTimestamp();
        }
      })

      const {
        id,
        type,
      } = resource;

      const docRef = this.firebaseSDK.firebaseDb
        .doc(`${type}/${id}`);

      batch.set(docRef, resourceWithTimestamps);
      return resource;
    }))
      .then(() => {
        this.debug.log('Updating resources in batch', resources);
        return batch.commit();
      })
      .then(() => Promise.all(resources.map(r => this.getResource(r))))
      .catch((e) => {
        this.debug.log('Issue doing batch update');
        throw e;
      });

    return resources;
  }

}
