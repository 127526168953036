import { createContext, useContext } from 'react';
import UsersSDK from 'src/sdk/users/UsersSDK';

export const UserSDKContext = createContext<UsersSDK | null>(null)

export const useUsersSDK = () => {
  const usersSDK = useContext(UserSDKContext);

  if (!usersSDK) {
    throw Error('You need to have a UserSDKContext.Provider')
  }
  return usersSDK;
}
