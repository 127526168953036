import {
  ProductOnStackType,
  StackType
} from 'src/sdk/schemaTypes';
import { v4 as uuid } from 'uuid';
import _last from 'src/utils/_last';
import _sortBy from 'src/utils/_sortBy';
import stackDimensions from '../../stackDimensions';

const { minRows } = stackDimensions;

export default function makeBlanks(stack: StackType) {
  let gridItems = Object.values(stack.relationships.products || {})
    .map(productOnStack => (productOnStack as ProductOnStackType).gridItem)
  gridItems = _sortBy(gridItems, ['y'])

  const gridItemInLastRow = _last(gridItems)
  let numOfRows = gridItemInLastRow ? gridItemInLastRow.y : 0
  if (numOfRows < minRows) {
    numOfRows = minRows
  }

  let y = 0
  const blanks = []
  while (y <= numOfRows) {
    // eslint-disable-next-line no-loop-func
    const logosInRow = gridItems.filter(gridItem => gridItem.y === y)
    const sortedLogosInRow = _sortBy(logosInRow, ['x'])

    let x = 0
    let logoInRow
    while (x < 12) {
      /**
       * This will always at least be undefined
       */
      logoInRow = sortedLogosInRow.shift()
      if (!logoInRow) {
        /**
         * Create an item to fill the rest of the row
         */
        const id = uuid()
        const w = 12 - x
        blanks.push({
          i: id,
          x,
          y,
          w,
        })
        x += w
      } else if (logoInRow.x !== x) {
        /**
         * Create an item to fill up until the x
         */
        const w = logoInRow.x - x
        const id = uuid()
        blanks.push({
          i: id,
          x,
          y,
          w,
        })
        x = logoInRow.x + logoInRow.w
      } else {
        x = logoInRow.x + logoInRow.w
      }
    }
    y++
  }

  return blanks
}

