import produce from 'immer';
import React, {
  useCallback,
  useContext
} from 'react';
import {
  RotateCcw,
  ZoomIn,
  ZoomOut
} from 'react-feather';
import {
  SCALE_VALUES,
  ScaleType
} from 'src/components/ShirtCloseUp/helpers';
import { UserContext } from 'src/pages/AuthListener/User';
import { useMethodsSDK } from 'src/sdk/methods/MethodsSDKProvider';
import {
  FacingSchema,
  ReachedShirtCloseUpStepSchema
} from 'src/sdk/schemas';
import {
  FacingUnion,
  UserType
} from 'src/sdk/schemaTypes';

interface Props {
  facing: FacingUnion;
  onChangeFacing: (o: FacingUnion) => void;
  scale: ScaleType;
  onChangeScale: (s: ScaleType) => void;
}

const ZoomButtons: React.FC<Props> = (props) => {

  const {
    facing,
    onChangeFacing,
    scale,
    onChangeScale,
  } = props

  const { data: user } = useContext(UserContext);
  const methodsSDK = useMethodsSDK();

  const currentStepId = user?.attributes?.reachedShirtCloseUpStepId
  const handleZoom = useCallback(async () => {
    if (user && currentStepId === ReachedShirtCloseUpStepSchema.enum.zoomStep) {
      const updatedUser = produce<UserType>(user, (draft) => {
        draft.attributes.reachedShirtCloseUpStepId = ReachedShirtCloseUpStepSchema.enum.flipStep;
      })
      return methodsSDK.upsertResource(updatedUser)
    }
  }, [user, currentStepId, methodsSDK]);

  const currentIndex = SCALE_VALUES.indexOf(scale);

  const zoomIn = useCallback(() => {
    if (currentIndex < SCALE_VALUES.length - 1) {
      onChangeScale(SCALE_VALUES[currentIndex + 1])
    }
  }, [currentIndex, onChangeScale])
  const zoomOut = useCallback(() => {
    if (currentIndex > 0) {
      onChangeScale(SCALE_VALUES[currentIndex - 1])
    }
  }, [currentIndex, onChangeScale])

  return (
    <span>
      <div className="btn-group">
        <button
          type="button"
          onClick={() => {
            onChangeFacing(facing === FacingSchema.enum.front ? FacingSchema.enum.back : FacingSchema.enum.front)
          }}
          className="btn btn-default"
        >
          <RotateCcw />
        </button>
        <button
          type="button"
          disabled={currentIndex === 0}
          onClick={() => {
            zoomOut();
            return handleZoom();
          }}
          className="btn btn-default"
        >
          <ZoomOut />
        </button>
        <button
          type="button"
          disabled={currentIndex === SCALE_VALUES.length - 1}
          onClick={() => {
            zoomIn();
            return handleZoom();
          }}
          className="btn btn-default"
        >
          <ZoomIn />
        </button>

      </div>
    </span>
  );
}

export default ZoomButtons;
