import React from 'react';
import { StackProductSVGThemeProps } from 'src/components/StackProductSVGTheme/types';
import { defaultThemeProps } from 'src/components/StackProductSVGTheme/utils';
import { FacingUnion } from 'src/sdk/schemaTypes';

function getRadius(facing: FacingUnion) {
  if (facing === 'front') {
    return 8;
  }
  return 4;
}

const WhiteBorderTheme: React.FC<StackProductSVGThemeProps> = (props) => {

  const themeProps = Object.assign(defaultThemeProps, props)

  const {
    logo,
    color,
    facing,
    svgProps
  } = themeProps

  const radius = getRadius(facing)
  const filterId = `white-border-${logo.name}`
  const filterUrl = `url(#${filterId})`


  return (
    <svg
      {...svgProps}
    >
      <defs>
        <filter
          id={filterId}
          x="-10%"
          y="-10%"
          width="120%"
          height="120%"
          colorInterpolationFilters="sRGB"
        >

          <feFlood
            floodColor="white"
            result="outside-color"
          />
          <feMorphology
            in="SourceAlpha"
            operator="dilate"
            radius={1}
          />
          <feComposite
            in="outside-color"
            operator="in"
            result="outside-stroke"
          />
          <feMerge>
            <feMergeNode />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      <rect
        width="100%"
        height="100%"
        rx={radius}
        x={0}
        y={0}
        fill={color.hex}
      />
      <image
        height="68%"
        width="68%"
        x="16%"
        y="16%"
        filter={filterUrl}
        href={logo.src}
      />
    </svg>

  );
}

export default WhiteBorderTheme
