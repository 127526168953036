import classnames from 'classnames';
import React, { MutableRefObject } from 'react';
import { X } from 'react-feather';
import StackProductSVGTheme from 'src/components/StackProductSVGTheme/StackProductSVGTheme';
import useSimpleSWR from 'src/hooks/useSimpleSWR';
import { useProductsSDK } from 'src/sdk/products/ProductsSDKContext';
import {
  ProductOnStackType,
  ProductType,
  StackType,
  ThemeUnion
} from 'src/sdk/schemaTypes';
import breakpoints from 'src/styles/breakpoints';
import {
  DARK,
  getContrastLightOrDark,
  LIGHT
} from 'src/utils/colorUtils';
import styled from 'styled-components'

const StyledDiv = styled.div`

  overflow: hidden;
  height: 100%;
  width: 100%;
  position: relative;
  cursor: grab;

  .GridItem__removeProduct {
    position: absolute;
    cursor: pointer;
    z-index: 10;
    top: 0;
    left: 0;
  }

  @media (max-width: ${breakpoints.md - 1}px) {
    .GridItem__removeProduct {
      right: 0;
      left: auto;
    }
  }
`


type Props = {
  productOnStack: ProductOnStackType;
  stack: StackType;
  theme?: ThemeUnion;
  canRemove?: boolean;
  disabled?: boolean;
  onRemove?: Function;
  animatedRef?: MutableRefObject<Promise<any> | null>
}

const GridItem: React.FC<Props> = (props) => {

  const {
    productOnStack,
    stack,
    canRemove,
    onRemove,
  } = props;

  const productsSDK = useProductsSDK();
  const { data: product } = useSimpleSWR<ProductType, [string]>(productOnStack.id, productsSDK.getProduct);
  const color = productOnStack.color
  const hexColor = color.hex;
  const contrastToColor = hexColor && getContrastLightOrDark(hexColor);

  return (
    <StyledDiv
      className={classnames('GridItem shadow-sm', {
        lightHandle: contrastToColor === LIGHT,
        darkHandle: contrastToColor === DARK
        /**
         * The lightHandle/darkHandle is used to style the sibling
         * handle (added by react-grid-layout)
         */
      })}
    >
      <StackProductSVGTheme
        theme={stack.attributes.theme}
        logo={productOnStack.logo}
        color={productOnStack.color}
        facing="back"
        svgProps={{
          width: '100%',
          height: '100%'
        }}
      />
      {canRemove && (
        <div
          role="button"
          tabIndex={0}
          onKeyPress={() => onRemove && onRemove(product)}
          onClick={() => onRemove && onRemove(product)}
          className="GridItem__removeProduct"
        >
          <X
            style={{
              color: contrastToColor === LIGHT ? '#fff' : '#333',
              verticalAlign: 'top',
            }}
            size={20}
          />
        </div>
      )}
    </StyledDiv>
  );
};

export default GridItem;
