import {
  GridItemType,
  LogoType,
  ProductsOnStackType,
  ProductType
} from 'src/sdk/schemaTypes';
import breakpoints from 'src/styles/breakpoints';
import { Responsive } from '@stackshirts/react-grid-layout';
import _sortBy from 'src/utils/_sortBy';
import stackDimensions from 'src/stackDimensions';

export function makeGridItemInStack(product: ProductType, productsOnStack: ProductsOnStackType): GridItemType {

  const gridItems = Object.values(productsOnStack || {})
    .map(productOnStack => productOnStack.gridItem)

  const logo = product.attributes.logos[0]
  const i = product.id

  /**
   * We set the width below
   */
  const minW = getLogoW(logo)

  // Now go through every item in stack and find a place for minW
  // !x if x is 0 is true
  let x = -1
  let y = 0
  while (x < 0) {
    const logosInRow = gridItems.filter(gridItem => gridItem.y === y) /* eslint-disable-line no-loop-func */
    const sortedLogosInRow: GridItemType[] = _sortBy(logosInRow, ['x'])

    if (sortedLogosInRow.length === 0) {
      x = 0
    }

    // Otherwise let's go through the row
    // !x if x is 0 is true
    let logoInRow
    while (x < 0 && (logoInRow = sortedLogosInRow.shift())) {
      const endOfLogoX = logoInRow.x + logoInRow.w
      const startOfNextLogoX = sortedLogosInRow[0]?.x
      if (!startOfNextLogoX) {
        if (stackDimensions.columns - endOfLogoX >= minW) {
          x = endOfLogoX
        }
      }
      else if (startOfNextLogoX - endOfLogoX > minW) {
        x = endOfLogoX
      }
    }

    if (x < 0) {
      y++
    }
  }

  return {
    ...gridRestrictions,
    i,
    x,
    y,
    w: minW,
    h: 1,
    minW,
    minH: 1,
    maxH: 1
  }
}

export const getLogoW = (logo: LogoType): number => {
  /*  Get proportional width when height is half of stackItemHeight */
  const proportionalWidth = (stackDimensions.heightOfLogoInDataGrid / logo.height) * logo.width;

  function widthOfGridItemWithColumns(w: number) {
    return (w * stackDimensions.stackItemWidth + (w - 1) * stackDimensions.gutter) - stackDimensions.gutter * 4;
  }

  /**
   * minW is minimum width of logo accounting for
   * logo proportions
   */
  let minW = 0;
  let w = 1;
  while (minW === 0 && w < 12) {
    if (proportionalWidth < widthOfGridItemWithColumns(w)) {
      minW = w;
    }
    else {
      w++;
    }
  }
  return minW;
};

export const gridRestrictions = {
  w: 1,
  h: 1,
  maxH: 1,
  minH: 1,
  minW: 1,
  maxW: 12,
  isDraggable: true,
  isResizable: true,
  static: false,
  moved: false,
};

export type PartialGridItemType = Omit<GridItemType, 'x' | 'y'>;

export const makePartialGridItem = (product: ProductType): PartialGridItemType => {

  const logo = product.attributes.logos[0];
  const i = product.id;
  const minW = getLogoW(logo);

  return {
    ...gridRestrictions,
    i,
    w: minW,
    minW,
  };
};

export const rglBreakpoints: any = {
  ...breakpoints,
  xs: 480,
  xxs: 300,
}

export const getRowHeightAtGridWidth = (gridWidth: number): number => {
  const breakpoint = Responsive.utils.getBreakpointFromWidth(rglBreakpoints, gridWidth)
  if (breakpoint) {
    return (rglBreakpoints[breakpoint] / stackDimensions.artworkWidth) * stackDimensions.stackItemHeight
  }
  return 120
}

