import * as React from "react";

function SvgOrderIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 30 32" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M2.314 8l12.959-6.512L28.408 8.23v16.216L15.36 30.91l-.088-15.732z"
        />
        <path fill="#C4C4C4" d="M15.273 14.81L2 8v16.098l13.273 6.811z" />
        <path
          d="M15 2.526a1.84 1.84 0 00-.822.194L3.35 8.168a1.87 1.87 0 00-.754.692 1.9 1.9 0 00-.282.996v12.97c-.003.353.093.7.275 1 .182.299.442.54.75.696l10.826 5.448a1.842 1.842 0 001.657 0L26.65 24.52a1.87 1.87 0 00.754-.692 1.9 1.9 0 00.282-.995V9.856a1.9 1.9 0 00-.282-.996 1.871 1.871 0 00-.756-.693L15.823 2.721A1.842 1.842 0 0015 2.526zm1.585-1.305h.002L27.41 6.669a3.557 3.557 0 011.968 3.188v12.978a3.572 3.572 0 01-1.966 3.187l-10.825 5.447a3.543 3.543 0 01-3.187 0L2.572 26.019a3.557 3.557 0 01-1.95-3.199V9.855A3.572 3.572 0 012.588 6.67L13.415 1.22a3.543 3.543 0 013.17 0z"
          fill="#000"
          fillRule="nonzero"
        />
        <path
          d="M27.716 7.683a.846.846 0 11.765 1.509l-13.1 6.637a.846.846 0 01-.764 0L1.518 9.192a.846.846 0 01.765-1.509l12.716 6.444 12.717-6.444z"
          fill="#000"
          fillRule="nonzero"
        />
        <path
          d="M16 30.193c0 .446-.448.807-1 .807s-1-.361-1-.807V14.807c0-.446.448-.807 1-.807s1 .361 1 .807v15.386zM7.852 5.544a.846.846 0 01.764-1.508l13.532 6.856a.846.846 0 11-.764 1.509L7.852 5.544z"
          fill="#000"
          fillRule="nonzero"
        />
        <path d="M-3 0h36v32H-3z" />
      </g>
    </svg>
  );
}

export default SvgOrderIcon;
