import { CSSTransition, TransitionGroup } from 'react-transition-group';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components'

const StyledDiv = styled.div`

  z-index: 3;
  background: #a5c9ff;
  transition: transform .25s;
  left: -5%;
  right: -5%;
  overflow: hidden;

  &.loading-enter {
    transform: translateX(-110%) skew(15deg);
  }

  &.loading-enter-active {
    transform: translateX(0) skew(15deg);
  }

  &.loading-exit {
    transform: translateX(0) skew(15deg);
  }

  &.loading-exit-active,
  &.loading-exit-done {
    transform: translateX(110%) skew(15deg);
  }

  @keyframes before {
    0% {
      transform: translateX(-110%) skew(15deg);
    }
    100% {
      transform: translateX(110%) skew(15deg);
    }
  }

  &:before {
    transform: translateX(-110%) skew(15deg);
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    background: #6b8cf2;
    width: 100%;
    height: 100%;
    animation-delay: .25s;
    animation-duration: .4s;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    animation-name: before;
    animation-timing-function: linear;

  }


  @keyframes after {
    0% {
      transform: translateX(-210%) skew(15deg);
    }
    100% {
      transform: translateX(160%) skew(15deg);
    }
  }

  //&:after {
  //  transform: translateX(-210%) skew(15deg);
  //  content: '';
  //  position: absolute;
  //  left: 0;
  //  bottom: 0;
  //  background: #3a5595;
  //  width: 100%;
  //  height: 100%;
  //  animation-delay: .25s;
  //  animation-duration: .4s;
  //  animation-fill-mode: forwards;
  //  animation-iteration-count: 1;
  //  animation-name: after;
  //  animation-timing-function: linear;
  //}

  @keyframes slow {
    0% {
      transform: translateX(-110%) skew(15deg);
    }
    100% {
      transform: translateX(160%) skew(15deg);
    }
  }


  .slow {
    transform: translateX(-110%) skew(15deg);
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    background: #122344;
    width: 80%;
    height: 100%;
    animation-delay: .65s;
    animation-duration: 1.8s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: slow;
    animation-timing-function: ease;
  }

`

type Props = {
  loaded: boolean;
  queryId?: string;
}

const GridItemSearchLoading: React.FC<Props> = (props) => {

  const {
    queryId,
    loaded,
  } = props

  const [done, setDone] = useState(false);
  const ref = useRef(new Date().getTime())

  useMemo(() => {
    if (queryId) {
      setDone(false)
      ref.current = new Date().getTime();
    }
  }, [queryId])

  useEffect(() => {
    if (loaded) {
      const diff = new Date().getTime() - ref.current; // 0 - 1000 for example
      setTimeout(() => {
        setDone(true)
      }, diff > 650 ? 0 : 650 - diff)
    }
  }, [queryId, loaded])

  return (
    <TransitionGroup
      appear
      unmountOnExit
      component={null}
    >
      {!done && (
        <CSSTransition
          key={queryId}
          timeout={400}
          classNames="loading"
        >
          <StyledDiv className="GridItemSearchLoading__panel fill">
            <div className="slow" />
          </StyledDiv>
        </CSSTransition>
      )}
    </TransitionGroup>
  );
}

export default GridItemSearchLoading
