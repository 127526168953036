import React, { useEffect, useRef } from 'react';

type PageKey = string | number | false | null;

export interface PageViewProps {
  pageKey?: PageKey;
  onPageView: () => void;
}

const PageView: React.FC<PageViewProps> = (props) => {

  const {
    onPageView,
  } = props;

  const pageKey = 'pageKey' in props ? props.pageKey : 'callOnLoad'
  const ref = useRef<PageKey | undefined>()
  useEffect(() => {
    if (pageKey && pageKey !== ref.current) {
      ref.current = pageKey;
      onPageView();
    }
  }, [pageKey, onPageView])

  return null;
}

export default PageView
