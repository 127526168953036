import {
  useContext,
  useEffect
} from 'react';
import { AuthContext } from 'src/sdk/auth/AuthProvider';
import { useDebug } from 'src/sdk/debug/DebugContext';
import { useFirebaseSDK } from 'src/sdk/firebase/FirebaseSDKContext';
import axiosInstance from 'src/utils/axiosInstance';

export default function useAuthAxiosInterceptors() {

  const { auth } = useContext(AuthContext);
  const firebaseSDK = useFirebaseSDK();
  const debug = useDebug()


  useEffect(() => {
    let requestInterceptor: any;
    let responseInterceptor: any;

    if (auth.uid) {
      const main = async () => {
        if (!firebaseSDK.firebaseAuth.currentUser) {
          return;
        }

        /**
         * Ok look,
         * If the auth.uid changes,
         * and it exists,
         * then add a request interceptor
         */

        const idToken = await firebaseSDK.firebaseAuth.currentUser.getIdToken();
        requestInterceptor = axiosInstance.interceptors.request.use(config => {
          config.headers['X-Id-Token'] = idToken;
          return config;
        });
        responseInterceptor = axiosInstance.interceptors.response.use(undefined, async (error) => {
          if (error.config && error.response && error.response.status === 401 && !error.config.headers['x-auth-retried']) {
            /**
             * We want to set the response interceptor once
             * and forget about it.
             * If it catches an auth issue, it tries to
             * set a new request interceptor with a fresh token.
             */
            debug.log('Retrying after 401');
            error.config.headers['x-auth-retried'] = 'retried';
            return axiosInstance.request(error.config);
          }
          return Promise.reject(error);
        });
      }
      void main()

      return () => {
        if (requestInterceptor) {
          axiosInstance.interceptors.request.eject(requestInterceptor);
        }
        if (responseInterceptor) {
          axiosInstance.interceptors.response.eject(requestInterceptor);
        }
      };
    }
  }, [auth.uid, debug, firebaseSDK]);

  return null;
}
