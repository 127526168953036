import { useMemo } from 'react';
import usePreventObjectLiteralInDev from 'src/hooks/usePreventObjectLiteralInDev';
import { useDebug } from 'src/sdk/debug/DebugContext';
import {
  Fetcher,
  MetaType
} from 'src/types';
import useSWR, { ConfigInterface } from 'swr';

export default function useSimpleSWR<T, Key extends any[] = any[]>(
  key: Key | any,
  fetcher: Fetcher<T, Key>,
  config?: ConfigInterface,
): { data: T | undefined, meta: MetaType<T> } {

  usePreventObjectLiteralInDev(key); // Because useSWR memoizes an object in a WeakMap: https://github.com/vercel/swr/blob/393af2ee8dc4475abff8fab01509ae6448b0d439/src/libs/hash.ts#L33

  const debug = useDebug()

  let swrKey: any = key ? key : null; // useSWR does not want falsy or undefined, only null
  if (key && !Array.isArray(key) && typeof key !== 'string') {
    swrKey = [key] // useSWR wants an array of objects, not an object by itself
  }

  const {
    data,
    error,
    isValidating
  } = useSWR<T>(swrKey, (...args: Key) => {
    // Remember, ^^^^^^^ that the real swr only wants null keys I think.
    const promise = fetcher(...args)

    // if (!promise.then) {
    //   const e = new Error('The fetcher passed to _useSWR should return a promise')
    //   debug.error(e)
    //   throw e;
    // }

    return promise.catch((e) => {
      debug.error(e)
      throw e;
    })
  }, config)

  return useMemo(() => {
    const meta = {
      loading: data === undefined && isValidating,
      loaded: data !== undefined,
      error,
    }

    return { data, meta }
  }, [data, error, isValidating])
}
