import { createContext, useContext } from 'react';
import ProductsSDK from './ProductsSDK';

export const ProductsSDKContext = createContext<ProductsSDK | null>(null)

export const useProductsSDK = () => {
  const productsSDK = useContext(ProductsSDKContext);

  if (!productsSDK) {
    throw Error('You need to have a ProductsSDKContext.Provider')
  }
  return productsSDK;
}
