import * as Sentry from '@sentry/browser'
import LogRocket from 'logrocket';
import { DebugType } from 'src/sdk/types';
import {
  isBrowser,
  isProdOrPreview
} from 'src/utils/environment';
import { isTest } from 'src/utils/environment';

/**
 * This essentially hides the logs
 * a) In dev they are visible in console because why the hell not
 * b) In prod the logs are visible in LogRocket and Sentry
 */

type Props = {
  sentry?: typeof Sentry | null,
  logrocket?: typeof LogRocket | null
}

export default function makeDebug({ sentry, logrocket }: Props = {}): DebugType {
  return {
    log(...args: any[]) {
      const [message] = args;
      sentry?.addBreadcrumb({
        message
      });

      // Quietly log on browser & in prod
      if (isBrowser && isProdOrPreview) {
        logrocket?.log(...args);
      }
      else if (!isTest) {
        console.log(...args);
      }
    },
    warn(...args: any[]) {
      const [message /*, data */] = args;
      sentry?.captureMessage(message, sentry?.Severity.Warning)

      // Quietly log on browser & in prod
      if (isBrowser && isProdOrPreview) {
        logrocket?.warn(...args);
      }
      else if (!isTest) {
        console.warn(...args);
      }
    },
    error(error: Error, extra?: any) {

      // This gets console logged, so logrocket will find it there
      console.error(error);

      sentry?.withScope((scope: any) => {
        if (extra) {
          scope.setExtra('extra', extra)
        }
        sentry?.captureException(error)
      })

      if (extra) {
        // Quietly log on browser & in prod
        if (isBrowser && isProdOrPreview) {
          logrocket?.log(extra);
        }
        if (!isTest) {
          console.log('extra', extra);
        }
      }
    }
  }
}

