import { defineMessages } from 'react-intl'

export default defineMessages({
  noStacksHeader: {
    id: 'Stack.noStacksHeader',
    defaultMessage: 'You don\'t have a stack yet, but you should!',
  },
  withStacksHeader: {
    id: 'Stack.withStacksHeader',
    defaultMessage: 'Nice collection!',
  },
})
