import {
  ShirtType,
  StackType
} from 'src/sdk/schemaTypes';
import _values from 'lodash/values';
import stackDimensions from 'src/stackDimensions';

export const makeColor = (shirt?: ShirtType | null) => {
  const shirtColor = shirt?.attributes?.color;
  switch (shirtColor) {
    case 'black':
      return '#FFFFFF';
    case 'asphalt':
      return '#FFFFFF';
    case 'white':
    default:
      return '#1B242E';
  }
};


/**
 * This gets the total number of rows for the stack
 * @param {stack} stack The hash of logos in stack
 * @returns {Number} of rows 0-9
 */
export const numberOfRowsInStack = (stack: StackType) => {
  const productsOnStack = stack.relationships.products;
  return _values(productsOnStack).reduce((rows, productOnStack) => {
    const { gridItem } = productOnStack;
    return gridItem.y > rows ? gridItem.y : rows;
  }, 0);
};


/**
 * This gets the viewBox height for the stack
 * @param {object} stack The hash of logos in stack
 * @returns {number} Total height of svg
 */
export const svgHeightOfStack = (stack: StackType) => {
  let rows = numberOfRowsInStack(stack);
  if (rows < stackDimensions.minRows) {
    rows = stackDimensions.minRows;
  }
  return (rows + 1) * stackDimensions.stackItemHeight + (stackDimensions.gutter * rows);
};

export enum STACK_SVG_IDS {
  STACK_VERSION = 'STACK_VERSION',
  STACK_NAME = 'STACK_NAME',
  SHIRT_USERNAME = 'SHIRT_USERNAME',
}
