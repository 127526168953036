import dataUri from 'data-uri.macro'
import React from 'react';
import GridItems from 'src/components/StackSVGs/GridItems';
import printfulSettings from 'src/sdk/PRINTFUL_SETTINGS';
import { FacingSchema } from 'src/sdk/schemas';
import {
  ShirtType,
  StackType
} from 'src/sdk/schemaTypes';
import stackDimensions from 'src/stackDimensions';
import {
  makeColor,
  STACK_SVG_IDS,
  svgHeightOfStack
} from './helpers';
import SvgBlanks from './SvgBlanks';

const Fontello = dataUri('./fontello/fontello.inline.ttf')

const { artworkWidth } = stackDimensions;

interface Props {
  stack: StackType;
  stackOnly?: boolean,
  asArtwork?: boolean;
  shirt?: ShirtType;
}

export const backStackSVGId = 'BackStackSVG'

const BackStackSVG: React.FC<Props> = (props) => {

  const {
    asArtwork,
    stack,
    shirt,
  } = props;

  const facing = FacingSchema.enum.back
  const stackHeight = svgHeightOfStack(stack);
  const font = {
    color: makeColor(shirt),
    family: 'IBM Plex Mono',
    size: 28,
  }

  const xOffset = 5;
  let yOffset = 0;
  let heightOfTextLine = 60

  if (asArtwork) {
    /**
     * Need to make room at top. 400 when print height is 2400
     *
     *   x      1420
     * ----- = ------
     *  400     1800
     */
    yOffset = (1420 / printfulSettings.shirt.backPosition.unisex.width) * 400;
  }

  const yOffsetOfStack = yOffset + heightOfTextLine;
  const yOffsetOfBottomLine = yOffsetOfStack + stackHeight;

  const viewBoxWidth = artworkWidth;
  let viewBoxHeight = yOffsetOfBottomLine + heightOfTextLine; // This means it won't keep space at the bottom

  if (asArtwork) {
    viewBoxHeight = Math.floor((1420 / printfulSettings.shirt.backPosition.unisex.width) * printfulSettings.shirt.backPosition.unisex.height);
  }

  const svgDimensions = {
    style: {
      height: 'auto',
    },
    viewBox: `0 0 ${viewBoxWidth} ${viewBoxHeight}`
  };

  return (
    <svg
      id={backStackSVGId}
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMinYMin"
      {...svgDimensions}
    >
      <style>
        {`
          @font-face {
            font-family: 'fontello';
            src: url('${Fontello}');
            font-weight: normal;
            font-style: normal;
          }
        `}
      </style>

      <text
        id={STACK_SVG_IDS.STACK_NAME}
        alignmentBaseline="middle"
        textAnchor="start"
        fontWeight="600"
        letterSpacing=".5px"
        fontSize={font.size}
        fontFamily={font.family}
        fill={font.color}
        y={yOffset + heightOfTextLine / 2}
        x={xOffset}
        style={{
          opacity: stack.attributes.name ? 1 : 0,
        }}
      >
        {stack.attributes.name || 'foobar'}
      </text>

      <text
        id={STACK_SVG_IDS.STACK_VERSION}
        alignmentBaseline="middle"
        fontWeight="600"
        letterSpacing=".5px"
        fontSize={font.size}
        fontFamily={font.family}
        fill={font.color}
        textAnchor="end"
        y={yOffset + heightOfTextLine / 2}
        x={viewBoxWidth - xOffset}
        style={{
          opacity: stack.attributes.version ? 1 : 0,
        }}
      >
        {stack.attributes.version || 'foobar'}
      </text>


      <svg y={yOffsetOfStack}>
        <SvgBlanks
          stack={stack}
          facing={FacingSchema.enum.back}
        />
        <GridItems
          stack={stack}
          facing={facing}
        />
      </svg>
      {shirt && (
        <>
          <text
            fontWeight="600"
            letterSpacing=".5px"
            fontSize={font.size}
            fontFamily={font.family}
            fill={font.color}
            y={yOffsetOfBottomLine + heightOfTextLine / 2}
            alignmentBaseline="middle"
            textAnchor="start"
          >
            powered by
            {' '}
            <tspan
              alignmentBaseline="middle"
              fontFamily="fontello"
            >
              &#xe800;
            </tspan>
            <tspan
              alignmentBaseline="middle"
              dx="8"
            >
              stackshirts
            </tspan>
          </text>

          <text
            fontWeight="600"
            letterSpacing=".5px"
            fontSize={font.size}
            fontFamily={font.family}
            fill={font.color}
            id={STACK_SVG_IDS.SHIRT_USERNAME}
            textAnchor="end"
            y={yOffsetOfBottomLine + heightOfTextLine / 2}
            alignmentBaseline="middle"

            x={viewBoxWidth - xOffset}
          >
            property of
            {' '}
            <tspan
              alignmentBaseline="middle"
              dx="5"
            >
              {shirt.attributes.providerId === 'twitter.com' ? (
                <tspan
                  alignmentBaseline="middle"
                  fontFamily="fontello"
                >
                  &#xf099;
                </tspan>
              ) : (
                <tspan
                  alignmentBaseline="middle"
                  fontFamily="fontello"
                >
                  &#xf09b;
                </tspan>
              )}
              <tspan
                alignmentBaseline="middle"
                dx="8"
                style={{
                  opacity: shirt.attributes.username ? 1 : 0,
                }}
              >
                {shirt.attributes.username || 'www'}
              </tspan>

            </tspan>
          </text>
        </>
      )}

    </svg>
  );
}

export default BackStackSVG;
