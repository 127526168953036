import * as React from "react";

function SvgStackIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 36 32" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M18.728 14.773a1.159 1.159 0 00-.853-.005L1.36 21.19a1.159 1.159 0 00-.027 2.15l16.516 6.903c.29.121.619.12.908-.006l15.926-6.904a1.159 1.159 0 00-.027-2.138l-15.927-6.42z"
          fill="#CCC"
        />
        <path
          d="M18.67 14.877a1.067 1.067 0 00-.785-.004L2.68 20.783a1.067 1.067 0 00-.025 1.979l15.205 6.355c.268.112.57.11.836-.005l14.662-6.356a1.067 1.067 0 00-.026-1.968l-14.662-5.91zm.748-1.855l14.662 5.912a3.067 3.067 0 01.073 5.657l-14.662 6.356a3.067 3.067 0 01-2.402.016L1.884 24.607a3.067 3.067 0 01.071-5.688l15.205-5.91a3.067 3.067 0 012.258.013z"
          fill="#000"
          fillRule="nonzero"
        />
        <path
          d="M18.728 7.821a1.159 1.159 0 00-.853-.005L1.36 14.237a1.159 1.159 0 00-.027 2.149l16.516 6.904c.29.121.619.12.908-.006l15.926-6.904a1.159 1.159 0 00-.027-2.138l-15.927-6.42z"
          fill="#DDD"
        />
        <path
          d="M18.67 8.477a1.067 1.067 0 00-.785-.005L2.68 14.384a1.067 1.067 0 00-.025 1.978l15.205 6.355c.268.112.57.11.836-.005l14.662-6.356a1.067 1.067 0 00-.026-1.968L18.67 8.478zm15.41 4.057a3.067 3.067 0 01.073 5.657l-14.662 6.356a3.067 3.067 0 01-2.402.016L1.884 18.207a3.067 3.067 0 01.071-5.688L17.16 6.61a3.067 3.067 0 012.258.013l14.662 5.912z"
          fill="#000"
          fillRule="nonzero"
        />
        <path
          d="M18.728 1.449a1.159 1.159 0 00-.853-.006L1.36 7.864a1.159 1.159 0 00-.027 2.15l16.516 6.903c.29.122.619.12.908-.006l15.926-6.904a1.159 1.159 0 00-.027-2.137L18.728 1.449z"
          fill="#FFF"
        />
        <path
          d="M18.67 2.61a1.067 1.067 0 00-.785-.004L2.68 8.516a1.067 1.067 0 00-.025 1.98L17.86 16.85c.268.112.57.11.836-.006l14.662-6.355a1.067 1.067 0 00-.026-1.968L18.67 2.61zm15.41 4.057a3.067 3.067 0 01.073 5.658L19.491 18.68a3.067 3.067 0 01-2.402.016L1.884 12.341a3.067 3.067 0 01.071-5.688L17.16.742a3.067 3.067 0 012.258.014l14.662 5.91z"
          fill="#000"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}

export default SvgStackIcon;
