import React, { useContext } from 'react';

import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';
import { StackType } from 'src/sdk/schemaTypes';
import { UserContext } from 'src/pages/AuthListener/User';
import { useAnalytics } from 'src/sdk/analytics/AnalyticsContext';
import { useCreateStack } from 'src/hooks/useCreateStack';
import { useResources } from 'src/hooks/useResources';
import Alert, { AlertButton, AlertMessage } from 'src/components/Alert/Alert';
import PageView from 'src/components/PageView/PageView';
import StackCard from 'src/components/StackCard/StackCard';
import messages from 'src/pages/Private/Dashboard/DashboardStacks.messages';

const DashboardStacks: React.FC = () => {

  const { data: user } = useContext(UserContext);
  const { data: stacks } = useResources<StackType>(user?.relationships?.stacks)

  const [creatingStack, createStack] = useCreateStack();
  const analytics = useAnalytics();

  if (!user || !stacks) {
    return null;
  }


  return (
    <>
      <Helmet defer={false}>
        <title>Your stacks</title>
        <meta
          name="description"
          content="Pick from one of your stacks"
        />
      </Helmet>
      <PageView
        onPageView={() => analytics.page({
          name: 'Stacks',
          category: 'Dashboard'
        })}
      />
      <Alert className="fadeInFromRight">
        <AlertMessage>
          {Object.keys(stacks).length
            ? <FormattedMessage {...messages.withStacksHeader} />
            : <FormattedMessage {...messages.noStacksHeader} />}
        </AlertMessage>
        <AlertButton
          className="btn btn-primary fadeInFromRight second"
          onClick={createStack}
          disabled={creatingStack}
        >
          Create a new one
        </AlertButton>
      </Alert>
      <ul className="fadeInFromRight third list-group">
        {stacks && Object.values(stacks).map((stack) => {
          return (
            <li
              key={stack.id}
              className="list-group-item overflow-hidden"
            >
              <StackCard
                user={user}
                stack={stack}
              />
            </li>
          );
        })}
      </ul>
    </>
  );
}

export default DashboardStacks;
