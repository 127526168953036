import { animated, useSpring } from '@react-spring/web';
import {
  GridItemType,
  ProductsOnStackType,
  StackType
} from 'src/sdk/schemaTypes';
import { Responsive } from '@stackshirts/react-grid-layout'
import {
  rglBreakpoints,
  getRowHeightAtGridWidth,
  makeGridItemInStack
} from 'src/pages/Private/Stack/GridItemSearchResult/helpers';
import { useStacksSDK } from 'src/sdk/stacks/StacksSDKContext';
import GridItem from 'src/pages/Private/Stack/GridItem/GridItem'
import GridItemSearch from 'src/pages/Private/Stack/GridItemSearch/GridItemSearch';
import React, {
  useCallback,
  useRef,
  useState
} from 'react'
import _mapValues from 'src/utils/_mapValues';
import classnames from 'classnames'
import equal from 'fast-deep-equal'
import produce from 'immer';
import raf from 'raf';
import rafscroll from '@braid/rafscroll'
import stackDimensions from 'src/stackDimensions';
import styled, { createGlobalStyle } from 'styled-components'
import useRectangle from 'src/hooks/useRectangle';
import OnLoad from '../../../../components/OnLoad/OnLoad';
import { blurOn } from '../../StackAndShirtAttributes/StackAttributes/StackAttributes';
import usePreventGesture from '../../../../hooks/usePreventGesture';
import { useDrag } from 'react-use-gesture';
import FormAndListeners from '../../../../components/FormAndListeners/FormAndListeners';
import sleep from '../../../../utils/sleep';

const scroll = rafscroll();

const GlobalStyle = createGlobalStyle`
  #Smallchat {
    display: none;
  }
`

const StyledDiv = styled.div`

  touch-action: none;

  .StackGrid__layout {
    transition: height 400ms ease-in-out;
  }

  .StackGrid {
    box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    border: 2px solid #989898;
    background: #fff;
  }

  .StackGrid__deleteOnMobile {
    transition: opacity .4s;
  }

  @media (max-width: ${rglBreakpoints.sm - .02}px) {
    .react-resizable-handle {
      width: 15px;
      height: 15px;
    }
  }

`

const { maxRows } = stackDimensions;

type Props = {
  onChange: (products: ProductsOnStackType) => void;
  stack: StackType;
  bottomBarCTA?: React.ReactNode;
}

function cleanGridItem(gridItem: any): GridItemType {
  return _mapValues(gridItem, v => v === undefined ? null : v) as GridItemType
}

const StackGrid: React.FC<Props> = (props) => {

  const {
    onChange,
    stack,
    bottomBarCTA,
  } = props


  const [selectedProductId, setSelectedProductId] = useState<string | null>(null)
  const [currentProductsOnStack, setCurrentProductsOnStack] = React.useState(stack.relationships.products || {})
  const gridItems = Object.values(currentProductsOnStack)
    .map(productOnStack => productOnStack.gridItem)

  const productsOnStackArray = Object.values(currentProductsOnStack)

  const containerDiv = useRef<HTMLDivElement>(null);
  const gridDiv = useRef<HTMLDivElement>(null);
  const drawerDiv = useRef<HTMLDivElement>(null);
  const rectangle = useRectangle(containerDiv);
  const gridWidth = rectangle.width ? rectangle.width - 4 : 0 // To account for border width on the stack container?
  const rowHeight = getRowHeightAtGridWidth(gridWidth)

  const animatedRef = useRef<Promise<any> | null>(null);

  usePreventGesture();

  const [dragSpring, setDragSpring] = useSpring(() => ({
    y: 0,
    config: {
      mass: 1,
      tension: 360,
      friction: 35
    }
  }))

  const bind = useDrag((state) => {
    const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      movement: [_, y]
    } = state;
    return setDragSpring({
      y
    })
  }, {
    initial: () => {
      return [0, dragSpring.y.get()]
    }
  })

  const onLayoutChange = React.useCallback((gridItemsArray: GridItemType[]) => {
    /**
     * An array of gridItems a la react-grid-layout
     * zoho: {
     *   i: 'zoho', // This is a product id!
     *   w: 1,
     *   h: 1,
     *   ...
     * }
     */
    const different = gridItemsArray.some((gridItem: any) => {
      return !equal(currentProductsOnStack[gridItem.i]?.gridItem, gridItem)
    })
    if (different) {
      setCurrentProductsOnStack((_currentProductsOnStack) => {
        const updatedProductsOnStack = produce<ProductsOnStackType>(_currentProductsOnStack, (draft) => {
          for (let gridItem of gridItemsArray) {
            draft[gridItem.i].gridItem = cleanGridItem(gridItem);
          }
        })
        onChange(updatedProductsOnStack)
        return updatedProductsOnStack;
      })
    }
  }, [onChange, currentProductsOnStack])

  const stacksSDK = useStacksSDK()
  const addProductOnStack = useCallback(async (product) => {
    setCurrentProductsOnStack((_currentProductsOnStack) => {
      const updatedProductsOnStack = produce<ProductsOnStackType>(_currentProductsOnStack, (draft) => {
        const gridItem = makeGridItemInStack(product, draft)
        draft[product.id] = stacksSDK.makeRelationshipToProduct(gridItem, product)
      })
      onChange(updatedProductsOnStack)
      return updatedProductsOnStack;
    })
    raf(() => {
      const el = document.getElementById(`GridItem-${product.id}`);
      if (el) {
        const distanceToTop = window.pageYOffset + el.getBoundingClientRect().top
        scroll.scrollTop(distanceToTop - 200, 360);
      }
    })
  }, [stacksSDK, onChange])

  const removeProductFromStack = React.useCallback((product) => {
    setCurrentProductsOnStack((_currentProductsOnStack) => {
      const updatedProductsOnStack = produce<ProductsOnStackType>(_currentProductsOnStack, (draft) => {
        delete draft[product.id]
      })
      onChange(updatedProductsOnStack)
      return updatedProductsOnStack;
    })
  }, [onChange])

  // const { isMobileDevice } = useUserAgent()
  // console.log('isMobileDevice', isMobileDevice);

  const [keyboardOffset, setKeyboardOffset] = useState(0)

  const onFocus = useCallback(async () => {
    const initialWindowInnerHeight = window.innerHeight;
    const containerY = containerDiv.current && containerDiv.current.getBoundingClientRect().y;
    await sleep(240)
    const diff = initialWindowInnerHeight - window.innerHeight;
    if (diff > 0 && containerY) {
      setKeyboardOffset(containerY)
      await setDragSpring({
        y: diff / 2,
      })
    }
  }, [setDragSpring])

  const onBlur = useCallback(async () => {
    await sleep(240)
    setKeyboardOffset(0)
    await setDragSpring({
      y: 0
    })
  }, [setDragSpring])

  return (
    <StyledDiv
      {...(keyboardOffset ? bind() : {})}
      style={{
        paddingTop: keyboardOffset,
        position: keyboardOffset ? 'fixed' : 'relative',
        overflow: keyboardOffset ? 'hidden' : 'visible',
        height: keyboardOffset ? '100%' : 'auto',
        zIndex: 1,
        left: 0,
        top: 0,
      }}
      className="w-100"
    >
      <GlobalStyle />
      <animated.div
        style={dragSpring}
      >
        <div
          style={{
            pointerEvents: keyboardOffset ? 'none' : 'initial'
          }}
          className="container"
        >
          <div
            style={{
              paddingBottom: 320
            }}
            // This is to get gridWidth
            ref={containerDiv}
          >
            {gridWidth > 0 && (
              <div
                ref={gridDiv}
                className="StackGrid position-relative fadeInFromRight second"
              >
                <Responsive
                  compactType={null}
                  maxRows={maxRows}
                  useCSSTransforms
                  onDragStart={(_, { i }) => {
                    setSelectedProductId(i)
                  }}
                  onResizeStart={(_, { i }) => {
                    setSelectedProductId(i)
                  }}
                  autoSize
                  margin={[10, 10]}
                  containerPadding={[10, 10]}
                  breakpoints={rglBreakpoints}
                  draggableCancel=".GridItem__removeProduct"
                  cols={{
                    lg: 12,
                    md: 12,
                    sm: 12,
                    xs: 12,
                    xxs: 12
                  }}
                  className="StackGrid__layout"
                  style={{
                    minHeight: (rowHeight + 10) * 3 + 10
                  }}
                  width={gridWidth}
                  rowHeight={rowHeight}
                  layouts={{
                    lg: gridItems,
                    md: gridItems,
                    sm: gridItems,
                    xs: gridItems,
                    xxs: gridItems,
                  }}
                  onLayoutChange={onLayoutChange}
                >

                  {
                    productsOnStackArray.map((productOnStack, i) => {

                      /**
                       * You have to map to divs.
                       * Using the 'key' property, react-grid-layout will
                       * match it up to the 'layouts' prop by gridItem.i
                       *
                       * NOTE: product.id and productOnStack.id are equal to gridItem.i
                       */

                      // @ts-ignore
                      return (
                        <div
                          key={productOnStack.id}
                          id={`GridItem-${productOnStack.id}`}
                          className={classnames('StackGrid__reactGridLayoutItem', {
                            active: selectedProductId === productOnStack.id
                          })}
                        >
                          <GridItem
                            canRemove
                            stack={stack}
                            productOnStack={productOnStack}
                            onRemove={removeProductFromStack}
                            animatedRef={animatedRef}
                          />
                        </div>
                      )
                    })
                  }
                </Responsive>
              </div>
            )}
          </div>
        </div>
      </animated.div>

      <div
        ref={drawerDiv}
        className="bottom-action-bar"
      >
        <OnLoad
          onLoad={() => {
            const div = drawerDiv.current;
            if (div) {
              div.addEventListener('touchmove', blurOn)
              return () => {
                div.removeEventListener('touchmove', blurOn)
              }
            }
          }}
        />
        <FormAndListeners
          onFocus={onFocus}
          onBlur={onBlur}
        >
          <GridItemSearch
            addProductOnStack={addProductOnStack}
            stack={stack}
          />
        </FormAndListeners>
        <div className="container-fluid d-flex flex-row align-items-center my-3 ">
          {bottomBarCTA}
        </div>
      </div>

    </StyledDiv>
  )
}

StackGrid.defaultProps = {
  onChange: () => undefined,
}

export default StackGrid;
