import React from 'react';
import { Helmet } from 'react-helmet';
import PageView from 'src/components/PageView/PageView';
import {
  Table,
  Tr
} from 'src/components/Table/Table';
import { useAnalytics } from 'src/sdk/analytics/AnalyticsContext';

const ContactUs: React.FC = () => {

  const analytics = useAnalytics();

  return (
    <>
      <Helmet defer={false}>
        <title>Contact Us</title>
        <meta
          name="description"
          content="Got questions or feedback? We want to know!"
        />
      </Helmet>
      <PageView
        onPageView={() => analytics.page({
          name: 'Contact Us'
        })}
      />
      <div className="container cy">
        <h1 className="display-2">
          Contact Us
        </h1>
        <h2 className="lead">
          Need something? Having issues?
          Let us know!
        </h2>

        <Table>
          <Tr>
            <>
              GitHub
            </>
            <>
              Talk to us on
              {' '}
              <a href="https://github.com/stackshirts/republic-of-stackshirts/discussions">
                GitHub Discussions
              </a>
            </>
          </Tr>

          <Tr>
            <>
              Twitter
            </>
            <>
              Hit us up at
              {' '}
              <a href="https://twitter.com/stackshirts">
                @stackshirts
              </a>
            </>
          </Tr>

          {/*<FormResult>*/}
          {/*  <>*/}
          {/*    Instagram*/}
          {/*  </>*/}
          {/*  <>*/}
          {/*    @stackshirts*/}
          {/*  </>*/}
          {/*</FormResult>*/}
          <Tr>
            <>
              Email
            </>
            <a href="mailto:support@stackshirts.com">
              support@stackshirts.com
            </a>
          </Tr>

        </Table>

      </div>
    </>
  );
};

export default ContactUs
