import classnames from 'classnames'
import React, { useContext } from 'react';
import {
  NavLink,
  Redirect,
  Route,
  Switch,
  useParams
} from 'react-router-dom';
import {
  OrderIcon,
  ShirtIcon,
  StackIcon
} from 'src/icons';
import { UserContext } from 'src/pages/AuthListener/User';
import DashboardOrders from 'src/pages/Private/Dashboard/DashboardOrders';
import DashboardShirts from 'src/pages/Private/Dashboard/DashboardShirts';
import DashboardStacks from 'src/pages/Private/Dashboard/DashboardStacks';
import {
  makePrivateOrdersPathname,
  makePrivateShirtsPathname,
  makePrivateStacksPathname,
  PRIVATE_ORDERS_PATH,
  PRIVATE_SHIRTS_PATH,
  PRIVATE_STACKS_PATH
} from 'src/sdk/PATHS';

import styled from 'styled-components'

const StyledDiv = styled.div`
  padding-bottom: 200px;
`

const Dashboard: React.FC = () => {

  const { data: user } = useContext(UserContext);
  const params = useParams();

  if (!user) {
    return null;
  }

  return (
    <StyledDiv
      style={{ maxWidth: 900 }}
      className="container cy"
    >
      <div className="flex-column flex-sm-row mb-4 d-none d-md-flex">
        <NavLink
          to={makePrivateStacksPathname(params)}
          exact
          className="btn btn-link d-flex flex-row align-items-center"
          activeClassName="active"
        >
          <StackIcon
            height="18px"
            className="pr-2"
          />
          {' '}
          <span className="">
            Stacks
          </span>
        </NavLink>
        <NavLink
          to={makePrivateShirtsPathname(params)}
          exact
          className="btn btn-link d-flex flex-row align-items-center"
          activeClassName="active"
        >
          <ShirtIcon
            height="18px"
            className="pr-2"
          />
          <span>
            Shirts
          </span>
        </NavLink>
        <NavLink
          to={makePrivateOrdersPathname(params)}
          exact
          className={classnames('btn btn-link d-flex flex-row align-items-center',
            !user.relationships.orders && 'disabled')}
          activeClassName="active"
        >
          <OrderIcon
            height="18px"
            className="pr-2"
          />
          <span>
            Orders
          </span>
        </NavLink>
      </div>

      <Switch>
        <Route
          exact
          component={DashboardStacks}
          path={PRIVATE_STACKS_PATH}
        />
        <Route
          exact
          component={DashboardShirts}
          path={PRIVATE_SHIRTS_PATH}
        />
        <Route
          exact
          component={DashboardOrders}
          path={PRIVATE_ORDERS_PATH}
        />
        <Route>
          <Redirect to={makePrivateStacksPathname(params)} />
        </Route>
      </Switch>

      <div className="bottom-action-bar d-block d-md-none">
        <div className="container-fluid py-3">
          <div className="row mb-2">
            <div className="col-4">
              <NavLink
                to={makePrivateStacksPathname(params)}
                exact
                className="btn btn-link d-flex flex-column align-items-center"
                activeClassName="active"
              >
                <StackIcon
                  height="18px"
                  className="mb-1"
                />
                {' '}
                <span className="">
                  Stacks
                </span>
              </NavLink>
            </div>
            <div className="col-4">
              <NavLink
                to={makePrivateShirtsPathname(params)}
                exact
                className="btn btn-link d-flex flex-column align-items-center"
                activeClassName="active"
              >
                <ShirtIcon
                  height="18px"
                  className="mb-1"
                />
                <span>
                  Shirts
                </span>
              </NavLink>
            </div>
            <div className="col-4">
              <NavLink
                to={makePrivateOrdersPathname(params)}
                exact
                className={classnames('btn btn-link d-flex flex-column align-items-center',
                  !user.relationships.orders && 'disabled')}
                activeClassName="active"
              >
                <OrderIcon
                  height="18px"
                  className="mb-1"
                />
                <span>
                  Orders
                </span>
              </NavLink>
            </div>
          </div>
        </div>
      </div>

    </StyledDiv>
  );
}

export default Dashboard
