import React from 'react';
import { StackProductSVGThemeProps } from 'src/components/StackProductSVGTheme/types';
import { defaultThemeProps } from 'src/components/StackProductSVGTheme/utils';
import { FacingUnion } from 'src/sdk/schemaTypes';

function getRadius(facing: FacingUnion) {
  if (facing === 'front') {
    return 8;
  }
  return 4;
}

const InsetShadowTheme: React.FC<StackProductSVGThemeProps> = (props) => {


  const themeProps = Object.assign(defaultThemeProps, props)

  const {
    svgProps,
    logo,
    color,
    facing,
  } = themeProps

  const radius = getRadius(facing)
  const filterId = `inset-shadow-${logo.name}`
  const filterUrl = `url(#${filterId})`

  return (
    <svg
      {...svgProps}
    >
      <defs>
        <filter
          id={filterId}
          width="200%"
          height="200%"
        >
          {/*<!-- Shadow Offset -->*/}
          <feOffset
            dx="0"
            dy="1"
          />
          {/*<!-- Shadow Blur -->*/}
          <feGaussianBlur
            stdDeviation="1"
            result="offset-blur"
          />
          {/*<!-- Invert the drop shadow to create an inner shadow -->*/}
          <feComposite
            operator="out"
            in="SourceGraphic"
            result="inverse"
          />
          {/*<!-- Color & Opacity -->*/}
          <feFlood
            floodColor="black"
            floodOpacity=".75"
            result="color"
          />
          {/*<!-- Clip color inside shadow -->*/}
          <feComposite
            operator="in"
            in="color"
            in2="inverse"
            result="shadow"
          />
          {/*<!-- Shadow Opacity -->*/}
          <feComponentTransfer
            in="shadow"
            result="shadow"
          >
            <feFuncA
              type="linear"
              slope="1"
            />
          </feComponentTransfer>
          {/*<!-- Put shadow over original object -->*/}
          <feComposite
            operator="over"
            in="shadow"
            in2="SourceGraphic"
          />
        </filter>
      </defs>
      <rect
        width="100%"
        height="100%"
        rx={radius}
        x={0}
        y={0}
        fill={color.hex}
      />
      <image
        height="68%"
        width="68%"
        x="16%"
        y="16%"
        filter={filterUrl}
        href={logo.src}
      />
    </svg>
  );
}

export default InsetShadowTheme
