import classnames from 'classnames'
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { ACTIVE_INPUTS } from 'src/components/ShirtCloseUp/helpers';
import { EditableStackAttributes } from 'src/sdk/schemaTypes';

const NameInput: React.FC = (props) => {

  const { register, formState } = useFormContext<EditableStackAttributes>()
  const {
    errors,
    touchedFields,
  } = formState

  return (
    <input
      {...register('name', { required: true })}
      autoCorrect="off"
      autoCapitalize="none"
      type="text"
      style={{
        background: 'rgba(255,255,255, .6)'
      }}
      data-activeinputid={ACTIVE_INPUTS.NAME}
      data-testid={ACTIVE_INPUTS.NAME}
      className={classnames('form-control form-control-lg', errors.name && touchedFields.name && 'is-invalid')}
      placeholder="Give it a name"
    />
  );
}

export default NameInput;
