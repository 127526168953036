import { FacingUnion } from 'src/sdk/schemaTypes';

const stackDimensions = {
  gutter: 16,
  columns: 12,
  stackItemHeight: 180,
  stackItemWidth: 100,
  gridItemMinWidth: 2,
  minRows: 2, // which means 3 rows
  maxRows: 6, // which means 7 rows

  get artworkWidth() {
    return (this.stackItemWidth * this.columns) + (this.gutter * (this.columns - 1))
  },
  get heightOfLogoInDataGrid() {
    return this.stackItemHeight * (3 / 4)
  },

  getStroke(facing: FacingUnion) {
    if (facing === 'front') {
      return 10;
    }
    return 5;
  },

  getRadius(facing: FacingUnion) {
    if (facing === 'front') {
      return 8;
    }
    return 4;
  },

}

export default stackDimensions;
