import * as React from "react";

function SvgMenuIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 36 36" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.4 18.5a1.5 1.5 0 00-1.5-1.5H8.7a1.5 1.5 0 000 3h25.2a1.5 1.5 0 001.5-1.5zM35.4 7.5A1.5 1.5 0 0033.9 6H1.5a1.5 1.5 0 000 3h32.4a1.5 1.5 0 001.5-1.5zM35.4 29.5a1.5 1.5 0 00-1.5-1.5H14.1a1.5 1.5 0 000 3h19.8a1.5 1.5 0 001.5-1.5z"
        fill="#000"
      />
    </svg>
  );
}

export default SvgMenuIcon;
