import React, {
  useContext,
  useEffect,
  useMemo,
  useRef
} from 'react';
import { Helmet } from 'react-helmet';
import {
  FormattedMessage,
  useIntl
} from 'react-intl';
import { useParams } from 'react-router-dom';
import Alert, {
  AlertLink,
  AlertMessage
} from 'src/components/Alert/Alert';
import Message from 'src/components/Message/Message';
import PageView from 'src/components/PageView/PageView';
import ShirtCard from 'src/components/ShirtCard/ShirtCard';
import usePromise from 'src/hooks/usePromise';
import {
  useResource,
  useResources,
  useResourcesArray
} from 'src/hooks/useResources';
import { UserContext } from 'src/pages/AuthListener/User';
import Page404 from 'src/pages/Page404/Page404';
import messages from 'src/pages/Private/Cart/Cart.messages';
import { useAnalytics } from 'src/sdk/analytics/AnalyticsContext';
import { AuthContext } from 'src/sdk/auth/AuthProvider';
import { useDebug } from 'src/sdk/debug/DebugContext';
import { useOrdersSDK } from 'src/sdk/orders/OrdersSDKContext';
import {
  makeContactUsPathname,
  makePrivateCartPathname,
  makePrivateOrdersPathname,
  makePrivateShirtsPathname,
  makePrivateStacksPathname,
  makeRenderShirtProofXs,
  makeSignInPathname
} from 'src/sdk/PATHS';
import settings from 'src/sdk/PRODUCT_SETTINGS';
import { OrderStateSchema } from 'src/sdk/schemas';
import {
  CartOrderType,
  ShirtType,
  StackType,
  SubmittedOrderType
} from 'src/sdk/schemaTypes';
import {
  CART_CODES,
  getCartStatus
} from 'src/sdk/users/validation';
import useStripe from 'src/useStripe';
import { baseUrl } from 'src/utils/environment';
import styled from 'styled-components'
import Invoice from './Invoice';

const StyledDiv = styled.div`

  padding-bottom: 200px;

  .Cart__disablePayment {
    opacity: .5;
    pointer-events: none;
  }

  .CheckoutForm__poweredBy {
    text-align: left;
    display: block;
  }

  .StripeElement {
    width: 100%;
    min-width: 320px;
    background-color: white;
    height: 40px;
    padding: 10px 12px;
    border-radius: 4px;
    border: 1px solid transparent;
    box-shadow: 0 1px 2px 0 #aaa;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
  }

  .StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
  }

  .StripeElement--invalid {
    border-color: #fa755a;
  }

  .StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
  }

`

const warmCacheMap = new Map()

const Cart: React.FC = () => {

  const { data: user } = useContext(UserContext);
  const cartSWR = useResource<CartOrderType>(user?.relationships.cart);
  const {
    data: cart,
  } = cartSWR
  const { auth } = useContext(AuthContext);
  const ordersSDK = useOrdersSDK();
  const params = useParams();
  const intl = useIntl();
  const stripe = useStripe();
  const analytics = useAnalytics();
  const debug = useDebug()

  const { data: orders } = useResources<SubmittedOrderType>(user?.relationships?.orders);

  const hasActiveOrder = Object.values(orders || {}).some((order) => {
    return order.meta.state !== OrderStateSchema.enum.delivered;
  })

  const shirtsSWR = useResources<ShirtType>(user?.relationships?.shirts)
  const { data: shirts, meta } = shirtsSWR;
  const shirtsInCart = useMemo(() => {
    if (!cart || !shirts) {
      return;
    }
    return Object.values(shirts).filter((shirt) => cart?.relationships.shirts?.[shirt.id])
  }, [shirts, cart])
  const initialShirtsInCart = useRef<ShirtType[]>()

  if (!initialShirtsInCart.current && meta.loaded) {
    initialShirtsInCart.current = shirtsInCart;
  }

  const stacksOnShirts = useMemo(() => {
    return shirtsInCart && shirtsInCart.map(shirt => shirt.relationships.stack)
  }, [shirtsInCart])

  const { data: stacksInCart } = useResourcesArray<StackType>(stacksOnShirts);

  const [checkingOut, callAsync] = usePromise()

  const cartId = cart?.id
  useEffect(() => {
    if (cartId && shirtsInCart && stacksInCart) {
      shirtsInCart.forEach((shirt) => {
        const stack = stacksInCart.find((stack) => stack.id === shirt.relationships.stack.id)
        if (!stack) {
          return debug.error(new Error('Unlikely issue not having a stack for a shirt in the cart'))
        }

        const pathname = makeRenderShirtProofXs({
          orderId: cartId,
          facing: 'front',
          shirtUpdatedOn: shirt.attributes.updatedOn.seconds.toString(),
          stackUpdatedOn: stack.attributes.updatedOn.seconds.toString(),
          extension: 'jpg',
          shirtId: shirt.id,
          renderType: 'proof',
        })

        if (!warmCacheMap.get(pathname)) {
          const img = new Image();
          img.src = new URL(pathname, baseUrl).href;
          warmCacheMap.set(pathname, img)
        }
      })
    }
  }, [cartId, debug, shirtsInCart, stacksInCart])

  if (!user || !shirtsInCart || !stacksInCart || !initialShirtsInCart.current || !stripe) {
    return null;
  }

  const handleSubmit = async () => {
    if (cartStatus === CART_CODES.VALID) {
      return callAsync(async () => {
        const cartOrder = await ordersSDK.getSession()
        if (!cartOrder.meta.session?.id) {
          debug.error(new Error('No cartOrder.meta.session.id on cartOrder: ' + JSON.stringify(cartOrder)))
          // TODO: Toast?
          return
        }
        await stripe.redirectToCheckout({ sessionId: cartOrder.meta.session?.id });
      })
    }
  }

  const cartStatus = getCartStatus(user, shirtsInCart, stacksInCart);

  if (cartStatus === CART_CODES.NO_STACKS) {
    return (
      <Page404 reason={intl.formatMessage(messages[CART_CODES.NO_STACKS])} />
    )
  }
  if (cartStatus === CART_CODES.NO_SHIRTS) {
    return (
      <Page404 reason={intl.formatMessage(messages[CART_CODES.NO_SHIRTS])} />
    )
  }

  const renderAlert = () => {

    if (user.attributes?.noOrderAfterPayment) {
      return (
        <Alert>
          <FormattedMessage {...messages.noOrderAfterPayment} />
          {' '}
          <AlertLink
            className="btn btn-primary"
            to={makeContactUsPathname(params)}
          >
            Contact us
          </AlertLink>
        </Alert>
      )
    }

    switch (cartStatus) {
      case CART_CODES.NO_SHIPPING_ADDRESS:
        return (
          <Alert>
            <AlertMessage>
              <FormattedMessage {...messages[cartStatus]} />
            </AlertMessage>
            <AlertLink
              className="btn btn-primary"
              to={makePrivateStacksPathname(params)}
            >
              Add yours
            </AlertLink>
          </Alert>
        )
      case CART_CODES.ISSUE_WITH_SHIPPING:
        return (
          <Alert>
            <AlertMessage>
              <FormattedMessage {...messages[cartStatus]} />
            </AlertMessage>
            <AlertLink
              className="btn btn-primary"
              to={makePrivateStacksPathname(params)}
            >
              Fix it
            </AlertLink>
          </Alert>
        )
      case CART_CODES.NO_SHIRTS_IN_CART:
        return (
          <Alert>
            <AlertMessage>
              <Message message={messages[cartStatus]} />
            </AlertMessage>
            <AlertLink
              className="btn btn-primary"
              to={makePrivateShirtsPathname(params)}
            >
              Back to dashboard
            </AlertLink>
          </Alert>
        )
      case CART_CODES.ERRORS_IN_SHIRT:
        return (
          <Alert>
            <AlertMessage>
              <FormattedMessage {...messages[cartStatus]} />
              {' '}
              <span className="text-red">
                Look for red.
              </span>
            </AlertMessage>
          </Alert>
        )
      case CART_CODES.ERRORS_IN_STACK_ATTRIBUTES:
        return (
          <Alert>
            <AlertMessage>
              <FormattedMessage {...messages[cartStatus]} />
              {' '}
              <span className="text-red">
                Look for red.
              </span>
            </AlertMessage>
          </Alert>
        )
      case CART_CODES.ERRORS_IN_STACK_PRODUCTS:
        return (
          <Alert>
            <AlertMessage>
              <FormattedMessage {...messages[cartStatus]} />
              {' '}
              <span className="text-red">
                Look for red.
              </span>
            </AlertMessage>
          </Alert>
        )
      default: {
        break;
      }
    }

    if (hasActiveOrder) {
      return (
        <Alert>
          <AlertMessage>
            <FormattedMessage {...messages.hasActiveOrder} />
          </AlertMessage>
          <AlertLink to={makePrivateOrdersPathname(params)}>
            See your orders
          </AlertLink>
        </Alert>
      )
    }

    if (!auth.authenticated) {
      return (
        <Alert>
          <AlertMessage>
            <p>
              <strong>
                You know you haven't actually created an account right?
              </strong>
            </p>
            <p>
              You can checkout as a guest,
              but creating an account makes it easier to check on your order later.
            </p>
          </AlertMessage>
          <AlertLink
            className="btn btn-outline"
            to={{
              pathname: makeSignInPathname(params),
              state: {
                redirectTo: makePrivateCartPathname(params),
              }
            }}
          >
            Sign in
          </AlertLink>
        </Alert>
      )
    }

  }

  const invoice = ordersSDK.makeInvoice({
    shirts: shirtsInCart,
    settings,
  });

  const CTA = (
    <div className="d-flex flex-row align-items-center">
      <button
        className="btn btn-primary"
        type="submit"
        disabled={cartStatus !== CART_CODES.VALID || checkingOut || user.attributes?.noOrderAfterPayment}
        onClick={handleSubmit}
      >
        Checkout
      </button>
      <small className="ml-2">
        Powered by
        <br />
        <strong>
          Stripe
        </strong>
      </small>
    </div>
  )

  return (
    <StyledDiv className="Cart">
      <Helmet defer={false}>
        <title>Cart</title>
        <meta
          name="description"
          content="This is your cart"
        />
      </Helmet>
      <PageView
        onPageView={() => analytics.page({
          name: 'Cart'
        })}
      />
      <div className="fadeInFromRight container ct">
        <h1 className="display-2">
          Your Cart
        </h1>
        <h2 className="lead">
          Some swag of your own making
        </h2>
      </div>

      <div className="bg-gray py-2rem my-2rem d-none d-md-block">
        <div className="container">
          <div className="row flex-wrap">
            <section className="col-sm-6">
              <div className="d-flex flex-row mb-1rem">
                <span
                  role="img"
                  className="pr-2"
                  aria-label="USA flag"
                >
                  🇺🇸
                </span>
                <div>
                  <div>
                    <strong>
                      Made in the USA
                    </strong>
                    <div>
                      Los Angeles or Charlotte, NC to be specific.
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-row mb-1rem">
                <span
                  role="img"
                  className="pr-2"
                  aria-label="package"
                >
                  📦
                </span>
                <div>
                  <div>
                    <strong>
                      Free shipping
                    </strong>
                    <div>
                      Well, we'll pay for it whatever it is.
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-row">
                <span
                  role="img"
                  className="pr-2"
                  aria-label="anxious grin"
                >
                  😅
                </span>
                <div>
                  <div>
                    <strong>
                      But, returns are hard
                    </strong>
                    <div>
                      We're not MoviePass. Reach out to us if we messed something up of course and we'll work it
                      out!
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="col-sm-6 d-flex flex-column justify-content-between">
              <div className="mb-1rem d-none d-sm-block">
                <div className="underlined">
                  <h5 className="uppercase-label">
                    Your Order
                  </h5>
                </div>
                <Invoice invoice={invoice} />
              </div>
              {CTA}
            </section>
          </div>
        </div>
      </div>

      <div className="container cb fadeInFromRight second">
        {renderAlert()}
        <ul className="list-group">
          {initialShirtsInCart.current.map((shirt) => {
            return (
              <li
                key={shirt.id}
                className="list-group-item overflow-hidden"
              >
                <ShirtCard
                  onCartPage
                  shirtId={shirt.id}
                />
              </li>
            );
          })}
        </ul>
      </div>
      <div className="bottom-action-bar d-block d-md-none">
        <div className="container-fluid py-4 border-bottom">
          <Invoice invoice={invoice} />
        </div>
        <div className="container-fluid py-4">
          {CTA}
        </div>
      </div>
    </StyledDiv>
  );
}

export default Cart;
