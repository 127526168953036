import {
  createContext,
  useContext
} from 'react';
import FirebaseClientSDK from 'src/sdk/FirebaseClientSDK';

export const FirebaseSDKContext = createContext<FirebaseClientSDK | null>(null)

export const useFirebaseSDK = () => {
  const firebaseSDK = useContext(FirebaseSDKContext);

  if (!firebaseSDK) {
    throw Error('You need to have a FirebaseSDKContext.Provider')
  }
  return firebaseSDK;
}
