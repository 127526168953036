import {
  ShirtType,
  UserType
} from 'src/sdk/schemaTypes';
import { FirebaseUniversalSDKType } from 'src/sdk/types';

export default class ShirtsSDK {

  firebaseSDK: FirebaseUniversalSDKType;

  constructor(firebaseSDK: FirebaseUniversalSDKType) {
    this.firebaseSDK = firebaseSDK;
  }

  makeShirt = (user: UserType, stackId: string): ShirtType => {
    return {
      id: this.firebaseSDK.makeResourceId('shirts'),
      type: 'shirts',
      attributes: {
        color: 'white',
        size: 'l',
        providerId: 'twitter.com',
        username: '',
        createdOn: this.firebaseSDK.makeTimestamp(),
        updatedOn: this.firebaseSDK.makeTimestamp(),
      },
      relationships: {
        user: {
          id: user.id,
          type: user.type,
          addedOn: this.firebaseSDK.makeTimestamp(),
        },
        stack: {
          id: stackId,
          type: 'stacks',
          addedOn: this.firebaseSDK.makeTimestamp(),
        }
      },
      meta: {}
    }
  }
}
