import {
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { CurriedFn } from 'src/sdk/types';
import { nprogressTry } from 'src/utils/nprogress';

type Ref = {
  unmounted: boolean
  count: number
}

export default function usePromise(errorHandler?: (e: Error) => void): [boolean, CurriedFn] {

  const [loading, setLoading] = useState(false)

  const ref = useRef<Ref>({
    unmounted: false,
    count: 0,
  })

  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      ref.current.unmounted = true;
    }
  }, [])

  const callAsync = useCallback((fn: () => any) => {
    ref.current.count += 1
    if (ref.current.count === 1) {
      // Then we just started loading something
      setLoading(true)
    }
    let promise = nprogressTry(fn)
    if (errorHandler) {
      promise.catch(errorHandler)
    }
    return promise.finally(() => {
      ref.current.count -= 1
      if (ref.current.count === 0 && !ref.current.unmounted) {
        // Then we just finished loading everything
        setLoading(false)
      }
    })
  }, [errorHandler])


  return [loading, callAsync]
}
