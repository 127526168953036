import preval from 'preval.macro';

export const env = process.env.REACT_APP_ENV
export const baseUrl = process.env.REACT_APP_BASE_URL
export const isBrowser = process.env.TARGET === 'web';
export const isProdOrPreview = process.env.REACT_APP_ENV === 'production';
export const isDev = process.env.REACT_APP_ENV === 'development';
export const isTest = process.env.REACT_APP_ENV === 'test';

export const packageVersion = preval`module.exports = require('../../package.json').version`
