import classnames from 'classnames'
import produce from 'immer';
import * as queryString from 'querystring';
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { Helmet } from 'react-helmet';
import { useFormContext } from 'react-hook-form';
import {
  useHistory,
  useLocation,
  useParams
} from 'react-router';
import { Link } from 'react-router-dom';
import FormAndListeners from 'src/components/FormAndListeners/FormAndListeners';
import OnLoad from 'src/components/OnLoad/OnLoad';
import PageView from 'src/components/PageView/PageView';
import { ACTIVE_INPUTS } from 'src/components/ShirtCloseUp/helpers';
import { useBreakpointPrefix } from 'src/hooks/useBreakpoint';
import useCreateShirt from 'src/hooks/useCreateShirt';
import useUserAgent from 'src/hooks/useUserAgent';
import NameInput from 'src/pages/Private/StackAndShirtAttributes/StackAttributes/NameInput/NameInput';
import StackAttributesMobileDrawer
  from 'src/pages/Private/StackAndShirtAttributes/StackAttributes/StackAttributesMobileDrawer';
import VersionInput from 'src/pages/Private/StackAndShirtAttributes/StackAttributes/VersionInput/VersionInput';
import { useAnalytics } from 'src/sdk/analytics/AnalyticsContext';
import { useMethodsSDK } from 'src/sdk/methods/MethodsSDKProvider';
import {
  makePrivateShirtAttributesPath,
  makePrivateShirtsPathname,
  makePrivateStacksPathname,
} from 'src/sdk/PATHS';
import {
  EditableStackAttributes,
  StackType,
  UserType
} from 'src/sdk/schemaTypes';
import _debounce from 'src/utils/_debounce';
import { nprogressTry } from 'src/utils/nprogress';

export const blurOn = (e: Event) => {
  if (document.activeElement instanceof HTMLInputElement) {
    e.preventDefault()
    document.activeElement.blur();
  }
};

/**
 * Because we can arrive here with an active input (from ShirtCard or StackCard)
 */
type LocationState = { editing: ACTIVE_INPUTS } | undefined

type Props = {
  stack: StackType
  user: UserType,
  setActiveInput: Dispatch<SetStateAction<ACTIVE_INPUTS | null>>
}

const StackAttributes: React.FC<Props> = (props) => {

  const {
    stack,
    setActiveInput
  } = props

  const formBag = useFormContext<EditableStackAttributes>()
  const location = useLocation<LocationState>()
  const queryParams = queryString.parse(location.search)

  /**
   * Form
   */
  const formValuesRef = useRef<EditableStackAttributes>()
  const { handleSubmit, formState, trigger, watch } = formBag
  formValuesRef.current = watch()

  useEffect(() => {
    // To trigger an immediate validation
    // NOTE: you have to use trigger, not formBag.trigger
    void trigger()
  }, [trigger])

  const {
    isSubmitting,
    isValid,
    touchedFields,
    errors,
  } = formState;
  /**
   * End form
   */

  const methodsSDK = useMethodsSDK();
  const debouncedSaveStack = useMemo(() => {
    return _debounce((stack) => {
      return nprogressTry(async () => {
        const updatedStack = produce<StackType>(stack, (draft) => {
          Object.assign(draft.attributes, formValuesRef.current);
        })
        await methodsSDK.upsertResource(updatedStack)
      });
    }, 400)
  }, [methodsSDK])

  const history = useHistory();
  const params = useParams<{ stackId: string, userId: string }>();
  const analytics = useAnalytics()
  const [nameHelpText, setNameHelpText] = useState(false)
  const [versionHelpText, setVersionHelpText] = useState(false)
  const [creatingShirt, createShirtOnStack] = useCreateShirt()
  const { isMobileDevice } = useUserAgent();
  const containerDiv = useRef<HTMLDivElement>(null)

  const focusOnActiveInput = useCallback((key) => {
    if (isMobileDevice) {
      return
    }
    const el = document.getElementById(key);
    if (el) {
      el.focus()
    }
  }, [isMobileDevice])

  const focusOnStackName = useCallback(() => setActiveInput(ACTIVE_INPUTS.NAME), [setActiveInput])
  const focusOnStackVersion = useCallback(() => setActiveInput(ACTIVE_INPUTS.VERSION), [setActiveInput])
  const isMedium = useBreakpointPrefix('md');

  const renderCTA = () => {

    const shirts = Object.values(stack.relationships.shirts)
    let CTA: React.ReactNode
    if (queryParams.shirtId) {
      CTA = (
        <button
          type="button"
          onClick={() => {
            history.push({
              pathname: makePrivateShirtsPathname(params),
            });
          }}
          disabled={isSubmitting || !isValid}
          className="btn btn-primary center w-100 w-md-auto"
        >
          Back to shirt
        </button>
      )
    }
    else if (shirts.length > 1) {
      CTA = (
        <button
          type="button"
          onClick={() => {
            history.push({
              pathname: makePrivateShirtsPathname(params),
            });
          }}
          disabled={isSubmitting || !isValid}
          className="btn btn-primary center w-100 w-md-auto"
        >
          See your shirts
        </button>
      )
    }
    else if (shirts.length === 1) {
      CTA = (
        <button
          type="button"
          onClick={() => {
            history.push({
              pathname: makePrivateShirtAttributesPath({
                ...params,
                shirtId: shirts[0].id
              }),
            });
          }}
          disabled={isSubmitting || !isValid}
          className="btn btn-primary center w-100 w-md-auto"
        >
          See your shirt with this stack
        </button>
      )
    }
    else {
      CTA = (
        <button
          type="button"
          onClick={() => createShirtOnStack(stack)}
          disabled={isSubmitting || !isValid || creatingShirt}
          className="btn btn-primary center w-100 w-md-auto"
        >
          <span className="d-none d-sm-block">
            Customize your shirt
          </span>
          <span className="d-block d-sm-none">
            Custom shirt
          </span>
        </button>
      )
    }


    return (
      <div className="row">
        <div className="col-12 mb-2 mb-md-0">
          {CTA}
        </div>
        {!isMedium && (
          <div className="col-12">
            <Link
              to={makePrivateStacksPathname(params)}
              className="btn btn-default btn-block"
            >
              See your stacks
            </Link>
          </div>
        )}
        {/*{!dirty && !isValid && (*/}
        {/*  <small className="ml-3">*/}
        {/*    <FormattedMessage {...messages[errorCode || 'valid']} />*/}
        {/*  </small>*/}
        {/*)}*/}
      </div>
    )
  }

  return (
    <FormAndListeners
      className="StackAttributes__form h-100 w-100"
      onFocus={(e) => {
        const activeInputId = e.target.dataset.activeinputid
        if (Object.values(ACTIVE_INPUTS).includes(activeInputId)) {
          setActiveInput(activeInputId)
        }
      }}
      onBlur={(e) => {
        console.log('onBlur in stack')
        setActiveInput(null)
      }}
      onChange={(e) => {
        debouncedSaveStack(stack)
      }}
      onSubmit={handleSubmit(() => void 0)}
    >
      <Helmet defer={false}>
        <title>Customize your stack</title>
        <meta
          name="description"
          content="Customize your stack"
        />
      </Helmet>
      <PageView
        pageKey={stack.id}
        onPageView={() => analytics.page({
          name: 'Stack - Attributes',
          properties: {
            stackId: stack.id
          }
        })}
      />


      {isMedium ? (
        <div className="cy offset-md-6 col-md-6 col-lg-5 d-none d-md-block">

          <OnLoad
            onLoad={() => {
              focusOnActiveInput(location.state?.editing)
              const div = containerDiv.current;
              if (div) {
                div.addEventListener('touchmove', blurOn)
                return () => {
                  div.removeEventListener('touchmove', blurOn)
                }
              }
            }}
          />
          <div
            style={{
              maxWidth: 500,
            }}
            className="container-fluid"
          >
            <h1 className="display-3 mb-4">
              About your stack
            </h1>
            <div className="form-group">
              <label className="form-label">
                Stack name
              </label>
              <NameInput />
              <small
                onClick={() => {
                  focusOnStackName()
                  if (!nameHelpText) {
                    setNameHelpText(true);
                  }
                }}
                className="href"
              >
                What's this?
              </small>
              {nameHelpText && (
                <small>
                  {' '}
                  What’s the product? Or website, or team name, or version name?
                </small>
              )}
              {errors.name && touchedFields.name && (
                <small className={classnames('invalid-feedback', nameHelpText ? 'd-block' : 'd-inline')}>
                  {' '}
                  {nameHelpText ? 'And' : 'Well'}
                  , it's required
                </small>
              )}
            </div>

            <div className="form-group">
              <label className="form-label d-flex flex-row justify-content-between">
                Version
              </label>
              <VersionInput />
              <small
                onClick={() => {
                  focusOnStackVersion()
                  if (!versionHelpText) {
                    setVersionHelpText(true);
                  }
                }}
                className="href"
              >
                What's this?
              </small>
              {versionHelpText && (
                <small>
                  {' '}
                  Like the new macOS is <a href="https://www.apple.com/macos/big-sur-preview">
                  v11 Big Sur
                </a>. Play around with it.
                </small>
              )}
              {errors.version && touchedFields.version && (
                <small className={classnames('invalid-feedback', versionHelpText ? 'd-block' : 'd-inline')}>
                  {' '}
                  It's required, btw.
                </small>
              )}
            </div>
            {renderCTA()}
          </div>
        </div>
      ) : (
        <StackAttributesMobileDrawer
          ref={containerDiv}
          initialTab={location.state?.editing}
          bottomBarCTA={renderCTA()}
          setActiveInput={setActiveInput}
        />
      )}
    </FormAndListeners>
  );
}

export default StackAttributes;
