import React from 'react';
import GridItems from 'src/components/StackSVGs/GridItems';
import { svgHeightOfStack } from 'src/components/StackSVGs/helpers';
import {
  FacingUnion,
  StackType
} from 'src/sdk/schemaTypes';
import stackDimensions from 'src/stackDimensions';

const StackOnlySVG: React.FC<{ stack: StackType, facing: FacingUnion }> = (props) => {

  const {
    stack,
    facing
  } = props;

  const stackHeight = svgHeightOfStack(stack);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMinYMin"
      style={{
        height: 'auto',
      }}
      viewBox={`0 0 ${stackDimensions.artworkWidth} ${stackHeight}`}
    >
      <GridItems
        stack={stack}
        facing={facing}
      />
    </svg>

  );
}

export default StackOnlySVG
