import { Location } from 'history';
import { useLocation } from 'react-router';
import React, { createContext, useEffect, useRef, useState } from 'react';

type State = Location[];

const initialState: State = [];

export const LocationLogContext = createContext(initialState);

const LocationLog: React.FC = (props) => {

  const {
    children,
  } = props;

  const location = useLocation()

  const [locationLog, setLocationLog] = useState<State>(initialState);
  const locationRef = useRef(location);

  useEffect(() => {
    if (location) {
      if (location.key !== locationRef.current.key) {
        setLocationLog((locationLog) => [locationRef.current, ...locationLog])
        locationRef.current = location;
      }
    }
  }, [location]);

  return (
    <LocationLogContext.Provider value={locationLog}>
      {children}
    </LocationLogContext.Provider>
  );
}

export default LocationLog;
