import Menu from 'src/pages/Menu/Menu';
import Page404 from 'src/pages/Page404/Page404';
import SignedIn from 'src/pages/SignIn/SignedIn';
import {
  CONTACT_US_PATH,
  HOME_PATH,
  MENU_PATH,
  PRIVATE_PATH,
  SIGN_IN_PATH,
  SIGNED_IN_PATH
} from 'src/sdk/PATHS';
import { Route, Switch } from 'react-router';
import ContactUs from 'src/pages/ContactUs/ContactUs';
import Home from '../Home/Home';
import Private from 'src/pages/Private/Private';
import React from 'react';
import SignIn from 'src/pages/SignIn/SignIn';

const AppRoutes: React.FC = (props) => {

  const {
    children,
  } = props;

  return (
    <Switch>
      <Route
        exact
        path="/children"
        render={() => children}
      />
      {/* ^^^ This only exists for storybook */}

      <Route
        path={HOME_PATH}
        exact
        component={Home}
      />
      <Route
        path={MENU_PATH}
        exact
        component={Menu}
      />
      <Route
        path={CONTACT_US_PATH}
        exact
        component={ContactUs}
      />
      <Route
        path={SIGN_IN_PATH}
        exact
        component={SignIn}
      />
      <Route
        path={PRIVATE_PATH}
        component={Private}
      />
      <Route
        path={SIGNED_IN_PATH}
        component={SignedIn}
      />
      <Route
        path={'/'}
        component={Page404}
      />
    </Switch>
  );
}


AppRoutes.propTypes = {};
AppRoutes.defaultProps = {};

export default AppRoutes;
