// import updateCoupon from './updateCoupon'
import produce from 'immer';
import {
  GridItemType,
  ProductOnStackType,
  ProductType,
  ShirtType,
  StackType,
  UserType
} from 'src/sdk/schemaTypes';
import { FirebaseUniversalSDKType } from 'src/sdk/types';

export default class StacksSDK {

  firebaseSDK: FirebaseUniversalSDKType;

  constructor(firebaseSDK: FirebaseUniversalSDKType) {
    this.firebaseSDK = firebaseSDK;
  }

  addShirtToStack(shirt: ShirtType, stack: StackType) {
    return produce<StackType>(stack, (draft) => {
      draft.relationships.shirts[shirt.id] = {
        id: shirt.id,
        type: 'shirts',
        addedOn: this.firebaseSDK.makeTimestamp()
      }
    })
  };

  makeRelationshipToProduct(gridItem: GridItemType, product: ProductType): ProductOnStackType {

    return {
      id: product.id,
      type: 'products',
      color: product.attributes.colors[0],
      logo: product.attributes.logos[0],
      gridItem,
      addedOn: this.firebaseSDK.makeTimestamp()
    };
  };

  makeStackAndUpdateUser(user: UserType): [UserType, StackType] {

    const {
      id,
      type,
    } = user;

    const newStack: StackType = {
      id: this.firebaseSDK.makeResourceId('stacks'),
      type: 'stacks',
      attributes: {
        createdOn: this.firebaseSDK.makeTimestamp(),
        updatedOn: this.firebaseSDK.makeTimestamp(),
        name: '',
        version: '',
        theme: 'whiteBorder',
      },
      relationships: {
        products: {},
        shirts: {},
        orders: {},
        user: {
          id,
          type,
          addedOn: this.firebaseSDK.makeTimestamp(),
        }
      },
      meta: {}
    };

    const updatedUser = produce<UserType>(user, (_user) => {
      _user.relationships.stacks[newStack.id] = {
        id: newStack.id,
        type: 'stacks',
        addedOn: this.firebaseSDK.makeTimestamp(),
      }
    })

    return [updatedUser, newStack];

  }
}
