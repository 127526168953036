import { Helmet } from 'react-helmet';
import { useAnalytics } from 'src/sdk/analytics/AnalyticsContext';
import PageView from 'src/components/PageView/PageView';
import React from 'react';
import styled from 'styled-components'

const count = 16;

const spans: React.ReactNode[] = []
for (let i = 0; i < count; i++) {
  spans.push(<span
    key={i}
    className="loading-bit"
  />)
}

let css = '';
for (let i = 1; i <= count; i++) {
  css += `.loading-bit:nth-child(${i}) {animation-delay: ${i * .05}s;}`
}

const StyledDiv = styled.div`
  .loading-line {
    display: inline-block;
    height: 1px;
    position: relative;
  }

  .loading-bit {
    animation: 2s ease infinite animWidth;
    background-color: white;
    border-radius: 0;
    display: inline-block;
    height: 100%;
    opacity: 0;
    transform: scale(1);
    vertical-align: top;
    width: 4px;
  }

  ${css} @keyframes animWidth {
    0% {
      transform: scale(1);
    }
    50% {
      background-color: black;
      opacity: 1;
      transform: scale(3);
    }
    100% {
      transform: scale(1);
    }
  }
`

const Processing: React.FC = () => {

  const analytics = useAnalytics();

  return (
    <>
      <Helmet defer={false}>
        <title>Processing</title>
        <meta
          name="description"
          content="Processing your order"
        />
      </Helmet>
      <PageView
        onPageView={() => analytics.page({
          name: 'Processing'
        })}
      />
      <StyledDiv className="container text-center vh-100 d-flex flex-column justify-content-center">
        <div>
          <div className="display-p fadeInFromRight">
            Processing your order
          </div>
          <div className="loading-line fadeInFromRight second">
            {spans}
          </div>

        </div>

      </StyledDiv>
    </>
  );
}

export default Processing;
