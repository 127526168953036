import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import {
  Link,
  useHistory,
  useParams
} from 'react-router-dom';
import { navHeight } from 'src/components/Navbar/Navbar';
import PageView from 'src/components/PageView/PageView';
import {
  initialAuth
} from 'src/pages/AuthListener/AuthListener';
import { UserContext } from 'src/pages/AuthListener/User';
import { useAnalytics } from 'src/sdk/analytics/AnalyticsContext';
import { AuthContext } from 'src/sdk/auth/AuthProvider';
import { useFirebaseSDK } from 'src/sdk/firebase/FirebaseSDKContext';
import {
  makeContactUsPathname,
  makeHomePathname,
  makePrivateCartPathname,
  makePrivateStacksPathname,
  makeSignInPathname
} from 'src/sdk/PATHS';
import breakpoints from 'src/styles/breakpoints';
import { nprogressTry } from 'src/utils/nprogress';
import styled from 'styled-components'

const StyledDiv = styled.div`
  min-height: calc(100vh - ${navHeight.xs * 2}px);

  @media (min-width: ${breakpoints.md}px) {
    min-height: calc(100vh - ${navHeight.md * 2}px);
  }
`

const Menu: React.FC = (props) => {

  const analytics = useAnalytics();
  const params = useParams();
  const { data: user } = useContext(UserContext)
  const { auth, setAuth } = useContext(AuthContext);
  const history = useHistory();
  const firebaseSDK = useFirebaseSDK();

  return (
    <>
      <Helmet defer={false}>
        <title>Menu</title>
      </Helmet>
      <PageView
        onPageView={() => {
          analytics.page({
            name: 'Menu',
          })
        }}
      />
      <StyledDiv className="container center">
        <div>
          <h1 className="h2 monospace">
            Where to?
          </h1>
          <ul>
            {user && (
              <>
                <li>
                  <Link
                    to={makePrivateStacksPathname({
                      ...params,
                      userId: user.id
                    })}
                  >
                    Dashboard
                  </Link>
                </li>
                <li>
                  <Link
                    to={makePrivateCartPathname({
                      ...params,
                      userId: user.id
                    })}
                  >
                    Cart
                  </Link>
                </li>
              </>
            )}

            <li>
              <Link to={makeContactUsPathname(params)}>
                Contact us
              </Link>
            </li>
            <li>
              <Link to={makeHomePathname(params)}>
                Home
              </Link>
            </li>

            {auth.authenticated ? (
              <li>
                <Link
                  to={makeHomePathname(params)}
                  onClick={async () => {
                    return nprogressTry(async () => {
                      history.push(makeHomePathname());
                      setAuth(initialAuth); // Then we logged out!
                      await firebaseSDK.firebaseAuth.signOut()
                    })
                  }}
                >
                  Logout
                </Link>
              </li>
            ) : (
              <li>
                <Link
                  to={makeSignInPathname()}
                >
                  Sign in
                </Link>
              </li>
            )}

          </ul>
        </div>
      </StyledDiv>
    </>
  );
}

export default Menu;
