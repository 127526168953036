import { Location } from 'history';
import React, { Component } from 'react';
import Page404 from 'src/pages/Page404/Page404';
import { DebugType } from 'src/sdk/types';

interface Props {
  location: Location,
  debug: DebugType,
}

interface State {
  pathname: string | null;
  error: Error | null;
}

export default class ErrorBoundary extends Component<Props, State> {

  state = {
    pathname: null,
    error: null,
  };

  static getDerivedStateFromProps(props: Props, state: State) {

    const {
      location: {
        pathname
      }
    } = props;

    if (pathname !== state.pathname) {
      return {
        pathname: null,
        error: null,
      };
    }
    return null; // Do not update state
  }

  componentDidCatch(error: Error) {
    const {
      location,
      debug,
    } = this.props;
    debug.error(error);
    this.setState({
      pathname: location.pathname,
      error,
    });
  }

  render() {

    const {
      children,
    } = this.props;

    const {
      error,
    } = this.state;

    if (error) {
      return <Page404 />;
    }

    return (
      <>
        {children}
      </>
    );
  }
}

