import { createContext, useContext } from 'react';
import * as Sentry from '@sentry/browser'

export const SentryContext = createContext<typeof Sentry | null>(null)

export const useSentry = () => {
  const sentry = useContext(SentryContext);

  return sentry;
}
