import { match } from 'path-to-regexp';
import * as pathToRegexp from 'path-to-regexp'
import { FacingUnion } from 'src/sdk/schemaTypes';
import qs from 'src/utils/qs';

// TODO: Move to sdk

type Locale = {
  locale?: 'en' | 'en-ca' | 'fr'
}
export const LOCALE_PATH = '/:locale(en|en-ca|fr)?'

export const HOME_PATH = `${LOCALE_PATH}/`
export const makeHomePathname = pathToRegexp.compile<Locale>(HOME_PATH)

export const SIGN_IN_PATH = `${LOCALE_PATH}/sign-in`
export const SIGNED_IN_PATH = `${LOCALE_PATH}/signed-in`

export const MENU_PATH = `${LOCALE_PATH}/menu`
export const makeMenuPathname = pathToRegexp.compile(MENU_PATH)

export const CONTACT_US_PATH = `${LOCALE_PATH}/contact-us`
export const ABOUT_US_PATH = `${LOCALE_PATH}/about-us`
export const CONDITIONS_PATH = `${LOCALE_PATH}/conditions`
export const PRIVACY_PATH = `${LOCALE_PATH}/privacy`

type Private = Locale & {
  userId: string
}

export const PRIVATE_PATH = `${LOCALE_PATH}/:userId/private`
export const PRIVATE_STACK_PATH = `${PRIVATE_PATH}/stacks/:stackId`
export const PRIVATE_STACK_INTRO_PATH = `${PRIVATE_STACK_PATH}/intro/:stepId?`

export const PRIVATE_STACK_ATTRIBUTES_PATH = `${PRIVATE_STACK_PATH}/attributes`
export const makePrivateStackAttributesPathname = pathToRegexp.compile<Private & {
  stackId: string
}>(PRIVATE_STACK_ATTRIBUTES_PATH)

export const PRIVATE_SHIRT_ATTRIBUTES_PATH = `${PRIVATE_STACK_ATTRIBUTES_PATH}/shirts/:shirtId/attributes`
export const makePrivateShirtAttributesPath = pathToRegexp.compile<Private & {
  stackId: string,
  shirtId: string,
}>(PRIVATE_SHIRT_ATTRIBUTES_PATH)

export const PRIVATE_DASHBOARD_PATH = `${PRIVATE_PATH}/dashboard`
export const PRIVATE_STACKS_PATH = `${PRIVATE_DASHBOARD_PATH}/stacks`
export const PRIVATE_SHIRTS_PATH = `${PRIVATE_DASHBOARD_PATH}/shirts`
export const PRIVATE_ORDERS_PATH = `${PRIVATE_DASHBOARD_PATH}/orders`
export const PRIVATE_ORDER_PATH = `${PRIVATE_DASHBOARD_PATH}/orders/:orderId`

export const PRIVATE_CART_PATH = `${PRIVATE_PATH}/cart`
export const PRIVATE_CONFIRMATION_PATH = `${PRIVATE_PATH}/confirmation`


export const makeContactUsPathname = pathToRegexp.compile(CONTACT_US_PATH)
export const makeSignInPathname = pathToRegexp.compile(SIGN_IN_PATH)
export const makeSignedInPathname = pathToRegexp.compile(SIGNED_IN_PATH)

export const makePrivateStackPathname = pathToRegexp.compile(PRIVATE_STACK_PATH)
export const makePrivateStackIntroPathname = pathToRegexp.compile(PRIVATE_STACK_INTRO_PATH)

export const makePrivateDashboardPathname = pathToRegexp.compile(PRIVATE_DASHBOARD_PATH)
export const makePrivateStacksPathname = pathToRegexp.compile(PRIVATE_STACKS_PATH)
export const makePrivateShirtsPathname = pathToRegexp.compile(PRIVATE_SHIRTS_PATH)
export const makePrivateOrdersPathname = pathToRegexp.compile(PRIVATE_ORDERS_PATH)
export const makePrivateOrderPathname = pathToRegexp.compile(PRIVATE_ORDER_PATH)

export const makePrivateCartPathname = pathToRegexp.compile(PRIVATE_CART_PATH)

// 👇 Only use this to MAKE a path for Stripe. You cannot use query params in the "path" attribute of Route components
export const PRIVATE_CONFIRMATION_PATH_WITH_SEARCH = `${PRIVATE_CONFIRMATION_PATH}\\?sessionId=:sessionId`
export const makeCheckoutSuccessUrlPathname: (p: { sessionId: string, userId: string }) => string = pathToRegexp.compile(PRIVATE_CONFIRMATION_PATH_WITH_SEARCH)


// 👇 API paths!
export const API_ORDERS_MAKE_CHECKOUT_URL_PATH = '/api/orders.makeCheckoutUrl'


export const API_RENDER_PATH = '/api/render'
export const API_RENDER_SHIRT_ID_PATH = '/api/render/:shirtId\\.:extension\\?(.*)'
export const matchApiRenderShirtIdPath = match(API_RENDER_SHIRT_ID_PATH)

export const API_RENDER_SCREENSHOT_PATH = '/api/screenshot'
export const API_PRINTFUL_DRAFT_PATH = '/api/printful.draft'
export const API_SYNC_PRINTFUL_ORDERS_PATH = '/api/printful.syncOrders'
export const API_WEBHOOK_PRINTFUL_PATH = '/api/webhook.printful'
export const API_WEBHOOK_ON_STRIPE_CHECKOUT_PATH = '/api/webhook.onStripeCheckout'

export type ExtensionType = 'png' | 'svg' | 'jpg' | 'html'
export type RenderShirtPathnameParams = {
  orderId: string,
  shirtId: string,
  facing: FacingUnion,
  shirtUpdatedOn: string,
  stackUpdatedOn: string,
  extension: ExtensionType,
  renderType: 'proof' | 'artwork',
}

export const makeRenderShirtProofXs = (props: RenderShirtPathnameParams) => {
  const {
    shirtId,
    extension,
    ...otherProps
  } = props
  return `${API_RENDER_PATH}/${props.shirtId}.${props.extension}?${qs.stringify({
    ...otherProps,
    width: 300,
    height: 300
  })}`
}

export const makeRenderShirtArtwork = (props: RenderShirtPathnameParams) => {
  const {
    shirtId,
    extension,
    ...otherProps
  } = props
  return `${API_RENDER_PATH}/${props.shirtId}.${props.extension}?${qs.stringify(otherProps)}`
}
