import { FacingSchema } from 'src/sdk/schemas';
import { FacingUnion } from 'src/sdk/schemaTypes';

export type ScaleType = 1 | 1.5 | 2 | 2.5;
export const SCALE_VALUES = [1, 1.5, 2, 2.5] as const;

export enum ACTIVE_INPUTS {
  NAME = 'name',
  VERSION = 'version',
  USERNAME = 'username',
  PROVIDER = 'username',
  SHIRT_COLOR = 'size',
  SHIRT_SIZE = 'color',
}

export type ActiveInputType = ACTIVE_INPUTS | null;


export const getActiveInputFacing = (activeInput?: ActiveInputType): FacingUnion | undefined => {
  switch (activeInput) {
    case ACTIVE_INPUTS.USERNAME:
    case ACTIVE_INPUTS.VERSION: {
      return FacingSchema.enum.back;
    }
    default: {
      return;
    }
  }
}

