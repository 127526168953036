import { createContext, useContext } from 'react';
import OrdersSDK from 'src/sdk/orders/OrdersSDK';

export const OrdersSDKContext = createContext<OrdersSDK | null>(null)

export const useOrdersSDK = () => {
  const ordersSDK = useContext(OrdersSDKContext);

  if (!ordersSDK) {
    throw Error('You need to have a OrdersSDKContext.Provider')
  }
  return ordersSDK;
}
