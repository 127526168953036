import { Location } from 'history';
import React, { useContext } from 'react';

import { X } from 'react-feather';
import { useRouteMatch } from 'react-router';
import {
  NavLink,
  Route,
  useHistory,
  useLocation,
  useParams
} from 'react-router-dom';
import { useResource } from 'src/hooks/useResources';
import {
  CartIcon,
  DashboardIcon,
  Logo,
  MenuIcon
} from 'src/icons';
import { UserContext } from 'src/pages/AuthListener/User';
import { AuthContext } from 'src/sdk/auth/AuthProvider';
import {
  makeHomePathname,
  makeMenuPathname,
  makePrivateCartPathname,
  makePrivateDashboardPathname,
  makeSignInPathname,
  MENU_PATH,
  SIGN_IN_PATH,
} from 'src/sdk/PATHS';
import { CartOrderType } from 'src/sdk/schemaTypes';
import breakpoints from 'src/styles/breakpoints';
import styled from 'styled-components';

export const navHeight = {
  xs: 90,
  md: 90,
}

const StyledNav = styled.nav`
  > * {
    z-index: 100;
    position: relative;
  }

  height: ${navHeight.xs}px;
  @media (min-width: ${breakpoints.md}px) {
    height: ${navHeight.md}px;
  }

  .Navbar__brandName {
    font-size: 18px;
    padding-left: 8px;
    color: #111;
  }

  .Navbar__brandName {
    font-size: 25px;
  }
  
  .Navbar__cartBadge {
    background: #0bf;
    border-radius: 50%;
    height: 16px;
    width: 16px;
    font-size: 11px;
    color: white;
    position: absolute;
    top: 0px;
    left: 22px;
  }
`;

type LocationState = {
  from?: Location
}


const Navbar: React.FC = () => {

  const history = useHistory();
  const params = useParams();
  const { auth } = useContext(AuthContext);
  const userSWR = useContext(UserContext);
  const location = useLocation<LocationState | undefined>()
  const { data: user } = userSWR;

  const {
    data: cartOrder
  } = useResource<CartOrderType>(user?.relationships.cart)
  const cartCount = Object.values(cartOrder?.relationships.shirts || {}).length

  const hasAtLeastOneShirt = Object.keys(user?.relationships?.shirts || {}).length > 0

  const onMenu = useRouteMatch(MENU_PATH)
  const handleMenuClick = () => {
    if (!onMenu) {
      history.push({
        pathname: makeMenuPathname(params),
        state: {
          from: location,
        }
      })
    }
    else if (location.state?.from) {
      history.goBack()
    }
    else {
      history.push({
        pathname: makeHomePathname(params)
      })
    }
  }

  const onSignIn = useRouteMatch(SIGN_IN_PATH)
  const renderAuth = () => {
    if (onSignIn) {
      return null
    }
    if (!auth.authenticated) {
      return (
        <NavLink
          activeClassName="d-none"
          className="btn btn-default fadeInFromRight d-none d-md-block"
          to={makeSignInPathname()}
        >
          Sign in
        </NavLink>
      )
    }
  }

  return (
    <>
      <StyledNav>
        <NavLink
          className="p-4 float-left navbar-brand d-flex flex-row align-items-center"
          activeClassName="disabled"
          exact
          to={makeHomePathname(params)}
        >
          <Logo
            height="36px"
            className="d-inline-block fadeInFromRight"
          />
          {/* TODO: Fade this out */}
          <Route
            path={makeHomePathname(params)}
            exact
            render={() => {
              return (
                <span
                  className="fadeInFromRight second ml-2 align-middle d-none d-md-inline-block Navbar__brandName h-italic"
                >
                  Stackshirts
                </span>
              );
            }}
          />
        </NavLink>
        <div className="p-4 d-flex flex-row justify-content-end">
          {user && (
            <>
              {hasAtLeastOneShirt && (
                <>
                  <NavLink
                    to={makePrivateCartPathname({
                      userId: user.id
                    })}
                    className="btn btn-link fadeInFromRight third"
                    activeClassName="active"
                  >
                    {cartCount > 0 && (
                      <div className="Navbar__cartBadge">
                        {cartCount}
                      </div>
                    )}
                    <CartIcon height="18px" />
                    <span className="pl-2">
                      Cart
                    </span>
                  </NavLink>
                  <NavLink
                    to={makePrivateDashboardPathname({ userId: user.id })}
                    className="mr-3 btn btn-link fadeInFromRight second d-none d-md-block "
                    activeClassName="active"
                  >
                    <DashboardIcon height="18px" />
                    <span className="pl-2">
                      Dashboard
                    </span>
                  </NavLink>
                </>
              )}
              {renderAuth()}
              <button
                className="btn btn-default ml-2"
                onClick={handleMenuClick}
              >
                {onMenu ? <X size="24px" /> : <MenuIcon
                  height="24px"
                  width="24px"
                />}
              </button>
            </>
          )}
        </div>
      </StyledNav>
    </>
  );
}

export default Navbar;
