import dayjs from 'dayjs';
import { TimestampType } from 'src/sdk/schemaTypes';

const _dayjs = (on: TimestampType) => {
  if ('seconds' in on) {
    return dayjs(on.seconds! * 1000);
  }
  return {
    format: (s: string) => '-----'
  }
}

export default _dayjs;
