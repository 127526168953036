import firebase from 'firebase';
import admin from 'firebase-admin';
import AppError from 'src/sdk/AppError';
import {
  FacingUnion,
  ResourceType,
  ShirtColorsUnion,
  TimestampType
} from 'src/sdk/schemaTypes';

// TODO: Move to schemas, rename to ResourceTypesUnion and then replace all instances of ResourceTypesUnion.<> to ResourceTypesUnion.enum.<>
export enum RESOURCE_TYPES {
  STACKS = 'stacks',
  USERS = 'users',
  PRODUCTS = 'products',
  SHIRTS = 'shirts',
  ORDERS = 'orders',
}

export interface FirebaseUniversalSDKType {
  firebaseDb: firebase.firestore.Firestore | admin.firestore.Firestore;
  firebaseAuth: firebase.auth.Auth | admin.auth.Auth;
  makeResourceId: (type: string) => string;
  makeTimestamp: () => TimestampType;
}

export interface DebugType {
  log: (...args: [string, ...any[]]) => void;
  warn: (...args: any[]) => void;
  error: (e: AppError, extra?: any) => void;
}

export interface FirebaseDocResourceType<T> {
  exists: boolean,
  data: () => T,
}

export interface FirebaseType {
  makeTimestamp: () => any;
  firebaseDb: any;
}

export interface CurriedFn {
  <T extends () => any>(cb: T): Promise<ReturnType<T>>
}

export type PickResourceIdentifier<T extends ResourceType> = Pick<T, 'id' | 'type'>
export type ShirtImagesMapType = Record<FacingUnion, Record<ShirtColorsUnion, string>>

export interface AuthType {
  initialized: boolean;
  authenticated: boolean;
  uid: string | null;
}
