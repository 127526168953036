import * as React from "react";

function SvgShirtIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 36 32" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M12.04 1.882H24v2.73c-.382.393-.86.743-1.414 1.035h-8.172C12.942 4.871 12 3.683 12 2.353c0-.16.013-.316.04-.47z"
          fill="#C4C4C4"
        />
        <path
          d="M12.35 2.196H9.061c-4.378 1.528-7.14 5.637-7.889 8.69h33.704c-.749-3.053-3.678-7.162-8.056-8.69h-2.36c-.853 2.622-2.816 4.363-6.024 4.363-3.209 0-5.233-1.74-6.086-4.363z"
          fill="#FFF"
          fillRule="nonzero"
        />
        <path
          d="M12.395.814c.492 0 .912.337.988.795.348 2.09 2.485 3.691 5.053 3.691s4.704-1.602 5.053-3.69c.076-.459.495-.796.988-.796h1.425c.116 0 .23.019.34.056 5.625 1.911 9.409 5.667 9.755 9.994.044.547-.415 1.012-.997 1.012H1c-.582 0-1.04-.465-.997-1.012C.349 6.537 4.133 2.781 9.759.87c.108-.037.223-.056.34-.056h2.296zm-.793 1.882h-1.327C5.897 4.225 2.91 6.941 2.161 9.994H33.84c-.75-3.053-3.736-5.77-8.114-7.298h-.455c-.853 2.623-3.626 4.486-6.834 4.486-3.209 0-5.981-1.863-6.834-4.486z"
          fill="#000"
          fillRule="nonzero"
        />
        <path fill="#FFF" d="M8.057 29.43V8.38h19.566V29.43z" />
        <path
          d="M26.623 29.53V9.419c0-.52.448-.941 1-.941s1 .42 1 .94v21.053c0 .52-.448.941-1 .941H8.057c-.552 0-1-.421-1-.941V9.419c0-.52.448-.941 1-.941s1 .42 1 .94V29.53h17.566zM7.057 9.419c0-.52.448-.941 1-.941s1 .42 1 .94v21.053c0 .52-.448.941-1 .941s-1-.421-1-.941V9.419zM17.564 2.696c-.552 0-1-.421-1-.94 0-.52.448-.942 1-.942h6.974c.553 0 1 .421 1 .941s-.447.941-1 .941h-6.974z"
          fill="#000"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}

export default SvgShirtIcon;
