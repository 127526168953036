import * as Sentry from '@sentry/browser'
import {
  isProdOrPreview,
  packageVersion,
} from 'src/utils/environment';

export default function makeSentry(): typeof Sentry | null {

  if (!isProdOrPreview) {
    return null
  }

  Sentry
    .init({
      dsn: 'https://70504bd8a9cc4a5caa658ee8698f0670@o114551.ingest.sentry.io/252076',
      maxBreadcrumbs: 10,
      release: packageVersion,
      environment: process.env.REACT_APP_ENV || 'development',
      // integrations: integrations => {
      //   const updatedIntegrations = integrations.filter(integration => {
      //     return integration.name !== 'CaptureConsole'
      //       && integration.name !== 'Breadcrumbs';
      //   });
      //
      //   return [
      //     ...updatedIntegrations,
      //     new Sentry.Integrations.Breadcrumbs({ console: false })
      //   ];
      // }
    });

  return Sentry

}

