import { z } from 'zod'

export const ResourceTypesSchema = z.enum([
  'stacks',
  'users',
  'products',
  'shirts',
  'orders',
  'productLabels'
])

export const TimestampSchema = z.union([
  z.object({
    seconds: z.number(),
    nanoseconds: z.number(),
  }),
  z.object({
    _methodName: z.literal('FieldValue.serverTimestamp'),
    seconds: z.number(),
    nanoseconds: z.number(),
  })
])

export const ResourceMetaSchema = z.object({
  flagged: z.boolean().nullable().optional(),
  notes: z.string().nullable().optional(),
})

export const AttributesSchema = z.object({
  updatedOn: TimestampSchema,
  createdOn: TimestampSchema,
})

export const ResourceIdentifierSchema = z.object({
  id: z.string(),
  type: ResourceTypesSchema,
  meta: z.object({})
}).strict()

export const ResourceSchema = z.object({
  id: z.string(),
  type: ResourceTypesSchema,
  attributes: AttributesSchema,
  relationships: z.object({}),
  meta: ResourceMetaSchema
}).strict()

export const RelationshipSchema = z.object({
  id: z.string(),
  type: ResourceTypesSchema,
  addedOn: TimestampSchema
})

export const ResourceHashSchema = z.record(ResourceSchema)

/**
 * Users
 */

export const AdditionalUserInfoSchema = z.object({
  isNewUser: z.boolean(),
  profile: z.object({}).nonstrict().nullable(),
  providerId: z.string(),
  username: z.string().nullable().optional(),
})

export const PickedCredentialSchema = z.object({
  providerId: z.string().nullable(),
  signInMethod: z.string().nullable(),
  idToken: z.string().nullable(),
  accessToken: z.string().nullable(),
  secret: z.string().nullable(),
})

export const ProviderCredentialSchema = z.object({
  additionalUserInfo: AdditionalUserInfoSchema.nullable().optional(),
  credential: PickedCredentialSchema.nullable().optional(),
  operationType: z.string().nullable().optional(),
})

export const ReachedIntroStepSchema = z.enum([
  'stack',
  'frontend',
  'backend',
  'minRows',
  'maxRows',
  'shirtBack',
  'finished',
])

export const ReachedStackStepSchema = z.enum([
  'doSearch',
  'dragDrop',
  'finished'
])

export const ReachedShirtCloseUpStepSchema = z.enum([
  'flipStep',
  'zoomStep'
])

export const ProviderIdSchema = z.enum([
  'github.com',
  'twitter.com',
  'facebook.com',
  'google.com'
])

export const AuthUserSchema = z.object({
  isAnonymous: z.boolean(),
  displayName: z.string().nullable(),
  email: z.string().nullable(),
  phoneNumber: z.string().nullable(),
  photoURL: z.string().nullable(),
  providerId: z.string(),
  uid: z.string(),
})

export const UserSchema = ResourceSchema.merge(z.object({
  type: z.literal('users'),
  attributes: AttributesSchema.merge(z.object({
    authUser: AuthUserSchema,
    'github.com': ProviderCredentialSchema.optional(),
    'twitter.com': ProviderCredentialSchema.optional(),
    'facebook.com': ProviderCredentialSchema.optional(),
    'google.com': ProviderCredentialSchema.optional(),
    reachedIntroStepId: ReachedIntroStepSchema.optional(),
    reachedStackStepId: ReachedStackStepSchema.optional(),
    reachedShirtCloseUpStepId: ReachedShirtCloseUpStepSchema.optional(),
    congratulatedForCompletingAShirt: z.boolean().optional(),
    noOrderAfterPayment: z.boolean().optional()
  })),
  relationships: z.object({
    stacks: z.record(RelationshipSchema.merge(z.object({
      type: z.literal('stacks')
    }))),
    shirts: z.record(RelationshipSchema.merge(z.object({
      type: z.literal('shirts')
    }))),
    cart: RelationshipSchema.merge(z.object({
      type: z.literal('orders')
    })).nullable().optional(),
    orders: z.record(RelationshipSchema.merge(z.object({
      type: z.literal('orders')
    }))).optional()
  }),
}))

/**
 * Shirts
 */

export const FacingSchema = z.enum([
  'front',
  'back'
])

export const PrintableTypesSchema = z.enum([
  'unisexShirt',
  'womensShirt'
])

export const ShirtColorsSchema = z.enum([
  'black',
  'white',
  'asphalt',
])

export const ShirtSizesSchema = z.enum([
  'xs',
  's',
  'm',
  'l',
  'xl',
  'xxl',
  'xxxl',
])

export const UsernameProviderIdSchema = z.enum([
  'twitter.com',
  'github.com',
])

export const EditableShirtAttributesSchema = z.object({
  color: ShirtColorsSchema,
  size: ShirtSizesSchema,
  providerId: UsernameProviderIdSchema,
  username: z.string(),
})

export const ShirtStateSchema = z.enum([
  'APPROVED',
  'REJECTED',
])

export const ShirtSchema = ResourceSchema.merge(z.object({
  type: z.literal('shirts'),
  attributes: AttributesSchema.merge(EditableShirtAttributesSchema),
  relationships: z.object({
    user: RelationshipSchema.merge(z.object({
      type: z.literal('users')
    })),
    stack: RelationshipSchema.merge(z.object({
      type: z.literal('stacks')
    })),
    orders: z.record(RelationshipSchema.merge(z.object({
      type: z.literal('orders')
    }))).optional()
  }),
}))

export const OrderedShirtSchema = ShirtSchema.merge(z.object({
  meta: ResourceMetaSchema.merge(z.object({
    state: ShirtStateSchema.optional()
  }))
}))

export const PartialShirtSchema = ShirtSchema.merge(z.object({
  // attributes: AttributesSchema.merge(ShirtAttributesSchema.partial())
}))


/**
 * Profiles
 */

export const ProfileSchema = ResourceSchema.merge(z.object({
  type: z.literal('profiles'),
  attributes: AttributesSchema.merge(z.object({
    displayName: z.string(),
    isPublic: z.boolean()
  })),
  relationships: z.object({})
}))

/**
 * ProductLabels
 */

export const ProductLabelSchema = ResourceSchema.merge(z.object({
  type: z.literal('productLabels'),
  attributes: AttributesSchema,
  relationships: z.object({})
}))

/**
 * Products
 */

export const LogoSchema = z.object({
  name: z.string().nonempty(),
  pathname: z.string(),
  src: z.string(),
  height: z.number(),
  width: z.number(),
})

export const ColorSchema = z.object({
  name: z.string(),
  hex: z.string(),
})

export const ProductSchema = ResourceSchema.merge(z.object({
  type: z.literal('products'),
  attributes: AttributesSchema.merge(z.object({
    name: z.string(),
    colors: z.array(ColorSchema).min(1),
    logos: z.array(LogoSchema).min(1),
    package: z.object({
      manager: z.string().optional(),
      packageId: z.string().optional(),
    }),
    url: z.string(),
    githubUrl: z.string().optional(),
    updatedOn: TimestampSchema,
    createdOn: TimestampSchema,
  })),
  relationships: z.object({
    productLabels: z.record(RelationshipSchema.merge(z.object({
      type: z.literal('productLabels')
    })))
  })
}))


/**
 * Stacks
 */

export const GridItemSchema = z.object({
  i: z.string(),
  x: z.number(),
  y: z.number(),
  w: z.number(),
  h: z.number(),
  maxH: z.number(),
  minH: z.number(),
  maxW: z.number(),
  minW: z.number(),
})

export const ThemeSchema = z.enum([
  'default',
  'fill',
  'whiteBorder',
  'insetShadowTheme',
  'searchResult',
])

export const AllowedThemeSchema = z.enum([
  'whiteBorder'
])

export const ProductOnStackSchema = RelationshipSchema.merge(z.object({
  type: z.literal('products'),
  gridItem: GridItemSchema,
  color: ColorSchema,
  logo: LogoSchema,
}))

export const EditableStackAttributesSchema = z.object({
  name: z.string(),
  version: z.string(),
  theme: ThemeSchema,
  themeConfigs: z.object({}).optional()
})

export const StackSchema = ResourceSchema.merge(z.object({
  type: z.literal('stacks'),
  attributes: AttributesSchema.merge(EditableStackAttributesSchema),
  relationships: z.object({
    user: RelationshipSchema.merge(z.object({
      type: z.literal('users')
    })),
    shirts: z.record(RelationshipSchema.merge(z.object({
      type: z.literal('shirts')
    }))),
    orders: z.record(RelationshipSchema.merge(z.object({
      type: z.literal('orders')
    }))),
    products: z.record(ProductOnStackSchema)
  })
}))

/**
 * Orders
 */

export const OrderStateSchema = z.enum([
  'cart',
  'submitted',
  'draftedAtPrinter',
  'printing',
  'inTransit',
  'delivered',
  'canceled',
])

export const CancelableOrderStateSchema = z.enum([
  'cart',
  'submitted',
  'draftedAtPrinter',
])

export const ShippingAddressSchema = z.object({
  name: z.string(),
  street1: z.string(),
  street2: z.string()
    .optional()
    .nullable(),
  city: z.string(),
  state: z.string(),
  zip: z.string(),
  country: z.string(),
})

export const CartOrderSchema = ResourceSchema.merge(z.object({
  id: z.string(),
  type: z.literal('orders'),
  attributes: AttributesSchema.merge(z.object({
    shippingAddress: ShippingAddressSchema.partial(),
  })),
  relationships: z.object({
    user: RelationshipSchema.extend({
      type: z.literal('users')
    }),
    shirts: z.record(RelationshipSchema.extend({
      type: z.literal('shirts')
    }))
  }),
  meta: ResourceMetaSchema.merge(z.object({
    state: z.undefined(),
    session: z.any()
      .optional(),
  }))
}))

export const SubmittedOrderSchema = ResourceSchema.merge(z.object({
  id: z.string(),
  type: z.literal('orders'),
  attributes: AttributesSchema.merge(z.object({
    shippingAddress: ShippingAddressSchema
  })),
  relationships: z.object({
    user: RelationshipSchema.extend({
      type: z.literal('users')
    }),
    shirts: z.record(RelationshipSchema.extend({
      type: z.literal('shirts')
    }))
  }),
  meta: ResourceMetaSchema.merge(z.object({
    state: z.enum([
      'submitted',
      'draftedAtPrinter',
      'printing',
      'inTransit',
      'delivered',
      'canceled',
    ]),
    session: z.any()
      .optional(),
    printful: z.object({
      id: z.number(),
      external_id: z.string(),
      dashboard_url: z.string(),
      status: z.string(),
    })
      .optional(),
    printfulRetry: z.number()
      .optional(),
    submittedOn: TimestampSchema,
    shirts: z.record(OrderedShirtSchema), // We copy the shirts and stacks here so they immutable
    stacks: z.record(StackSchema)
  }))
}))
