export enum FIREBASE_AUTH_ERROR_CODES {
  EMAIL_ALREADY_IN_USE = 'auth/email-already-in-use',
  NETWORK_REQUEST_FAILED = 'auth/network-request-failed',
  USER_NOT_FOUND = 'auth/user-not-found',
  CREDENTIAL_ALREADY_IN_USE = 'auth/credential-already-in-use',
  WRONG_PASSWORD = 'auth/wrong-password'
}

export enum AUTH_CODES {
  UNKNOWN_ERROR = 'unknown-error',
  EMAIL_USED_PROBABLY_FROM_A_PROVIDER = 'try-another-password-or-provider',
}
