import classnames from 'classnames';
import React, {
  Dispatch,
  forwardRef,
  SetStateAction,
  useEffect,
  useState
} from 'react';
import { useFormContext } from 'react-hook-form';
import {
  ACTIVE_INPUTS,
  ActiveInputType
} from 'src/components/ShirtCloseUp/helpers';
import { bottomBarId } from 'src/pages/Private/StackAndShirtAttributes/ShirtAttributes/ShirtMobileDrawer';
import NameInput from 'src/pages/Private/StackAndShirtAttributes/StackAttributes/NameInput/NameInput';
import VersionInput from 'src/pages/Private/StackAndShirtAttributes/StackAttributes/VersionInput/VersionInput';

interface Props {
  initialTab: ActiveInputType | undefined;
  bottomBarCTA: React.ReactNode;
  setActiveInput: Dispatch<SetStateAction<ACTIVE_INPUTS | null>>
}

type TabType = ACTIVE_INPUTS.NAME | ACTIVE_INPUTS.VERSION;

const StackAttributesMobileDrawer = forwardRef<HTMLDivElement, Props>((props, ref) => {

  const {
    initialTab,
    bottomBarCTA,
    setActiveInput,
  } = props;

  const [mobileActiveTab, setMobileActiveTab] = useState<TabType>(() => {
    switch (initialTab) {
      case ACTIVE_INPUTS.NAME:
      case ACTIVE_INPUTS.VERSION: {
        return initialTab;
      }
      default: {
        return ACTIVE_INPUTS.NAME
      }
    }
  })

  const { setFocus } = useFormContext()
  useEffect(() => {
    setFocus(mobileActiveTab)
    setActiveInput(mobileActiveTab)
  }, [setFocus, mobileActiveTab, setActiveInput])

  const renderInput = () => {
    switch (mobileActiveTab) {
      case ACTIVE_INPUTS.NAME: {
        return <NameInput />
      }
      case ACTIVE_INPUTS.VERSION: {
        return <VersionInput />
      }
    }
  }

  return (
    <div
      id={bottomBarId}
      ref={ref}
      className="bottom-action-bar d-block d-md-none"
    >
      <div className="nav-tabs">
        <ul className="nav px-4 container-fluid">
          {[ACTIVE_INPUTS.NAME, ACTIVE_INPUTS.VERSION].map((key) => {

            const label = key === ACTIVE_INPUTS.NAME ? 'Name' : 'Version'

            return (
              <li
                key={key}
                className="nav-item"
              >
                <span
                  onClick={() => {
                    setMobileActiveTab(key as ACTIVE_INPUTS.NAME | ACTIVE_INPUTS.VERSION);
                  }}
                  className={classnames('nav-link href', mobileActiveTab === key && 'active')}
                >
                  {label}
                </span>
              </li>
            )
          })}
        </ul>
      </div>
      <div className="container-fluid py-3 border-bottom">
        {renderInput()}
      </div>
      <div className="container-fluid py-3">
        {bottomBarCTA}
      </div>
    </div>
  );
})

export default StackAttributesMobileDrawer
