import React, { useEffect, useRef } from 'react';

type Props = {
  onFocus?: (e: React.ChangeEvent<any>) => void;
  onBlur?: (e: React.ChangeEvent<any>) => void;
  onSubmit?: (e: React.FormEvent<HTMLFormElement>) => void;
  onChange?: (e: React.FormEvent<HTMLFormElement>) => void;
  className?: string
}

const FormAndListeners: React.FC<Props> = (props) => {

  const {
    children,
    onFocus,
    onBlur,
    onChange,
    className = '',
    onSubmit,
  } = props;

  const formRef = useRef<HTMLFormElement>(null);
  const ref = useRef<any>({
    onFocus,
    onBlur,
  })

  useEffect(() => {
    if (formRef.current) {
      const form = formRef.current;
      const callbacks = ref.current;
      form.addEventListener('focus', callbacks.onFocus, true);
      form.addEventListener('blur', callbacks.onBlur, true);
      return () => {
        if (form) {
          form.removeEventListener('focus', callbacks.onFocus, true);
          form.removeEventListener('blur', callbacks.onBlur, true);
        }
      }
    }
  }, [])

  return (
    <form
      className={className}
      ref={formRef}
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit && onSubmit(e)
      }}
      onChange={onChange}
    >
      {children}
    </form>
  );
}

export default FormAndListeners
