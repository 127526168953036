const { useEffect } = require('react');

const match = (userAgent: NavigatorID['userAgent'] | 'SSR' ) => {
  const isAndroid = Boolean(userAgent.match(/Android/i));
  const isIOS = Boolean(userAgent.match(/iPhone|iPad|iPod/i));
  const isOpera = Boolean(userAgent.match(/Opera Mini/i));
  const isWindows = Boolean(userAgent.match(/IEMobile/i));
  const isSSR = Boolean(userAgent.match(/SSR/i));

  // https://stackoverflow.com/questions/56934826/distinguish-between-ipad-and-mac-on-ipad-with-ipados
  const isIpad = Boolean(userAgent.toLowerCase().indexOf('macintosh') > -1 && navigator.maxTouchPoints && navigator.maxTouchPoints > 2);

  const isMobileDevice = Boolean(isAndroid || isIOS || isIpad || isOpera || isWindows);
  const isDesktopDevice = Boolean(!isMobileDevice && !isSSR);
  
  return {
    isMobileDevice,
    isDesktopDevice,
    isAndroid,
    isIos: isIOS,
    isSSR
  };
};

const useUserAgent = () => {
  useEffect(() => {}, []);
  const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;
  return match(userAgent);
};

export default useUserAgent
