import PageView from 'src/components/PageView/PageView';
import { useResources } from 'src/hooks/useResources';
import useSearchParams from 'src/hooks/useSearchParams';
import { UserContext } from 'src/pages/AuthListener/User';
import Page404 from 'src/pages/Page404/Page404';
import Processing from 'src/pages/Private/Confirmation/Processing';
import {
  makeContactUsPathname,
  makePrivateOrdersPathname
} from 'src/sdk/PATHS';
import { useAnalytics } from 'src/sdk/analytics/AnalyticsContext';
import { useDebug } from 'src/sdk/debug/DebugContext';
import { MethodsSDKContext } from 'src/sdk/methods/MethodsSDKProvider';
import {
  SubmittedOrderType,
  UserType
} from 'src/sdk/schemaTypes';
import produce from 'immer';
import React, {
  useContext,
  useEffect,
  useState
} from 'react';
import { Helmet } from 'react-helmet';
import {
  Link,
  useParams
} from 'react-router-dom';

const CONFIRMATION_CODES = {
  FAILED_TO_PROCESS: 'failed-to-process'
}

const Confirmation: React.FC = () => {
  
  const params = useParams();
  const [{ sessionId }] = useSearchParams<{ sessionId: string }>()

  // now, get all orders in user.relationships.orders
  // and .find() for an order with order.attributes.stripe.session.id = sessionId
  // Once you find it, display this and not Processing

  const debug = useDebug();
  const { data: user } = useContext(UserContext);
  const { data: orders } = useResources<SubmittedOrderType>(user?.relationships?.orders)
  const [processed, setProcessed] = useState(false)
  const [code, setCode] = useState<string | undefined>()
  const methodsSDK = useContext(MethodsSDKContext);
  const analytics = useAnalytics();

  useEffect(() => {
    if (!orders) {
      return;
    }
    if (!sessionId) {
      return debug.error(new Error('Damn, we need a sessionId on Confirmation'))
    }
    

    const ordersArray = Object.values(orders)
    const currentOrder = ordersArray.find((order) => {
      return order.meta.session.id === sessionId;
    })
    if (currentOrder) {
      setProcessed(true)
    }
  }, [orders, debug, sessionId])

  useEffect(() => {
    if (!user) {
      return;
    }
    // If not processed at first, start timeout.
    // If processed turns true, clearTimeout() will run
    if (!processed) {
      const id = setTimeout(async () => {
        if (!user.attributes.noOrderAfterPayment) {
          setCode(CONFIRMATION_CODES.FAILED_TO_PROCESS)
          const updatedUser = produce<UserType>(user, (draft) => {
            draft.attributes.noOrderAfterPayment = true;
          })
          await methodsSDK?.upsertResource(updatedUser)
        }
      }, 10000)
      return () => {
        clearTimeout(id)
      }
    }
  }, [methodsSDK, user, processed])

  if (code) {
    return (
      <Page404
        statusCode={500}
        reason="Shucks. We seem to be having trouble processing your order. The team has been notified and will reach out to you via email."
      />
    )
  }

  if (!processed) {
    return <Processing />
  }

  return (
    <>
      <PageView
        onPageView={() => analytics.page({
          name: 'Confirmation'
        })}
      />
      <div className="container cy">


        <Helmet defer={false}>
          <title>Confirmation</title>
          <meta
            name="description"
            content="Thank you for ordering. Please let us know how you like your shirt!"
          />
        </Helmet>

        <h1 className="display-2 fadeInFromRight">
          Congratulations!
        </h1>

        <p className="lead fadeInFromRight second">
          Your order is on its way.
        </p>
        <div className="fadeInFromRight third">
          <p className="w-75 display-p">
            Meanwhile, let us know if you have any suggestions or feedback on the whole shirt-making experience!
          </p>

          <Link
            to={makePrivateOrdersPathname(params)}
            className="btn btn-outline mr-2"
          >
            Your orders
          </Link>
          <Link
            to={makeContactUsPathname(params)}
            className="btn btn-default"
          >
            Contact us
          </Link>
        </div>

      </div>
    </>
  );
};

export default Confirmation
