import React from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import PageView from 'src/components/PageView/PageView';
import { useAnalytics } from 'src/sdk/analytics/AnalyticsContext';
import { makePrivateStackIntroPathname } from 'src/sdk/PATHS';
import { ReachedIntroStepSchema } from 'src/sdk/schemas';
import messages from '../Intro.messages'

const Frontend: React.FC = (props) => {

  const params = useParams();
  const analytics = useAnalytics();

  return (
    <>
      <Helmet defer={false}>
        <title>Intro - The Frontend</title>
        <meta
          name="description"
          content="Explanation of what the frontend of a stack is"
        />
      </Helmet>
      <PageView
        onPageView={() => analytics.page({
          name: 'Frontend Defined',
          category: 'Intro'
        })}
      />
      <div className="uppercase-label fadeInFromRight">
        Intro
      </div>
      <h1 className="fadeInFromRight second">
        <FormattedMessage
          {...messages.frontendHeading}
        />
      </h1>
      <p className="fadeInFromRight third">
        <FormattedMessage
          {...messages.frontendBody}
        />
      </p>
      <Link
        to={{
          pathname: makePrivateStackIntroPathname({
            ...params,
            stepId: ReachedIntroStepSchema.enum.backend
          }),
        }}
        className="btn btn-primary"
      >
        Next
      </Link>
    </>
  );
}

export default Frontend
