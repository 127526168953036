import { LOCALE_PATH } from 'src/sdk/PATHS';
import { Route } from 'react-router';
import { useShirtImagesCache } from 'src/shirtImages/shirtImages';
import AppLayout from '../AppLayout/AppLayout';
import LogRocket from 'logrocket';
import React, { useEffect } from 'react';
import setupLogRocketReact from 'logrocket-react';

import { EVENT_NAMES } from 'src/sdk/analytics/types';
import {
  isProdOrPreview,
  packageVersion
} from 'src/utils/environment';
import { useAnalytics } from 'src/sdk/analytics/AnalyticsContext';
import { useSentry } from 'src/sdk/sentry/SentryContext';
import AppRoutes from '../AppRoutes/AppRoutes';
import LocationLog from './LocationLog';


if (!process.env.REACT_APP_LOGROCKET_KEY) {
  throw new Error('You need to provide a REACT_APP_LOGROCKET_KEY env var')
}

const logRocketKey = process.env.REACT_APP_LOGROCKET_KEY;

export const App: React.FC = (props) => {

  const {
    children,
  } = props;

  const sentry = useSentry();
  const analytics = useAnalytics();

  useShirtImagesCache()

  useEffect(() => {

    LogRocket.init(logRocketKey, {
      release: packageVersion,
      console: {
        isEnabled: !isProdOrPreview,
      },
    });

    LogRocket.getSessionURL((sessionURL) => {
      sentry?.configureScope((scope: any) => {
        scope.setExtra("sessionURL", sessionURL);
      });


      analytics.track({
        name: EVENT_NAMES.LOG_ROCKET_SETUP,
        properties: {
          sessionURL: sessionURL,
        }
      });
    });

    setupLogRocketReact(LogRocket);


  }, [sentry, analytics]);

  return (
    <Route
      path={LOCALE_PATH}
      render={({ ...props }) => {
        return (
          <AppLayout>
            <LocationLog>
              <AppRoutes>
                {/*The only reason we pass children is for storybook and tests*/}
                {children}
              </AppRoutes>
            </LocationLog>
          </AppLayout>
        );
      }}
    />
  );
};

export default App;
