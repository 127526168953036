import {
  animated,
  to,
  useSpring
} from '@react-spring/web';
import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef
} from 'react'
import * as Rematrix from 'rematrix'
import { FlipElementBoundsType } from 'src/components/Flip/types';
import { getBoundsHelper } from 'src/components/Flip/utils';

import { FlipperContext } from './Flipper'

type Props = {
  flipId: string;
  style?: any;
  className?: string;
  isActive?: boolean;
  useBCR?: boolean;
}

export type Matrix = number[]

export const convertMatrix3dArrayTo2dArray = (matrix: Matrix): Matrix =>
  [0, 1, 4, 5, 12, 13].map(index => matrix[index])

const Flip: React.FC<Props> = (props, ref) => {

  const {
    flipId,
    style = {},
    className,
    children,
    isActive = true,
    useBCR = false,
  } = props

  const {
    debug,
    flipperDiv,
    boundsByFlipId,
    register,
    flipKey,
  } = useContext(FlipperContext)

  const stateRef = useRef<{
    prevBounds?: FlipElementBoundsType,
    nextBounds?: FlipElementBoundsType,
    nextMatrix?: Matrix,
  }>({})
  const flipDiv = useRef<HTMLDivElement>(null)

  const [{ matrix }, api] = useSpring(() => ({
    matrix: [1, 0, 0, 1, 0, 0],
    immediate: true,
  }))

  // Use useLayoutEffect because it will trigger before the browser paint.
  useLayoutEffect(() => {

    const prevBounds = boundsByFlipId[flipId]

    // We only need to bother animating if we have prevBounds on this flipId
    if (prevBounds && flipDiv.current && flipperDiv.current) {
      const nextBounds = getBoundsHelper(flipDiv.current, flipperDiv.current, useBCR)
      if (!nextBounds) {
        console.error(new Error('Make sure you wrapped your animation in a Flipper component'))
        return;
      }

      const {
        left,
        top,
        width,
        height,
      } = prevBounds

      const nextTransforms = []

      let style = getComputedStyle(flipDiv.current).transform;
      style = style !== 'none' ? style : 'matrix(1, 0, 0, 1, 0, 0)'
      nextTransforms.push(Rematrix.fromString(style))

      const flipTransforms = [...nextTransforms]

      flipTransforms.push(
        Rematrix.translateX(left - nextBounds.left)
      )
      flipTransforms.push(
        Rematrix.translateY(top - nextBounds.top)
      )
      flipTransforms.push(
        Rematrix.scaleX(
          Math.max(width, 1) / Math.max(nextBounds.width, 1)
        )
      )
      flipTransforms.push(
        Rematrix.scaleY(
          Math.max(height, 1) / Math.max(nextBounds.height, 1)
        )
      )

      const prevMatrix = convertMatrix3dArrayTo2dArray(
        flipTransforms.reduce(Rematrix.multiply)
      )
      const nextMatrix = convertMatrix3dArrayTo2dArray(
        nextTransforms.reduce(Rematrix.multiply)
      )

      api.set({
        matrix: prevMatrix,
      })
      if (!debug) {
        void api.start({
          matrix: nextMatrix,
        })
      }


      stateRef.current = {
        prevBounds,
        nextBounds,
        nextMatrix,
      }
    }
  }, [isActive, api, flipId, boundsByFlipId, flipperDiv, useBCR, debug])

  useEffect(() => {
    if (isActive && flipDiv.current) {
      register(flipId, flipDiv.current, useBCR)
    }
  }, [isActive, flipId, useBCR, register])

  const animatedStyle = {
    transform: to(matrix, (...args: number[]) => {
      return `matrix(${args.join(', ')})`
    }),
  }

  return (
    <animated.div
      key={flipKey}
      ref={flipDiv}
      className={className}
      style={{
        ...style,
        ...animatedStyle,
        transformOrigin: '0 0'
      }}
    >
      {children}
    </animated.div>
  )
}

export default Flip
