import produce from 'immer';
import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import {
  useHistory,
  useParams
} from 'react-router';
import PageView from 'src/components/PageView/PageView';
import { UserContext } from 'src/pages/AuthListener/User';
import { useAnalytics } from 'src/sdk/analytics/AnalyticsContext';
import { useMethodsSDK } from 'src/sdk/methods/MethodsSDKProvider';
import { makePrivateStackPathname } from 'src/sdk/PATHS';
import { ReachedIntroStepSchema } from 'src/sdk/schemas';
import { UserType } from 'src/sdk/schemaTypes';
import { nprogressTry } from 'src/utils/nprogress';
import messages from '../Intro.messages'

const ShirtBack: React.FC = (props) => {

  const params = useParams();
  const history = useHistory();
  const methodsSDK = useMethodsSDK();
  const { data: user } = useContext(UserContext);
  const analytics = useAnalytics();

  if (!user) {
    return null;
  }

  return (
    <>
      <Helmet defer={false}>
        <title>Intro - The Shirt</title>
        <meta
          name="description"
          content="Just a sneak peak of what a stack looks like on a shirt"
        />
      </Helmet>
      <PageView
        onPageView={() => analytics.page({
          name: 'The Shirt',
          category: 'Intro'
        })}
      />
      <div className="uppercase-label fadeInFromRight">
        Intro
      </div>
      <h1 className="fadeInFromRight second">
        <FormattedMessage
          values={{ br: <br /> }}
          {...messages.onShirtHeading}
        />
      </h1>
      <p className="fadeInFromRight third">
        <FormattedMessage
          values={{ br: <br /> }}
          {...messages.onShirtBody}
        />
      </p>
      <button
        type="button"
        onClick={async () => {
          await nprogressTry(async () => {
            if (!user) {
              return;
            }

            const updatedUser = produce<UserType>(user, (draft) => {
              draft.attributes.reachedIntroStepId = ReachedIntroStepSchema.enum.finished;
            });
            await methodsSDK.upsertResource(updatedUser)
            history.push({
              pathname: makePrivateStackPathname(params),
            });
          })
        }}
        className="btn btn-primary"
      >
        <FormattedMessage
          {...messages.getStarted}
        />
      </button>
    </>
  );
}

export default ShirtBack
