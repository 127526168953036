import nprogress from 'nprogress';
import { CurriedFn } from 'src/sdk/types';

export { nprogress };

export const nprogressTry: CurriedFn = (fn) => {
  nprogress.start();
  const promise = Promise.resolve(fn());
  promise.finally(nprogress.done);
  return promise;
}
