import * as React from "react";

function SvgCartIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 35 32" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M8.045 3.31c-.715 0-1.295.588-1.295 1.312l1.962 13.943a1.301 1.301 0 001.296 1.297c.065-.018 1.977-.835 5.41-1.246 5.788-.692 16.925-.741 17.038-.741.712 0 1.294-.588 1.294-1.314V4.62c0-.722-.582-1.31-1.294-1.31H8.045z"
          fill="#A7A7A7"
        />
        <path
          d="M8.045 3.31c-.715 0-1.295.588-1.295 1.312l1.962 13.943a1.301 1.301 0 001.296 1.297c.065-.018 1.977-.835 5.41-1.246 5.788-.692 16.925-.741 17.038-.741.712 0 1.294-.588 1.294-1.314V4.62c0-.722-.582-1.31-1.294-1.31H8.045z"
          fill="#A7A7A7"
        />
        <path
          d="M6.832 2.207c-.666 0-1.207.53-1.207 1.182l1.83 12.565c-.002.637.54 1.168 1.207 1.168.061-.016 1.843-.752 5.044-1.123 5.396-.624 15.78-.668 15.885-.668.664 0 1.206-.53 1.206-1.184V3.39c0-.651-.542-1.182-1.206-1.182H6.832z"
          fill="#FFF"
        />
        <path
          d="M6.832 2.207c-.666 0-1.207.53-1.207 1.182l1.83 12.565c-.002.637.54 1.168 1.207 1.168.061-.016 1.843-.752 5.044-1.123 5.396-.624 15.78-.668 15.885-.668.664 0 1.206-.53 1.206-1.184V3.39c0-.651-.542-1.182-1.206-1.182H6.832z"
          fill="#FFF"
        />
        <path
          d="M10.447 25.598c-1.15 0-2.085-.915-2.085-2.042l-.276-1.632-.322-2.123a2.008 2.008 0 01-.086-.55L5.482 4.18l-.38-2.246H.987A.978.978 0 010 .967C0 .435.443 0 .987 0h4.386c.063 0 .125.016.19.033l.028.007.085.008C5.796.017 5.859 0 5.92 0c.544 0 .988.434.988.966l.209 1.238.109-.02a2.04 2.04 0 01.342-.036h25.224c1.148 0 2.083.917 2.083 2.045v12.91c0 1.126-.934 2.042-2.083 2.042-.104 0-10.206.078-16.281.704-2.92.301-5.69 1.114-6.47 1.356l-.088.028.37 2.43h21.372a.98.98 0 01.986.968.979.979 0 01-.986.967h-21.25zM8.938 4.086c-.666 0-1.207.53-1.207 1.182l1.83 12.565C9.558 18.47 10.1 19 10.767 19c.061-.016 1.843-.752 5.044-1.123 5.397-.623 15.78-.668 15.885-.668.664 0 1.206-.53 1.206-1.184V5.268c0-.651-.542-1.182-1.206-1.182H8.938z"
          fill="#000"
        />
        <path
          d="M10.447 25.598c-1.15 0-2.085-.915-2.085-2.042l-.276-1.632-.322-2.123a2.008 2.008 0 01-.086-.55L5.482 4.18l-.38-2.246H.987A.978.978 0 010 .967C0 .435.443 0 .987 0h4.386c.063 0 .125.016.19.033l.028.007.085.008C5.796.017 5.859 0 5.92 0c.544 0 .988.434.988.966l.209 1.238.109-.02a2.04 2.04 0 01.342-.036h25.224c1.148 0 2.083.917 2.083 2.045v12.91c0 1.126-.934 2.042-2.083 2.042-.104 0-10.206.078-16.281.704-2.92.301-5.69 1.114-6.47 1.356l-.088.028.37 2.43h21.372a.98.98 0 01.986.968.979.979 0 01-.986.967h-21.25zM8.938 4.086c-.666 0-1.207.53-1.207 1.182l1.83 12.565C9.558 18.47 10.1 19 10.767 19c.061-.016 1.843-.752 5.044-1.123 5.397-.623 15.78-.668 15.885-.668.664 0 1.206-.53 1.206-1.184V5.268c0-.651-.542-1.182-1.206-1.182H8.938zM12.879 26.784c-1.21 0-2.191.962-2.191 2.15s.98 2.15 2.191 2.15c1.214 0 2.195-.962 2.195-2.15s-.981-2.15-2.195-2.15zM28.235 31.084c1.211 0 2.193-.963 2.193-2.15 0-1.188-.982-2.151-2.193-2.151-1.21 0-2.192.963-2.192 2.15 0 1.188.981 2.151 2.192 2.151z"
          fill="#000"
        />
      </g>
    </svg>
  );
}

export default SvgCartIcon;
