import React from 'react';
import { useParams } from 'react-router';
import { ReachedIntroStepSchema } from 'src/sdk/schemas';
import Backend from './Steps/Backend';
import Frontend from './Steps/Frontend';
import KickOff from './Steps/KickOff';
import MaxRows from './Steps/MaxRows';
import MinRows from './Steps/MinRows';
import ShirtBack from './Steps/ShirtBack';

const IntroRoutes: React.FC = (props) => {

  const params = useParams<{ stepId: string, userId: string, stackId: string }>();

  switch (params.stepId) {
    case ReachedIntroStepSchema.enum.stack:
      return <KickOff />
    case  ReachedIntroStepSchema.enum.frontend:
      return <Frontend />
    case  ReachedIntroStepSchema.enum.backend:
      return <Backend />
    case ReachedIntroStepSchema.enum.minRows:
      return <MinRows />
    case  ReachedIntroStepSchema.enum.maxRows:
      return <MaxRows />
    case  ReachedIntroStepSchema.enum.shirtBack:
      return <ShirtBack />
    default:
      return <KickOff />
  }
}

export default IntroRoutes
