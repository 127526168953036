import React, { useLayoutEffect, useRef } from 'react';
import breakpoints from 'src/styles/breakpoints';
import classnames from 'classnames'
import styled from 'styled-components';
import useRectangle from 'src/hooks/useRectangle';

const StyledTable = styled.table<{ breakpoint: Breakpoint }>`
  
  td {
    white-space: nowrap;
  }

  .Table__formValue__td {
    text-align: right;
    width: 100%;
  }

  @media (min-width: ${({ breakpoint }: { breakpoint: Breakpoint }) => breakpoints[breakpoint]}px) {
    .Table__formValue__td {
      text-align: left;
    }
  }
`

type Breakpoint = 'sm' | 'md'

export interface Props {
  breakpoint?: Breakpoint;
  className?: string;
}

export const Table: React.FC<Props> = ((props) => {

  const {
    children,
    className,
    breakpoint = 'sm',
  } = props;

  const ref = useRef<HTMLDivElement>(null);
  const { width: rowWidth } = useRectangle(ref)

  return (
    <div ref={ref} className="w-100">
      <StyledTable
        breakpoint={breakpoint}
        className={classnames('table w-100', className)}
      >
        <thead>
          <tr>
            <th>
              <span className="sr-only">
                Field label
              </span>
            </th>
            <th>
              <span className="sr-only">
                Field value
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          {React.Children.map(children, (child) => {
            if (React.isValidElement(child)) {
              return React.cloneElement(child, { rowWidth })
            }
          })}
        </tbody>
      </StyledTable>
    </div>
  );
})


export const Tr: React.FC<{ rowWidth?: number }> = (props) => {

  const {
    children,
    rowWidth,
  } = props;

  const labelRef = useRef<HTMLTableHeaderCellElement>(null)
  const [valueWidth, setValueWidth] = React.useState<number | undefined>();

  useLayoutEffect(() => {
    if (!labelRef.current || !rowWidth) {
      return;
    }
    const { width: labelWidth } = labelRef.current.getBoundingClientRect();
    setValueWidth(rowWidth && labelWidth && rowWidth - labelWidth);
  }, [rowWidth])

  if (!Array.isArray(children)) {
    throw new Error('You need to pass FormResult multiple nodes as children, corresponding to data cells in a row.');
  }

  return (
    <tr>
      <td
        ref={labelRef}
        className="Table__formLabel__td"
      >
        <span className="form-label">
          {children[0]}
        </span>
      </td>
      <td
        style={{
          maxWidth: valueWidth
        }}
        className="Table__formValue__td"
      >
        <span className="text-truncate form-value">
          {children[1]}
        </span>
      </td>
    </tr>
  )

}

