import { useFormContext } from 'react-hook-form';
import { ACTIVE_INPUTS, ActiveInputType } from 'src/components/ShirtCloseUp/helpers';
import React, {
  Dispatch,
  forwardRef,
  SetStateAction,
  useEffect,
  useState
} from 'react';
import SelectColor from 'src/pages/Private/StackAndShirtAttributes/ShirtAttributes/Inputs/SelectColor';
import SelectSize from 'src/pages/Private/StackAndShirtAttributes/ShirtAttributes/Inputs/SelectSize';
import UsernameInput from 'src/pages/Private/StackAndShirtAttributes/ShirtAttributes/Inputs/UsernameInput';
import classnames from 'classnames';

interface Props {
  initialTab: ActiveInputType | string | undefined;
  bottomBarCTA: React.ReactNode;
  setActiveInput: Dispatch<SetStateAction<ACTIVE_INPUTS | null>>
}

type TabType = ACTIVE_INPUTS.USERNAME | ACTIVE_INPUTS.SHIRT_SIZE | ACTIVE_INPUTS.SHIRT_COLOR

const labels: { [key in ACTIVE_INPUTS]?: string } = {
  [ACTIVE_INPUTS.SHIRT_COLOR]: 'Color',
  [ACTIVE_INPUTS.SHIRT_SIZE]: 'Size',
  [ACTIVE_INPUTS.USERNAME]: 'Username',
}

export const bottomBarId = 'bottom-action-bar'

const ShirtMobileDrawer = forwardRef<HTMLDivElement, Props>((props, ref) => {

  const {
    initialTab,
    bottomBarCTA,
    setActiveInput,
  } = props;

  const [mobileActiveTab, setMobileActiveTab] = useState<TabType>(() => {
    switch (initialTab) {
      case ACTIVE_INPUTS.USERNAME:
      case ACTIVE_INPUTS.SHIRT_SIZE:
      case ACTIVE_INPUTS.SHIRT_COLOR: {
        return initialTab;
      }
      default: {
        return ACTIVE_INPUTS.USERNAME
      }
    }
  })

  const { setFocus } = useFormContext()
  useEffect(() => {
    if (mobileActiveTab === ACTIVE_INPUTS.USERNAME) {
      setFocus(mobileActiveTab)
      setActiveInput(mobileActiveTab)
    }
  }, [setFocus, mobileActiveTab, setActiveInput])

  const renderInput = () => {
    switch (mobileActiveTab) {
      case ACTIVE_INPUTS.USERNAME: {
        return <UsernameInput />
      }
      case ACTIVE_INPUTS.SHIRT_COLOR: {
        return <SelectColor />
      }
      case ACTIVE_INPUTS.SHIRT_SIZE: {
        return <SelectSize />
      }
    }
  }

  return (
    <div ref={ref} id={bottomBarId} className="bottom-action-bar d-block d-md-none">
      <div className="nav-tabs">
        <ul className="nav px-4 container-fluid">
          {[ACTIVE_INPUTS.USERNAME, ACTIVE_INPUTS.SHIRT_SIZE, ACTIVE_INPUTS.SHIRT_COLOR]
            .map((key) => {

              const label = labels[key];

              return (
                <li
                  key={key}
                  className="nav-item"
                >
                  <span
                    onClick={() => setMobileActiveTab(key as TabType)}
                    className={classnames('nav-link href', mobileActiveTab === key && 'active')}
                  >
                    {label}
                  </span>
                </li>
              )
            })}
        </ul>
      </div>
      <div className="container-fluid py-3 border-bottom">
        {renderInput()}
      </div>
      <div className="container-fluid py-3">
        {bottomBarCTA}
      </div>
    </div>
  );
})

export default ShirtMobileDrawer
