import React from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import PageView from 'src/components/PageView/PageView';
import { useAnalytics } from 'src/sdk/analytics/AnalyticsContext';
import { useAuth } from 'src/sdk/auth/useAuth';
import { makePrivateStackIntroPathname } from 'src/sdk/PATHS';
import { ReachedIntroStepSchema } from 'src/sdk/schemas';
import messages from '../Intro.messages'

const KickOff: React.FC = (props) => {

  const params = useParams();
  const analytics = useAnalytics();
  const auth = useAuth();

  return (
    <>
      <Helmet defer={false}>
        <title>Intro - A Stack</title>
        <meta
          name="description"
          content="Explanation of what a stack is as part of an onboarding process"
        />
      </Helmet>
      <PageView
        onPageView={() => {
          analytics.page({
            name: 'Stack Defined',
            category: 'Intro',
            properties: {
              auth,
            }
          })
        }}
      />
      <div className="uppercase-label fadeInFromRight">
        Intro
      </div>
      <h1 className="fadeInFromRight">
        <FormattedMessage
          {...messages.stackHeading}
        />
      </h1>
      <p className="fadeInFromRight third">
        <FormattedMessage
          {...messages.stackBody}
        />
      </p>
      <Link
        to={{
          pathname: makePrivateStackIntroPathname({
            ...params,
            stepId: ReachedIntroStepSchema.enum.frontend
          }),
        }}
        className="btn btn-primary"
      >
        Next
      </Link>
    </>

  );
}

export default KickOff
