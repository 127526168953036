import firebase from 'firebase';
import produce from 'immer';
import {
  AuthUserType,
  ProviderCredentialType,
  ProviderId,
  ShirtType,
  UserType
} from 'src/sdk/schemaTypes';
import {
  FirebaseUniversalSDKType,
  RESOURCE_TYPES
} from 'src/sdk/types';

export const makeProviderData = (userCredential: firebase.auth.UserCredential): ProviderCredentialType => {
  const credential = userCredential.credential as firebase.auth.OAuthCredential;
  return {
    additionalUserInfo: Object.assign({}, userCredential.additionalUserInfo),
    credential: {
      providerId: credential?.providerId || null,
      signInMethod: credential?.signInMethod || null,
      idToken: credential?.idToken || null,
      accessToken: credential?.accessToken || null,
      secret: credential?.secret || null,
    },
    operationType: userCredential.operationType || null,
  }
}

export const makeAuthUser = (firebaseUser: firebase.User): AuthUserType => ({
  uid: firebaseUser.uid,
  displayName: firebaseUser.displayName,
  photoURL: firebaseUser.photoURL,
  email: firebaseUser.email,
  phoneNumber: firebaseUser.phoneNumber,
  providerId: firebaseUser.providerId,
  isAnonymous: firebaseUser.isAnonymous,
})

export default class UsersSDK {

  firebaseSDK: FirebaseUniversalSDKType;

  constructor(firebaseSDK: FirebaseUniversalSDKType) {
    this.firebaseSDK = firebaseSDK;
  }

  createUser(firebaseUser: firebase.User, userCredential?: firebase.auth.UserCredential): UserType {

    // So are we ever going to createUser with a userCredential??

    const authUser = makeAuthUser(firebaseUser);

    const user: UserType = {
      id: firebaseUser.uid,
      type: RESOURCE_TYPES.USERS,
      attributes: {
        authUser,
        createdOn: this.firebaseSDK.makeTimestamp(),
        updatedOn: this.firebaseSDK.makeTimestamp(),
      },
      relationships: {
        orders: {},
        shirts: {},
        stacks: {},
      },
      meta: {}
    }

    const providerId = userCredential?.credential?.providerId as ProviderId
    if (providerId && userCredential) {
      user.attributes[providerId] = makeProviderData(userCredential)
    }

    return user;
  }

  updateAuthOnUser(user: UserType, firebaseUser: firebase.User, userCredential: firebase.auth.UserCredential) {

    const authUser: AuthUserType = {
      isAnonymous: firebaseUser.isAnonymous,
      displayName: firebaseUser.displayName,
      email: firebaseUser.email,
      phoneNumber: firebaseUser.phoneNumber,
      photoURL: firebaseUser.photoURL,
      providerId: firebaseUser.providerId,
      uid: firebaseUser.uid,
    };

    const updatedUser = produce<UserType>(user, (draft) => {
      draft.attributes.authUser = authUser;

      const providerId = userCredential?.credential?.providerId as ProviderId
      if (providerId) {
        draft.attributes[providerId] = makeProviderData(userCredential)
      }
    })

    return updatedUser;
  }

  addShirtOnUser(shirt: ShirtType, user: UserType): UserType {
    return produce(user, (draft) => {
      draft.relationships.shirts = draft.relationships.shirts || {}
      draft.relationships.shirts[shirt.id] = {
        id: shirt.id,
        type: 'shirts',
        addedOn: this.firebaseSDK.makeTimestamp()
      }
    })
  }

}


// const userCredential = {
//   additionalUserInfo: {
//     'providerId': 'google.com',
//     'isNewUser': false,
//     'profile': {
//       'name': 'Morgan',
//       'granted_scopes': 'openid https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
//       'id': '105145127164312281326',
//       'verified_email': true,
//       'given_name': 'Morgan',
//       'locale': 'en',
//       'email': 'morgan.intrator@gmail.com',
//       'picture': 'https://lh3.googleusercontent.com/a-/AOh14GiTS32pqjvV2ue33M5nmEA6X53im73WM-bCu04AYw'
//     }
//   },
//   user: {
//     providerData: [
//       {
//         "uid": "105145127164312281326",
//         "displayName": "Morgan",
//         "photoURL": "https://lh3.googleusercontent.com/a-/AOh14GiTS32pqjvV2ue33M5nmEA6X53im73WM-bCu04AYw",
//         "email": "morgan.intrator@gmail.com",
//         "phoneNumber": null,
//         "providerId": "google.com"
//       }
//     ]
//   }
// };

