import React, { useEffect } from 'react';
import { Search as SearchIcon } from 'react-feather';
import {
  Link,
  useParams
} from 'react-router-dom';
import {
  CSSTransition,
  TransitionGroup
} from 'react-transition-group';
import useQuery from 'src/hooks/useQuery';
import useSearchParams from 'src/hooks/useSearchParams';
import GridItemSearchLoading from 'src/pages/Private/Stack/GridItemSearch/GridItemSearchLoading';
import GridItemSearchResult from 'src/pages/Private/Stack/GridItemSearchResult/GridItemSearchResult';
import { makeContactUsPathname } from 'src/sdk/PATHS';
import { useProductsSDK } from 'src/sdk/products/ProductsSDKContext';
import {
  ProductType,
  StackType
} from 'src/sdk/schemaTypes';
import breakpoints from 'src/styles/breakpoints';
import styled from 'styled-components'
import { useDebouncedCallback } from 'use-debounce';

const StyledDiv = styled.div`
  border-bottom: 1px solid rgb(204, 204, 204);

  .GridItemSearch__results {
    border-bottom: 1px solid rgb(204, 204, 204);
    height: 60px;
  }

  @media (min-width: ${breakpoints.sm}px) {
    .GridItemSearch__results {
      height: 100px;
    }
  }

  .GridItemSearch__scroll {
    overflow-x: scroll;

    &::-webkit-scrollbar {
      width: 2px;
      height: 4px;
    }

    /* Track */

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */

    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    /* Handle on hover */

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  .query-enter {
  }

  .query-enter-active {
  }

  .query-exit {
    z-index: 2;
  }

  .query-exit-active,
  .query-exit-done {
    z-index: 2;
  }
`

type Props = {
  stack: StackType
  addProductOnStack: (product: ProductType) => void;
}

interface Params {
  term?: string;
  perPage?: number;
}

export const gridItemSearchResultsId = 'GridItemSearch__results'

const GridItemSearch: React.FC<Props> = (props) => {


  const {
    stack,
    addProductOnStack,
  } = props;

  // searchParams come from the url!
  const [searchParams, updateSearchParams] = useSearchParams<{ term?: string }>()
  const params = useParams();

  const [inputValue, setInputValue] = React.useState(searchParams.term || '');
  const [queryParams, setQueryParams] = React.useState<Params>({
    term: searchParams.term,
    perPage: 18,
  })

  useEffect(() => {
    if (
      Boolean(searchParams.term) !== Boolean(queryParams.term)
      && searchParams.term !== queryParams.term
    ) {
      updateSearchParams({
        term: queryParams.term
      })
    }
  }, [queryParams, searchParams, updateSearchParams])

  // const  = new URLSearchParams

  const [debouncedSetQueryParams] = useDebouncedCallback((params: Params) => {
    setQueryParams(params)
  }, 500);

  const productsSDK = useProductsSDK();
  const query = useQuery<ProductType, Params>(queryParams,
    queryParams => productsSDK.queryProducts(queryParams));

  return (
    <StyledDiv>

      <div
        id={gridItemSearchResultsId}
        style={{
          opacity: query.meta.loading ? .5 : 1
        }}
        className="GridItemSearch__results position-relative border-top"
      >
        <GridItemSearchLoading
          loaded={Boolean(query.meta.loaded)}
          queryId={query.id}
        />


        <TransitionGroup
          appear
          unmountOnExit
          component={null}
        >
          <CSSTransition
            key={query.id}
            timeout={250}
            classNames="query"
          >
            <div className="GridItemSearch__scroll fill container-fluid bg-white d-flex flex-row flex-nowrap align-items-center">
              {query.data && query.data.length > 0 ? (
                <>
                  {query.data.map((product) => {
                    return (
                      <div
                        key={product.id}
                        className="px-2 flex-shrink-0"
                      >
                        <GridItemSearchResult
                          addProductOnStack={addProductOnStack}
                          stack={stack}
                          product={product}
                        />
                      </div>
                    )
                  })}
                  <div
                    style={{
                      width: 20,
                      height: '100%',
                      flexShrink: 0
                    }}
                  />
                </>
              ) : (
                <div className="text-center flex-grow-1">
                  Sorry, we couldn't find anything like that in our catalog.
                  {' '}
                  <Link to={makeContactUsPathname(params)}>
                    Let us know!
                  </Link>
                </div>
              )}
            </div>
          </CSSTransition>
        </TransitionGroup>
      </div>

      <div className="container-fluid">
        <div className="input-group my-3">
          <div className="input-group-prepend">
            <label
              className="input-group-text"
              htmlFor="inputGroupSelect01"
            >
              <SearchIcon size={20} />
            </label>
          </div>
          <input
            type="text"
            placeholder="Search for products"
            className="StackGrid__input mono form-control form-control-lg"
            style={{
              backgroundClip: 'initial',
            }}
            // So this is a controlled component
            value={inputValue}
            onChange={(e) => {
              setInputValue(e.target.value);
              const term = e.target.value.toLowerCase();
              // The synthetic event is reused for performance reasons. https://fb.me/react-event-pooling
              debouncedSetQueryParams({
                ...queryParams,
                term,
              })
            }}
          />
        </div>
      </div>
    </StyledDiv>
  );
}

export default GridItemSearch
