import React from 'react';
import { useFormContext } from 'react-hook-form';
import { ShirtSizesSchema } from 'src/sdk/schemas';
import { EditableShirtAttributesType } from 'src/sdk/schemaTypes';

export const SelectSizeIdAttribute = 'selectSize'

const SelectSize: React.FC = () => {

  const { register } = useFormContext<EditableShirtAttributesType>()

  return (
    <select
      {...register('size')}
      id={SelectSizeIdAttribute}
      name="size"
      className="custom-select custom-select-lg"
    >
      {Object.values(ShirtSizesSchema.options).map((size) => {
        return (
          <option
            key={size}
            value={size}
          >
            {size}
          </option>
        );
      })}
    </select>
  );
}

export default SelectSize;
