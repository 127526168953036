import { useEffect } from 'react';
import { ShirtImagesMapType } from 'src/sdk/types';
import _random from 'src/utils/_random';
import blackFront1 from 'src/shirtImages/images/blackFront1.png'
import blackFront2 from 'src/shirtImages/images/blackFront2.png'
import blackFront3 from 'src/shirtImages/images/blackFront3.png'
import whiteFront1 from 'src/shirtImages/images/whiteFront1.png'
import whiteFront2 from 'src/shirtImages/images/whiteFront2.png'
import whiteFront3 from 'src/shirtImages/images/whiteFront3.png'
import asphaltFront1 from 'src/shirtImages/images/asphaltFront1.png'
import asphaltFront2 from 'src/shirtImages/images/asphaltFront2.png'
import asphaltFront3 from 'src/shirtImages/images/asphaltFront3.png'
import blackBack1 from 'src/shirtImages/images/blackBack1.png'
import whiteBack1 from 'src/shirtImages/images/whiteBack1.png'
import asphaltBack1 from 'src/shirtImages/images/asphaltBack1.png'

export const shirtImages = {
  blackFront1,
  blackFront2,
  blackFront3,
  whiteFront1,
  whiteFront2,
  whiteFront3,
  asphaltFront1,
  asphaltFront2,
  asphaltFront3,
  blackBack1,
  whiteBack1,
  asphaltBack1,
}

function randomSelection(...args: Array<any>): string {
  return args[_random(args.length - 1)];
}

export const appShirtImagesMap: ShirtImagesMapType = {
  front: {
    black: randomSelection(
      blackFront1,
      blackFront2,
      blackFront3,
    ),
    white: randomSelection(
      whiteFront1,
      whiteFront2,
      whiteFront3,
    ),
    asphalt: randomSelection(
      asphaltFront1,
      asphaltFront2,
      asphaltFront3,
    ),
  },
  back: {
    black: shirtImages.blackBack1,
    white: shirtImages.whiteBack1,
    asphalt: shirtImages.asphaltBack1,
  }
} as const

export const useShirtImagesCache = () => {
  useEffect(() => {
    const shirts = [...Object.values(appShirtImagesMap.front), ...Object.values(appShirtImagesMap.back)]
    shirts.forEach((src) => {
      new Image().src = src
    })
  }, [])
}
