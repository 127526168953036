import React from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import useAddOrRemoveShirtOnCart from 'src/hooks/useAddOrRemoveShirtOnCart';
import { makePrivateCartPathname } from 'src/sdk/PATHS';

type Props = {
  shirtId: string
}

const ShirtCongratulations: React.FC<Props> = (props) => {

  const {
    shirtId,
  } = props

  const params = useParams()
  const history = useHistory()
  const [isAddingOrRemoving, addOrRemoveShirtOnCart] = useAddOrRemoveShirtOnCart(shirtId)

  return (
    <>
      <h1 className="display-3 font-weight-bold mb-4">
        Look at that beauty
      </h1>
      <p className="display-p">
        Go to your dashboard to add this shirt to your cart or to start another!
      </p>
      <button
        disabled={isAddingOrRemoving}
        onClick={async () => {
          await addOrRemoveShirtOnCart()
          history.push(makePrivateCartPathname(params))
        }}
        className="btn btn-primary"
      >
        Add to cart
      </button>
    </>
  );
}

export default ShirtCongratulations
