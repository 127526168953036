import { CART_CODES } from 'src/sdk/users/validation';
import { defineMessages } from 'react-intl'

export default defineMessages({
  'firebase/batch-update-designs': {
    id: 'Cart.firebase/batch-update-designs',
    defaultMessage: 'Hmm. This is not good. Something is preventing us from saving your designs to our database',
    description: 'An error saving designs to firebase',
  },
  'order/checkout': {
    id: 'Cart.order/checkout',
    defaultMessage: 'Darn it! We could not process your order. Let us know so we can make it right!?',
    description: 'An error checking out',
  },
  'max-redemptions-of-coupon': {
    id: 'Cart.max-redemptions-of-coupon',
    defaultMessage: 'Looks like that coupon has been used already!',
  },
  noOrderAfterPayment: {
    id: 'Cart.noOrderAfterPayment',
    defaultMessage: 'Ok we know about a processing error on your last order. \n' +
      'We’re going to stop you from submitting another order until we work it out!',
  },
  hasActiveOrder: {
    id: 'Cart.hasActiveOrder',
    defaultMessage: 'You have another order that is still being processed. Just making sure you know.',
  },
  noShirts: {
    id: 'Cart.noShirts',
    defaultMessage: 'You haven\'t made any shirts yet!',
  },
  noStacks: {
    id: 'Cart.noStacks',
    defaultMessage: 'You haven\'t made any stacks yet!',
  },
  [CART_CODES.NO_STACKS]: {
    id: CART_CODES.NO_STACKS,
    defaultMessage: 'How\'d you get here? Go make a stack, first. Then put a shirt in your cart.',
  },
  [CART_CODES.NO_SHIRTS]: {
    id: CART_CODES.NO_SHIRTS,
    defaultMessage: 'How\'d you get here? Go make a shirt first, and put it in your cart.',
  },
  [CART_CODES.NO_SHIPPING_ADDRESS]: {
    id: CART_CODES.NO_SHIPPING_ADDRESS,
    defaultMessage: 'We need a shipping address.',
  },
  [CART_CODES.NO_SHIRTS_IN_CART]: {
    id: CART_CODES.NO_SHIRTS_IN_CART,
    defaultMessage: 'You do not have any shirts in your cart.',
  },
  [CART_CODES.ISSUE_WITH_SHIPPING]: {
    id: CART_CODES.ISSUE_WITH_SHIPPING,
    defaultMessage: 'Your shipping address is not complete.',
  },
  [CART_CODES.ERRORS_IN_SHIRT]: {
    id: CART_CODES.ERRORS_IN_SHIRT,
    defaultMessage: 'There are problems with one or more of your shirts.',
  },
  [CART_CODES.ERRORS_IN_STACK_ATTRIBUTES]: {
    id: CART_CODES.ERRORS_IN_STACK_ATTRIBUTES,
    defaultMessage: 'One or more of your stacks is without a name.',
  },
  [CART_CODES.ERRORS_IN_STACK_PRODUCTS]: {
    id: CART_CODES.ERRORS_IN_STACK_PRODUCTS,
    defaultMessage: 'Looks like at least one of your stacks is not complete.',
  },
  [CART_CODES.VALID]: {
    id: CART_CODES.VALID,
    defaultMessage: 'You\'re good to go!',
  },
})
