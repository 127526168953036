import { FacingSchema } from 'src/sdk/schemas';
import { StackType } from 'src/sdk/schemaTypes';
import printfulSettings from 'src/sdk/PRINTFUL_SETTINGS';
import { svgHeightOfStack } from './helpers';
import GridItems from 'src/components/StackSVGs/GridItems';
import React from 'react';
import SvgBlanks from './SvgBlanks';
import stackDimensions from 'src/stackDimensions';

const { artworkWidth } = stackDimensions;

interface Props {
  asArtwork?: boolean;
  stack: StackType;
}

export const frontStackSVGId = 'FrontStackSvg'

const FrontStackSVG: React.FC<Props> = (props) => {

  const {
    asArtwork,
    stack,
  } = props;

  const facing = FacingSchema.enum.front
  const stackHeight = svgHeightOfStack(stack);

  // noinspection UnnecessaryLocalVariableJS
  let viewBoxHeight = stackHeight;
  let viewBoxWidth = artworkWidth;
  let xOffset = 0;
  let yOffset = 0;

  if (asArtwork) {
    xOffset = Math.floor((artworkWidth / printfulSettings.shirt.frontPosition.unisex.width) * printfulSettings.shirt.frontPosition.unisex.left);
    yOffset = Math.floor((artworkWidth / printfulSettings.shirt.frontPosition.unisex.width) * printfulSettings.shirt.frontPosition.unisex.top);
    viewBoxWidth = Math.floor((artworkWidth / printfulSettings.shirt.frontPosition.unisex.width) * printfulSettings.shirt.frontPosition.unisex.area_width);
    viewBoxHeight = Math.floor((artworkWidth / printfulSettings.shirt.frontPosition.unisex.width) * printfulSettings.shirt.frontPosition.unisex.area_height);
  }

  const svgDimensions = {
    style: {
      height: 'auto'
    },
    viewBox: `0 0 ${viewBoxWidth} ${viewBoxHeight}`
  };

  return (
    <svg
      id={frontStackSVGId}
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMinYMin"
      {...svgDimensions}
    >
      <g transform={`translate(${xOffset} ${yOffset})`}>
        <SvgBlanks
          stack={stack}
          facing={facing}
        />
        <GridItems stack={stack} facing={facing} />
      </g>
    </svg>
  );

}


FrontStackSVG.propTypes = {};
FrontStackSVG.defaultProps = {};

export default FrontStackSVG;
