import { UserContext } from 'src/pages/AuthListener/User';
import { useCreateStack } from 'src/hooks/useCreateStack';
import { AuthContext } from 'src/sdk/auth/AuthProvider';
import { ReachedStackStepSchema } from 'src/sdk/schemas';

import { Helmet } from 'react-helmet';
import { Link, useParams } from 'react-router-dom';
import {
  makeContactUsPathname,
  makePrivateCartPathname,
  makePrivateStackPathname,
  makePrivateStacksPathname
} from 'src/sdk/PATHS';
import { navHeight } from 'src/components/Navbar/Navbar';
import { useAnalytics } from 'src/sdk/analytics/AnalyticsContext';
import PageView from 'src/components/PageView/PageView';
import React, { useContext } from 'react';
import ShirtImage from './03-shirt-trimmed-flipped.png';
import breakpoints from 'src/styles/breakpoints';
import styled from 'styled-components'


const StyledDiv = styled.div`

  .Home__copy {
    padding-bottom: 1rem;
  }

  .Home__bigCopy {
    font-size: 30px;
  }

  .Home__whyYouMustHaveOne {
    padding-bottom: 1rem;
    will-change: transform, opacity;
    transform-style: preserve-3d;
  }

  .Home__aboutAndContactUs {
    position: absolute;
    bottom: 0;
  }

  @media (min-width: ${breakpoints.md}px) {
    .Home__copy {
      padding-bottom: 4rem;
    }

    .Home__bigCopy {
      font-size: 50px;
    }

    .Home__shirtImg {
      width: auto;
    }
  }
  @media (min-width: ${breakpoints.lg}px) {
    .Home__bigCopy {
      font-size: 60px;
    }
  }

  .Home__logoRow {
    height: 160px;
    width: 100%;
    overflow: hidden;

    font-size: 64px;
    font-weight: bold;
    font-style: italic;
    position: relative;

    &:nth-of-type(1) {
      background-color: #ff4c4c;
      background-image: radial-gradient(#ffffff 0.8px, #ff4c4c 0.8px);
      background-size: 16px 16px;
    }

    &.Home__logoRow--yellow {
      background-color: #ffdd00;
      background-image: radial-gradient(#666 0.8px, #ffdd00 0.8px);
      background-size: 16px 16px;
    }

    &.Home__logoRow--blue {
      background-color: #b089ff;
      background-image: radial-gradient(#ffffff 0.8px, #b089ff 0.8px);
      background-size: 16px 16px;
    }
  }

  .marquee {
    white-space: nowrap;
    position: absolute;
    height: 40px;
    line-height: 40px;
    top: 80px;
    margin: -20px auto 0;
    display: inline-block;
    animation: marquee 20s linear infinite;
  }

  .marquee2 {
    animation-delay: 10s;
    animation: marquee2 20s linear infinite;
  }

  @keyframes marquee {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  @keyframes marquee2 {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }

`

const frontendLogos = [
  'react',
  'angular',
  'svelte',
  'vue',
  'polymer',
  'javascript',
  'react-router',
  'redux',
  'material',
  'tailwind',
  'bootstrap',
  'formik',
  'webpack',
  'rollup',
  'rome',
  'jquery',
]

const middleLogos = [
  'figma',
  'sketch',
  // 'adobe xd',
  'zeplin',
  'pivotal',
  'jira',
  'github',
  'webstorm',
  'iterm2',
  'vs code',
  'sublime',
  // 'lambda',
  'firebase',
  'vercel',
]

const backendLogos = [
  'python',
  'javascript',
  'java',
  'rust',
  'go',
  'node',
  'deno',
  'webassembly',
  'scala',
  'elm',
  'aws',
  'gcp',
  'azure',
  'ruby'
]


const Home: React.FC = (props) => {

  /**
   * Beware auth.uid may not be set yet!!
   */
  const { auth } = useContext(AuthContext);
  const userSWR = useContext(UserContext);
  const { data: user } = userSWR;
  const params = useParams();
  const analytics = useAnalytics();

  const [creatingStack, createStack] = useCreateStack();

  const renderCTA = () => {

    const buttonClassname = `btn btn-sm-lg btn-primary mr-3`

    if (!auth.initialized || !user) {
      return null;
    }
    if (!user.relationships.stacks || !Object.keys(user.relationships.stacks).length) {
      return (
        <button
          type="button"
          disabled={creatingStack}
          onClick={async () => createStack()}
          className={buttonClassname}
        >
          <span className="d-none d-sm-block">
            Make a stackshirt
          </span>
          <span className="d-block d-sm-none">
            Make one
          </span>
        </button>
      )
    }

    if (user.attributes?.reachedStackStepId !== ReachedStackStepSchema.enum.finished) {
      const stackId = Object.keys(user.relationships.stacks)[0]
      return (
        <Link
          to={{
            pathname: makePrivateStackPathname({
              ...params,
              stackId: stackId,
              userId: auth.uid
            })
          }}
          className={buttonClassname}
        >
          Finish yours
        </Link>
      )
    }

    return (
      <>
        <Link
          to={{
            pathname: makePrivateStacksPathname({
              ...params,
              userId: auth.uid
            })
          }}
          className={buttonClassname}
        >
          See yours
        </Link>
        {user.relationships.shirts && (
          <Link
            to={{
              pathname: makePrivateCartPathname({
                ...params,
                userId: auth.uid
              })
            }}
            className={buttonClassname}
          >
            <span className="d-none d-sm-block">
              Your cart
            </span>
            <span className="d-block d-sm-none">
              Cart
            </span>
          </Link>
        )}
      </>
    )
  }

  const CTA = renderCTA();

  return (
    <>
      <Helmet defer={false}>
        <title>Stackshirts</title>
        <meta
          name="description"
          content="Stackshirts"
        />
      </Helmet>
      <PageView
        pageKey={auth.initialized && user?.id}
        onPageView={() => analytics.page({
          name: 'Home'
        })}
      />
      <div style={{ height: navHeight.md }} />

      <StyledDiv
        className="Home"
      >
        <div className="container-fluid container-sm ct d-flex flex-column flex-md-row">
          <div className="Home__copy">
            <h1 className="display-1 fadeInFromRight">
              <div className="mb-2">
                Love your tech?
              </div>
              <div className="mb-4">
                <i>
                  Prove it.
                </i>
              </div>
            </h1>

            <h2 className="lead fadeInFromRight second mb-4">
              Design a shirt
              <br />
              with your favorite tech stack.
            </h2>

            <div
              style={{
                minHeight: 80 // Just so there's no jerk in the page when auth is initialized
              }}
              className="fadeInFromRight third"
            >
              {CTA}
            </div>
          </div>
        </div>

        <img
          alt="An example shirt"
          src={ShirtImage}
          style={{
            width: 800,
            height: 672,
          }}
          className="float-right d-none d-md-block"
        />

        <div className="d-flex justify-content-center flex-row d-md-none mt-4 overflow-hidden">
          <img
            alt="An example shirt"
            src={ShirtImage}
            style={{
              width: 500,
              height: 420,
            }}
          />
        </div>

        <div className="clearfix" />

        <div className="h1 font-italic m-0">
          <div className="Home__logoRow">
            <div className="marquee">
              {backendLogos.map((logo, i) => (
                <span
                  key={logo}
                  className={i % 2 === 0 ? 'text-dark' : 'text-light'}
                >
                  {logo}
                </span>
              ))}
            </div>
            <div className="marquee marquee2">
              {backendLogos.map((logo, i) => (
                <span
                  key={logo}
                  className={i % 2 === 0 ? 'text-dark' : 'text-light'}
                >
                  {logo}
                </span>
              ))}
            </div>
          </div>
        </div>

        {/*<div className="Home__bigCopy border-top d-flex flex-column justify-content-center">*/}
        {/*  <div className="container">*/}
        {/*    <div className="py-4rem w-100 w-lg-75 m-auto">*/}
        {/*        Launch a new product?*/}
        {/*        Get a shirt to commemorate it.*/}
        {/*        A time capsule of the the tech you loved in 2020.*/}
        {/*        An heirloom to pass down for generations.*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}


        <div className="h1 font-italic m-0">
          <div className="Home__logoRow Home__logoRow--yellow">
            <div className="marquee">
              {middleLogos.map((logo, i) => (
                <span
                  key={logo}
                  className={i % 2 === 0 ? 'text-dark' : 'text-light'}
                >
                  {logo}
                </span>
              ))}
            </div>
            <div className="marquee marquee2">
              {middleLogos.map((logo, i) => (
                <span
                  key={logo}
                  className={i % 2 === 0 ? 'text-dark' : 'text-light'}
                >
                  {logo}
                </span>
              ))}
            </div>
          </div>
        </div>

        <div className="bg-dark text-light center">
          <div className="container py-4rem">
            <h2 className="display-2">
              "Not only is my stack the best stack, but it's the prettiest too".
            </h2>
            <div className="lead">
              - A happy customer
            </div>
          </div>
        </div>

        <div className="h1 font-italic m-0">
          <div className="Home__logoRow Home__logoRow--blue">
            <div className="marquee">
              {frontendLogos.map((logo, i) => (
                <span
                  key={logo}
                  className={i % 2 === 0 ? 'text-dark' : 'text-light'}
                >
                  {logo}
                </span>
              ))}
            </div>
            <div className="marquee marquee2">
              {frontendLogos.map((logo, i) => (
                <span
                  key={logo}
                  className={i % 2 === 0 ? 'text-dark' : 'text-light'}
                >
                  {logo}
                </span>
              ))}
            </div>
          </div>
        </div>

        <div className="Home__footer container-fluid py-2rem">
          <div className="Home__contactUs">
            <div>
              Made all over the U.S
            </div>
            <Link to={makeContactUsPathname(params)}>
              Contact us
            </Link>
          </div>
        </div>

      </StyledDiv>
    </>
  );
}

export default Home;
