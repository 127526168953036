import { useEffect } from 'react';

export default function usePreventGesture() {
  useEffect(() => {
    const handler = (e: Event) => {
      e.preventDefault();
    }
    document.addEventListener('gesturestart', handler)
    document.addEventListener('gesturechange', handler)
    return () => {
      document.removeEventListener('gesturestart', handler)
      document.removeEventListener('gesturechange', handler)
    }
  }, [])
}
