import { UserContext } from 'src/pages/AuthListener/User';
import { makePrivateStackPathname } from 'src/sdk/PATHS';
import { useCallback, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useMethodsSDK } from 'src/sdk/methods/MethodsSDKProvider';
import { useStacksSDK } from 'src/sdk/stacks/StacksSDKContext';
import usePromise from 'src/hooks/usePromise';
import promiseTry from 'src/utils/promiseTry';


export function useCreateStack() : [boolean, () => Promise<any>] {

  const userSWR = useContext(UserContext);
  const { data: user } = userSWR;
  const methodsSDK = useMethodsSDK();
  const stacksSDK = useStacksSDK()
  const params = useParams();
  const history = useHistory();

  const [creatingStack, callAsync] = usePromise();

  const createStack = useCallback(() => {
    return callAsync(async () => {
      if (!user) {
        return;
      }
      const [updatedUser, newStack] = stacksSDK.makeStackAndUpdateUser(user);
      await Promise.all([
        methodsSDK.upsertResources([updatedUser, newStack]),
        promiseTry(() => history.push({
          pathname: makePrivateStackPathname({
            ...params,
            userId: user.id,
            stackId: newStack.id
          })
        }))
      ])
    })
  }, [callAsync, methodsSDK, stacksSDK, params, history, user]);


  return [creatingStack, createStack]
}
