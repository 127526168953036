import {
  ShirtType,
  StackType,
  UserType
} from 'src/sdk/schemaTypes';
import { STACK_CODES, getProductsOnStackStatus, validateStackAttributes } from 'src/sdk/stacks/validation';
import { validateShirtAttributes } from 'src/sdk/shirts/validation';

export enum CART_CODES {
  NO_SHIPPING_ADDRESS = 'NO_SHIPPING_ADDRESS',
  NO_SHIRTS_IN_CART = 'ALL_STACKS_IN_LATER',
  NO_STACKS = 'NO_STACKS',
  NO_SHIRTS = 'NO_SHIRTS',
  ISSUE_WITH_SHIPPING = 'ISSUE_WITH_SHIPPING',
  ERRORS_IN_SHIRT = 'ERRORS_IN_SHIRT',
  ERRORS_IN_STACK_PRODUCTS = 'ERRORS_IN_STACK_PRODUCTS',
  ERRORS_IN_STACK_ATTRIBUTES = 'ERRORS_IN_STACK_ATTRIBUTES',
  VALID = 'VALID',
}

export const getCartStatus = (user: UserType, shirtsInCart: ShirtType[], stacksInCart: StackType[]): CART_CODES => {

  if (!user.relationships.stacks || !Object.keys(user.relationships.stacks).length) {
    return CART_CODES.NO_STACKS;
  }

  if (!user.relationships.shirts || !Object.keys(user.relationships.shirts).length) {
    return CART_CODES.NO_SHIRTS;
  }

  if (!shirtsInCart.length) {
    return CART_CODES.NO_SHIRTS_IN_CART;
  }

  for (let shirtId in shirtsInCart) {
    const shirt = shirtsInCart[shirtId];
    const errorsInShirtAttributes = validateShirtAttributes(shirt.attributes);
    if (Object.keys(errorsInShirtAttributes).length) {
      return CART_CODES.ERRORS_IN_SHIRT
    }
  }

  for (let stackId in stacksInCart) {
    const stack = stacksInCart[stackId];
    const errorsInStackAttributes = validateStackAttributes(stack.attributes);
    if (Object.keys(errorsInStackAttributes).length) {
      return CART_CODES.ERRORS_IN_STACK_ATTRIBUTES
    }
    const status = getProductsOnStackStatus(stack)
    if (status !== STACK_CODES.VALID) {
      return CART_CODES.ERRORS_IN_STACK_PRODUCTS
    }
  }

  return CART_CODES.VALID

};
