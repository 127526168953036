import { DebugType } from 'src/sdk/types';
import { createContext, useContext } from 'react';

export const DebugContext = createContext<DebugType | null>(null)

export const useDebug = () => {
  const debug = useContext(DebugContext);

  if (!debug) {
    throw Error('You need to have a DebugContext.Provider')
  }
  return debug;
}
